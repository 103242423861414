import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatumService } from '../../../../../services/datum/datum.service';
import { ApirequestService } from '../../../../../services/apirequest/apirequest.service';
import { ApiUrlService } from '../../../../../services/api-url/api-url.service';
import { SnackbarService } from '../../../../../services/snackbar/snackbar.service';
import { SessionOutService } from '../../../../../services/sessionOut/session-out.service';

@Component({
  selector: 'batp-withdrawal-box',
  templateUrl: './withdrawal-box.component.html',
  styleUrls: ['./withdrawal-box.component.css']
})
export class WithdrawalBoxComponent implements OnInit {

  constructor(private _fb: FormBuilder,
    private _req: ApirequestService,
    private _urls: ApiUrlService,
    private _sb: SnackbarService,
    private _so: SessionOutService,
    private _datum: DatumService) { }
  @Input() assetData: any = '';
  @Input() assetType: any = '';

  withdrawalForm: FormGroup;
  showLoader: boolean = false;
  show_dialog : boolean = false;
  withdrawStat: any = {
    message: '',
    icon: '',
    messageType: ''
  }
  withdrawalFormInit() {
    this.withdrawalForm = this._fb.group({
      message: [''],
      walletAddress: ['', [Validators.required]],
      amount: ['', [Validators.required]]
    })
  }
  userDetails: any;
  getUserDetails() {
    this.userDetails = this._datum.getUserDetails();
    // this.withdrawalForm.controls['walletAddress'].setValue(this.userDetails.internalAddress);
  }

  withdrawalSubmit() {
    if (this.withdrawalForm.valid) {
      this.showLoader = true;
      let data = {
        ...this.withdrawalForm.value,
        networkType: 'N',
        idCompanyStock: this.assetData.idCompanyStock
      };
      this._req.fetchApiData(this._urls.withdrawalUrl, data, false).subscribe(
        (data: any) => {
          let resSucc = data.data;
          let resErr = data.error;
          if (resSucc !== '') {
            this.setStatusMsg('Your Trascation has been done Successfully', 3000, 'hybse_success_snackbar', 'right', 'top');
            this.withdrawalForm.reset();
          }
          if (resErr !== '') {
            this.setStatusMsg(resErr['Error Description'], 3000, 'hybse_error_snackbar', 'right', 'top');
            this._so.handleSessionOutErr(resErr);
          }
        },
        error => {

        },
        () => {
          this.showLoader = false;
        }
      )
    }
  }

  setStatusMsg(message, duration, htmlClass, x, y) {
    this._sb.openSnackBar(message, duration, htmlClass, x, y);
  }
  toggle() {
    this.show_dialog = !this.show_dialog;
  }
  ngOnInit() {
    this.withdrawalFormInit();
    this.getUserDetails();
  }




  ngOnChanges() {
  }

}
