import { Component, OnInit, Input } from '@angular/core';
import { LogoutService } from '../../../../services/logout/logout.service';

@Component({
  selector: 'batp-wiz-registration-complete',
  templateUrl: './wiz-registration-complete.component.html',
  styleUrls: ['./wiz-registration-complete.component.css']
})
export class WizRegistrationCompleteComponent implements OnInit {

  constructor(private _lo:LogoutService) { }
  @Input() goTo:any = '';
  @Input() regStatus:any = '';

  tryAgain() {
    this._lo.logOut();
  }

  ngOnInit() {
  }

}
