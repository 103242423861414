import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/services/utilities/utility.service';
import { Store, select } from '@ngrx/store';
import * as rootState from '../../../../app.reducer';
import { FsUploadService } from 'src/app/services/fs-upload/fs-upload.service';
import { ApiUrlService } from 'src/app/services/api-url/api-url.service';
import { ApirequestService } from 'src/app/services/apirequest/apirequest.service';
import { LoginService } from 'src/app/services/login/login.service';
import { financialDocument, financialDoc } from 'src/app/shared/data/financial-docs';
import { ConfirmBoxComponent } from 'src/app/components/widgets/confirm-box/confirm-box.component';
import { MatDialog } from '@angular/material';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';


@Component({
  selector: 'batp-financial-document',
  templateUrl: './financial-document.component.html',
  styleUrls: ['./financial-document.component.css']
})
export class FinancialDocumentComponent implements OnInit {

  constructor(private _utils:UtilityService,
              private _fs:FsUploadService,
              private _url: ApiUrlService,
              private _req: ApirequestService,
              private _dialog:MatDialog,
              private _ls: LoginService,
              private _sb: SnackbarService,
              private store:Store<rootState.State>) { }


  // filterArrayObj(arr,prop,val)
  companyDetails:any;
  financialDocs:any = [];
  documentDescs:string = '';
  showErr:boolean = false;
  financialDocData:any = financialDocument;

  otherFinDoc:any = [];
  getCompanyDetails() {
    this.store.pipe(select('authData')).subscribe(
      (data:any) => {
        this.companyDetails = data.companyDetail;

        this.getFinancialDocs(this.companyDetails.companyDocument);
      }
    );
  }
  editMode:boolean = false;
  updateProfile() {
    this.editMode = true;
  }
  getFinancialDocs(docs) {
    if(docs.length != 0) {
      this.financialDocs = this._utils.filterArrayObj(docs,'docTitle','logo');
      this.otherFinDoc = this._utils.filteredArray(docs,'docType',9);
      this.mergeFinDocs();
    }
  }
  mergeFinDocs() {
    this.financialDocData = this.financialDocData.map(ele => {
      let doc = this._utils.filteredArray(this.financialDocs,'docTitle',ele.slug);
      let updatedDoc = {...doc[0], ...ele}
      return updatedDoc
    });
  }
  docInputHandler() {
    this.showErr = this.documentDescs == '';
  }
  uploadFiles() {
    if(this.documentDescs != '') {
      let fileAccepted = [".pdf",".doc",".docx",".docm","image/*"];
      let maxSize = 10485760;
      this._fs.uploadFiles(fileAccepted,maxSize).then((res)=>{
        if(res.filesUploaded.length > 0) {
          this.uploadAdditionalDoc(res.filesUploaded[0],this.documentDescs,'other_docs');
        }
      });
    } else {
      this.showErr = true;
    }
  }
  uploadFinFiles(type){
    let fileAccepted = [".pdf",".doc",".docx",".docm","image/*"];
    let maxSize = 10485760;
    this._fs.uploadFiles(fileAccepted,maxSize).then((res)=>{
      if(res.filesUploaded.length > 0) {
        this.uploadAdditionalDoc(res.filesUploaded[0],type,type);
      }
    });
  }
  getDocType(type) {
    let doctype;
    switch (type) {
      case 'commercial_register':
        doctype = 2;
        break;
      case 'comp_details':
        doctype = 3;
        break;
      case 'financial_document':
        doctype = 4;
        break;
      case 'prospectus':
        doctype = 5;
        break;
      case 'business_plan':
        doctype = 6;
        break;
      case 'business_valuation':
        doctype = 7;
        break;
      case 'one_pager_summary':
        doctype = 8;
        break;
      case 'other_docs':
        doctype = 9;
        break;
    }
    return doctype
  }
  uploadAdditionalDoc(file,title = '', type?) {
    let companyId = this.companyDetails.company.idCompany;
    let data = {
      idCompany: companyId,
      documentInfo: [{
        docTitle: title,
        docName: file.url,
        docType: this.getDocType(type),
      }]
    }
    this._req.fetchApiData(this._url.addCompanyDocumentsUrl,data,false).subscribe(
      (data:any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if(resSucc != '') {
          this._ls.getCompanyDetails(companyId)
        }
      },
      error => {

      },
      () => {

      }
    );
  }
  checkFinDocUpld(doc){
    return doc.hasOwnProperty('docTitle');
  }



  confirmModalBox(doc,type = 'financialDocData') {
    let message = 'Are You Sure You Want To Delete this Document?';
    const dialogRef = this._dialog.open(ConfirmBoxComponent, {
      width: '450px',
      data: {
        message: message
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.deleteDocument(doc,type)
      }
    });
  }
  deleteDocument(doc,type = 'financialDocData') {
    let companyId = this.companyDetails.company.idCompany;
    let data = {
     idCompany: companyId,
     idCompanyDocument : doc.idCompanyDocument
    };
    this._req.fetchApiData(this._url.deleteCompanyDocumentsUrl,data,false).subscribe(
      (data:any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if(resSucc != '') {
          this._sb.openSnackBar('Document Has Been Deleted Successfully',3000,'hybse_success_snackbar','right','top');
          this._ls.getCompanyDetails(companyId);
        }
      },
      error => {

      },
      () => {

      }
    )
  }
  ngOnInit() {
    this.getCompanyDetails();
  }

}
