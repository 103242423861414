import { Component, OnInit, ViewChild, HostListener } from "@angular/core";
import {
  MatSort,
  MatTableDataSource,
  MatDialog
} from "@angular/material";
import { MarketsService } from "../../../../services/markets/markets.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AccountDetailsService } from "../../../../services/accountDetails/account-details.service";
import { ApirequestService } from "../../../../services/apirequest/apirequest.service";
import { SnackbarService } from "../../../../services/snackbar/snackbar.service";
import { ConfirmBoxComponent } from "../../../widgets/confirm-box/confirm-box.component";
import { filter } from 'rxjs/operators';
import { DatumService } from "src/app/services/datum/datum.service";
import { LoginService } from "src/app/services/login/login.service";
import { StorageService } from "src/app/services/localstorage/storage.service";
import { ApiUrlService } from "src/app/services/api-url/api-url.service";
import { ErrorMessages } from "src/app/shared/error-messages";
import { UtilityService } from "src/app/services/utilities/utility.service";
import { validatePrice } from "src/app/shared/form-validators";
import { SessionOutService } from "src/app/services/sessionOut/session-out.service";
import { ChangeDetectorRef } from '@angular/core';
import { LogoutService } from "src/app/services/logout/logout.service";
import { LimitBuyComponent } from "src/app/components/widgets/limit-buy/limit-buy.component";
import { LimitSellComponent } from "src/app/components/widgets/limit-sell/limit-sell.component";
import { ConfirmLoginComponent } from "src/app/components/widgets/confirm-login/confirm-login.component";
import { ConfirmOrderLoginComponent } from "src/app/components/widgets/confirm-order-login/confirm-order-login.component";
import { BehaviorSubject } from "rxjs";


declare var $: any;

@Component({
  selector: 'batp-market-info',
  templateUrl: './market-info.component.html',
  styleUrls: ['./market-info.component.css']
})
export class MarketInfoComponent implements OnInit {
  showLoader: boolean = false;

  public sub;
  public targetCurrency: string;
  chartSelectedIndex: any = 0;
  chartSelectedIndex_depth: any = 0;
  isMobile: boolean = this._utils.checkMobileDevice();
  // isMobile:boolean = true;
  toggle1: boolean = false;
  toggle2: boolean = false;
  toggle3: boolean = false;
  toggle4: boolean = false;
  toggle5: boolean = false;
  toggle6: boolean = false;
  toggle7: boolean = false;
  toggle8: boolean = false;
  toggle9: boolean = false;
  errorMessages: any = new ErrorMessages();
  stockListColumns: string[] = ['companyName', 'priceCurrent', 'volume', 'change'];
  stockListProColumns: string[] = ['stockName', 'priceCurrent', 'volumeThirty', 'change'];
  // ordersColumns: string[] = ['bidPrice', 'bidSize', 'total', 'sum'];
  // sellOrdersColumns: string[] = ['askPrice', 'askSize', 'total', 'sum'];
  // matchedOrdersColumns: string[] = ['matchedAt', 'matchPrice', 'matchSize', 'type'];
  ordersColumns: string[] = ['price', 'size', 'total', 'sum'];
  sellOrdersColumns: string[] = ['price', 'size', 'total', 'sum'];
  matchedOrdersColumns: string[] = ['txnDate', 'price', 'size', 'type'];
  // orderStatsColumns: string[] = ["date", "price", "size", "total", "type"];
  orderStatsColumns: string[] = ['txnDate', 'price', 'size', 'total', 'type'];
  advOrderStatsColumns: string[] = ['date', 'price', 'size', 'total', 'type'];
  primaryActColumns: string[] = [
    // "symbol",
    'assetName',
    'nemAmount',
    'totalAmount',
    'availableAmount',
    'reserveAmount'
  ];
  secondaryActColumns: string[] = [
    // "symbol",
    'assetName',
    'nemAmount',
    'totalAmount',
    'availableAmount',
    'reserveAmount'
  ];

  isUserWalletBoolen: any = new BehaviorSubject(false);

  buyOrderForm: FormGroup;
  sellOrderForm: FormGroup;

  buyOrderLimitForm: FormGroup;
  sellOrderLimitForm: FormGroup;

  buyOrderStopLimitForm: FormGroup;
  sellOrderStopLimitForm: FormGroup;

  buyOrderLoader = false;
  buyOrderSubmitted = false;
  buyOrderLimitSubmitted = false;
  BOStopLimitSubmitted = false;
  sellOrderLoader = false;
  sellOrderSubmitted = false;
  sellOrderLimitSubmitted = false;
  SOStopLimitSubmitted = false;

  stockList: any = [];
  stockListClone: any = [];
  stockListSubs: any;
  favoriteStock: any = [];
  favoriteStockClone: any = [];
  favStockBlk: any = [];
  favSec = false;
  availableAmount: any;
  availableShares: any;
  userWallet = [];
  // commissionFee: number = 0.1;
  commissionFee = 0.15;

  @ViewChild(MatSort)
  sort: MatSort;
  @ViewChild('FavSort')
  FavSort: MatSort;
  @ViewChild('BOSort')
  buyOrderSort: MatSort;
  @ViewChild('SOSort')
  sellOrderSort: MatSort;
  @ViewChild('allOrderSort')
  allOrderSort: MatSort;
  @ViewChild('OpenBuyOrderSort')
  OpenBuyOrderSort: MatSort;
  @ViewChild('OpenSellOrderSort')
  OpenSellOrderSort: MatSort;
  @ViewChild('MatchdedOrderSort')
  MatchdedOrderSort: MatSort;
  @ViewChild('UserTradeSort')
  UserTradeSort: MatSort;
  @ViewChild('MarketTradeSort')
  MarketTradeSort: MatSort;
  @ViewChild('PrimarySort')
  PrimarySort: MatSort;
  @ViewChild('SecondarySort')
  SecondarySort: MatSort;
  @ViewChild('favoriteStockSort')
  favoriteStockSort: MatSort;
  @ViewChild('stockListProSort')
  stockListProSort: MatSort;
  @ViewChild('favoriteStockProSort')
  favoriteStockProSort: MatSort;
  /*
  getTotal(type, price, size, stopLimit: boolean = false ) {
    let orderPrice, orderSize, bidTotal, totalCommission;
    let form = type == "buy" ? this.buyOrderLimitForm : this.sellOrderLimitForm;
    if (stopLimit) {
      form = type == "buy" ? this.buyOrderStopLimitForm : this.sellOrderStopLimitForm;
    }
    let total = type == "buy" ? "bidTotal" : "askTotal";
    orderPrice = form.controls[price].value;
    orderSize = form.controls[size].value;
    bidTotal = orderPrice * orderSize;
    totalCommission = (this.commissionFee / 100) * bidTotal;
    if (!isNaN(orderSize) && orderSize != "") {
      form.controls[total].setValue(totalCommission + bidTotal);
      // form.controls['totalWithComission'].setValue(totalCommission + bidTotal);
    } else {
      form.controls[total].setValue("");
      //form.controls['totalWithComission'].setValue('');
    }
  } */
  isAuthenticated: boolean = false;
  commissionFeeBuy: any = 0;
  commissionFeeSell: any = 0;
  commissionFeeProBuy: any = 0;
  commissionFeeProSell: any = 0;
  commissionFeeLimitProBuy: any = 0;
  commissionFeeLimitProSell: any = 0;
  commissionFeeLimitStockProBuy: any = 0;
  commissionFeeLimitStockProSell: any = 0;






  stockInfo: any = [];
  stockInfoLoader = true;
  stockInfoLoaded = false;

  buyOrders: any = [];
  sellOrders: any = [];
  allOrdersStats: any = [];
  openBuyOrders: any = [];
  openSellOrders: any = [];
  userTradeData: any = [];
  marketTradeData: any = [];
  matchedOrdersData: any = [];
  stockDataSubs: any;

  accountDetails: any = {
    primary: [],
    secondary: []
  };
  actDetailSubs: any;
  primaryData: any = [];
  secondaryData: any = [];

  wltBalUSD: any = 0;
  wltBalBTC: any = 0;

  themeMode: any = { dayTheme: true, nightTheme: false };


  userData: any = '';

  dDMenuStatus = false;

  preferredTheme: boolean;
  themeSubs: any;



  doubleWalletToggle = false;

  currentTheme = 'light';
  orderBookData = {};





  paramID: any;
  routeData = '';

  constructor(
    private _market: MarketsService,
    private _accDetail: AccountDetailsService,
    private _req: ApirequestService,
    private _urls: ApiUrlService,
    private _sb: SnackbarService,
    private _dialog: MatDialog,
    private _ls: StorageService,
    private _lo: LoginService,
    private _logOut: LogoutService,
    private _ar: ActivatedRoute,
    private _datum: DatumService,
    private _router: Router,
    private _utils: UtilityService,
    private _fb: FormBuilder,
    private _so: SessionOutService,
    private changeDetector: ChangeDetectorRef
  ) { }

  applyFilter(filterValue: string) {
    this.stockListClone.filter = filterValue.trim().toLowerCase();
  }
  buyOrderFormInit() {
    this.buyOrderForm = this._fb.group({
      bidPrice: ['Price'],
      bidSize: ['', [Validators.required, validatePrice]],
      bidTotal: ['', []],
      // commissionFee: [this.commissionFee + "%", []],
      commissionFee: ['0.000001'],
      totalWithComission: ['', []]
    });
  }
  sellOrderFormInit() {
    this.sellOrderForm = this._fb.group({
      askPrice: ['Price'],
      askSize: ['', [Validators.required, validatePrice]],
      askTotal: ['', []],
      // commissionFee: [this.commissionFee + "%", []],
      commissionFee: ['0.000001'],
      totalWithComission: ['', []]
    });
  }
  buyOrderLimitFormInit() {
    this.buyOrderLimitForm = this._fb.group({
      bidPrice: ['', [Validators.required, validatePrice]],
      bidSize: ['', [Validators.required, validatePrice]],
      bidTotal: ['', [Validators.required, validatePrice]],
      commissionFee: ['0.000001']
    });
  }
  sellOrderLimitFormInit() {
    this.sellOrderLimitForm = this._fb.group({
      askPrice: ['', [Validators.required, validatePrice]],
      askSize: ['', [Validators.required, validatePrice]],
      askTotal: ['', [Validators.required, validatePrice]],
      commissionFee: ['0.000001']
    });
  }
  buyOrderStopLimitFormInit() {
    this.buyOrderStopLimitForm = this._fb.group({
      bidPriceStop: ['', [Validators.required, validatePrice]],
      bidPrice: ['', [Validators.required, validatePrice]],
      bidSize: ['', [Validators.required, validatePrice]],
      bidTotal: ['', [Validators.required, validatePrice]],
      commissionFee: ['0.000001']
    });
  }
  sellOrderStopLimitFormInit() {
    this.sellOrderStopLimitForm = this._fb.group({
      askPrice: ['', [Validators.required, validatePrice]],
      askPriceStop: ['', [Validators.required, validatePrice]],
      askSize: ['', [Validators.required, validatePrice]],
      askTotal: ['', [Validators.required, validatePrice]],
      commissionFee: ['0.000001']
    });
  }
  getMarketRoute(route) {
    let url = [];
    url = [route, this.paramID];
    this._ls.setLocalItem('latestView', url[0]);
    this._router.navigate(url);
  }
  buyOrderSubmit() {
    this.buyOrderSubmitted = true;
    if (this.buyOrderForm.valid) {
      this.buyOrderLoader = true;
      const formVal = this.buyOrderForm.value;
      const data = {
        type: 'market',
        stop: 0,
        bidPrice: formVal.bidPrice,
        bidSize: formVal.bidSize,
        idCompanyStock: this.stockInfo.idCompanyStock,
        idCompany: this.stockInfo.idCompany
      };
      this.sellBuySubmitReq(this._urls.buyOrderUrl, data, 'buyOrderForm');
    }
  }
  sellOrderSubmit() {
    this.sellOrderSubmitted = true;
    if (this.sellOrderForm.valid) {
      this.sellOrderLoader = true;
      const formVal = this.sellOrderForm.value;
      const data = {
        type: 'market',
        stop: 0,
        askPrice: formVal.askPrice,
        askSize: formVal.askSize,
        idCompanyStock: this.stockInfo.idCompanyStock,
        idCompany: this.stockInfo.idCompany
      };
      this.sellBuySubmitReq(
        this._urls.sellOrderUrl,
        data,
        'sellOrderForm',
        'sell'
      );
    }
  }
  buyOrderLimitSubmit() {
    this.buyOrderLimitSubmitted = true;
    if (this.buyOrderLimitForm.valid) {
      this.buyOrderLoader = true;
      const formVal = this.buyOrderLimitForm.value;
      const data = {
        type: 'limit',
        stop: 0,
        bidPrice: formVal.bidPrice,
        bidSize: formVal.bidSize,
        idCompanyStock: this.stockInfo.idCompanyStock,
        idCompany: this.stockInfo.idCompany
      };
      this.sellBuySubmitReq(
        this._urls.buyOrderUrl,
        data,
        'buyOrderLimitForm'
      );
    }
  }
  sellOrderLimitSubmit() {
    this.sellOrderLimitSubmitted = true;
    if (this.sellOrderLimitForm.valid) {
      this.sellOrderLoader = true;
      const formVal = this.sellOrderLimitForm.value;
      const data = {
        type: 'limit',
        stop: 0,
        askPrice: formVal.askPrice,
        askSize: formVal.askSize,
        idCompanyStock: this.stockInfo.idCompanyStock,
        idCompany: this.stockInfo.idCompany
      };
      this.sellBuySubmitReq(
        this._urls.sellOrderUrl,
        data,
        'sellOrderLimitForm',
        'sell'
      );
    }
  }

  BOStopLimitSubmit() {
    this.BOStopLimitSubmitted = true;
    if (this.buyOrderStopLimitForm.valid) {
      this.buyOrderLoader = true;
      const formVal = this.buyOrderStopLimitForm.value;
      const data = {
        type: 'stopLimit',
        stop: formVal.bidPriceStop,
        bidPrice: formVal.bidPrice,
        bidSize: formVal.bidSize,
        idCompanyStock: this.stockInfo.idCompanyStock,
        idCompany: this.stockInfo.idCompany
      };
      this.sellBuySubmitReq(
        this._urls.buyOrderUrl,
        data,
        'buyOrderStopLimitForm'
      );
    }
  }
  SOStopLimitSubmit() {
    this.SOStopLimitSubmitted = true;
    if (this.sellOrderStopLimitForm.valid) {
      this.sellOrderLoader = true;
      const formVal = this.sellOrderStopLimitForm.value;
      const data = {
        type: 'stopLimit',
        stop: formVal.askPriceStop,
        askPrice: formVal.askPrice,
        askSize: formVal.askSize,
        idCompanyStock: this.stockInfo.idCompanyStock,
        idCompany: this.stockInfo.idCompany
      };
      this.sellBuySubmitReq(
        this._urls.sellOrderUrl,
        data,
        'sellOrderStopLimitForm',
        'sell'
      );
    }
  }

  sellBuySubmitReq(url, data, form, type = 'buy') {
    this._req.fetchApiData(url, data, false).subscribe(
      (data: any) => {
        const resSucc = data.data;
        const resErr = data.error;

        if (resSucc !== '') {
          this.sellOrderLoader = false;
          this.buyOrderLoader = false;
          this._sb.openSnackBar(
            'Your Order Has Been Placed Successfully',
            3000,
            'hybse_success_snackbar',
            'center',
            'top'
          );

          // (type == "buy") ? this.resetformVal('buyOrderForm','buy') : this.resetformVal('sellOrderForm','sell');
          this[form].reset();
          this[form].controls['commissionFee'].setValue('0.000001');
          this.getStockLists();
          this._market.getStockInfo(this.paramID);
          this.getStockInfo();
          this.sellBuySuccAction(form);
        }
        if (resErr !== '') {
          this._sb.openSnackBar(
            resErr['Error Description'],
            3000,
            'hybse_error_snackbar',
            'center',
            'top'
          );
        }
      },
      error => { },
      () => {
        this.sellOrderLoader = false;
        this.buyOrderLoader = false;
      }
    );
  }

  sellBuySuccAction(form) {
    switch (form) {
      case 'buyOrderForm':
        this.buyOrderSubmitted = false;
        this.resetMarketForm(form);
        break;
      case 'sellOrderForm':
        this.sellOrderSubmitted = false;
        this.resetMarketForm(form);
        break;
      case 'buyOrderLimitForm':
        this.buyOrderLimitSubmitted = false;
        break;
      case 'sellOrderLimitForm':
        this.sellOrderLimitSubmitted = false;
        break;
      case 'buyOrderStopLimitForm':
        this.BOStopLimitSubmitted = false;
        break;
      case 'sellOrderStopLimitForm':
        this.SOStopLimitSubmitted = false;
        break;
      default:
        break;
    }
  }
  resetMarketForm(form) {
    const price = form === 'buyOrderForm' ? 'bidPrice' : 'askPrice';
    const size = form === 'buyOrderForm' ? 'bidSize' : 'askSize';
    this[form].controls[price].setValue('');
    this[form].controls[size].setValue('');
  }

  resetformVal(formType, type = 'buy') {
    const size = type === 'buy' ? 'bidSize' : 'askSize';
    const total = type === 'buy' ? 'bidTotal' : 'askTotal';
    this[formType].controls[size].setValue('');
    this[formType].controls[total].setValue('');
    this[formType].controls['totalWithComission'].setValue('');
  }
  getStockLists() {
    this._market.getStockList();
    this.stockListSubs = this._market.stockList.subscribe((data: any) => {
      if (data != null) {
        if (data !== 'error') {
          this.stockList = []; this.stockListClone = [];
          this.stockList = data;
          this.addPropToStockList(data);
          this.stockListClone = new MatTableDataSource(this.stockList);
          if (this.routeData === 'market-info') { this.stockListClone.sort = this.sort; }
          if (this.routeData === 'market-info-pro') { this.stockListClone.sort = this.stockListProSort; }
          // this.stockListClone.sort.active = 'volume';
          this.getFavorites(data);
        }
      }
    });
  }
  getFavorites(data) {
    this.favoriteStockClone = this._utils.filteredArray(data, 'isFavourite', true);
    this.favStockBlk = this.favoriteStockClone;
    this.favStockBlk = this._utils.changeArrProp(this.favStockBlk, 'hideFavSec', false);
    this.favoriteStock = new MatTableDataSource(this.favoriteStockClone);
    if (this.routeData === 'market-info') { this.favoriteStock.sort = this.favoriteStockSort; }
    if (this.routeData === 'market-info-pro') { this.favoriteStock.sort = this.favoriteStockProSort; }
  }
  addPropToStockList(data) {
    data.forEach((ele, i) => {
      this.stockList[i].change = this.getChange(ele, 'percent');
    });
  }
  toggleFavSec() {
    this.favSec = !this.favSec;
    this.changeDetector.detectChanges();
    if (this.favSec) {
      this.favoriteStock.sort = this.favoriteStockProSort;
    } else {
      this.stockListClone.sort = this.stockListProSort;
    }

  }


  closeFavBlk(favStk) {
    favStk.hideFavSec = !favStk.hideFavSec;
  }

  openlimitBuy(type, stockInfo, accountDetails, bidPrice, availableAmount) {
    const dialogRef = this._dialog.open(LimitBuyComponent, {
      width: '450px',
      data: {
        stockInfo: stockInfo,
        type: type,
        accountDetails: accountDetails,
        bidPrice: bidPrice,
        availableAmount: availableAmount
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }
  openlimitSell(type, stockInfo, accountDetails, askPrice, availableShares) {
    console.log(availableShares);

    const dialogRef = this._dialog.open(LimitSellComponent, {
      width: '450px',
      data: {
        stockInfo: stockInfo,
        type: type,
        accountDetails: accountDetails,
        askPrice: askPrice,
        availableAmount: availableShares
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }
  setFormValues(data) {
    // this.buyOrderForm.controls['bidPrice'].setValue(data.priceCurrent);
    // this.sellOrderForm.controls['askPrice'].setValue(data.priceCurrent);

    this.buyOrderLimitForm.controls['bidPrice'].setValue(data.priceCurrent);
    this.sellOrderLimitForm.controls['askPrice'].setValue(data.priceCurrent);
  }
  getTotal(type, price, size, formName: string = 'market') {
    let orderPrice, orderSize, bidTotal, totalCommission;
    let form;
    // if (stopLimit) {
    //   form = type == "buy" ? this.buyOrderStopLimitForm : this.sellOrderStopLimitForm;
    // }
    switch (formName) {
      case 'market':
        form = type === 'buy' ? this.buyOrderForm : this.sellOrderForm;
        break;
      case 'limit':
        form = type === 'buy' ? this.buyOrderLimitForm : this.sellOrderLimitForm;
        break;
      case 'stop-limit':
        form = type === 'buy' ? this.buyOrderStopLimitForm : this.sellOrderStopLimitForm;
        break;
      default:
        break;
    }
    const total = type === 'buy' ? 'bidTotal' : 'askTotal';
    orderPrice = form.controls[price].value;
    if (formName === 'market') { orderPrice = this.stockInfo.priceCurrent; }
    orderSize = form.controls[size].value;
    bidTotal = orderPrice * orderSize;
    totalCommission = (this.commissionFee / 100) * bidTotal;
    if (!isNaN(orderSize) && orderSize !== '') {
      const valIncComm = totalCommission + bidTotal;
      form.controls[total].setValue((valIncComm).toFixed(6));
      const commisionVal: any = 0.000001 > (valIncComm - bidTotal) ? 0.000001 : (valIncComm - bidTotal);
      form.controls['commissionFee'].setValue((commisionVal).toFixed(6));
      // form.controls['totalWithComission'].setValue(totalCommission + bidTotal);
    } else {
      form.controls[total].setValue('');
      form.controls[total].setValue(0);
      form.controls['commissionFee'].setValue('0.000001');
      // form.controls['totalWithComission'].setValue('');
    }
  }
  getStockInfo() {
    this._market.stockInfo.subscribe(data => {
      if (data) {
        this.stockInfoLoader = false;
        if (data.status) {
          this.stockInfo = data.result;
          this.targetCurrency = this.stockInfo.targetCurr;
          this.stockInfoLoaded = true;
          this.getStockData();
          // this.getUserAccountDetails();
          this.setFormValues(this.stockInfo);
        } else {
          this.stockInfo = [];
          this.stockInfoLoaded = false;
        }
      }
    });
  }
  getStockData() {
    if (this.isAuthenticated) {
      this.stockDataSubs = this._market.stockForkData.subscribe(data => {
        if (data) {
          if (data.status) {
            const orderBook = data.result[1].data;
            const orderStats = data.result[2].data;
            const marketTrades = data.result[3].data;
            const matchedOrders = data.result[4].data;
            // this.setOrderBooks(orderBook);
            // this.setOrderStats(orderStats);
            this.setMarketTrades(marketTrades);
            // this.setMatchedOrders(matchedOrders);
          } else {

          }
        }
      });
    }
    else {
      this.stockDataSubs = this._market.stockForkData.subscribe(data => {
        if (data) {
          if (data.status) {
            const orderBook = data.result[0].data;
            this.setOrderBooks(orderBook);
          } else {

          }
        }
      });
    }
  }
  confirmLogin() {
    const dialogRef = this._dialog.open(ConfirmOrderLoginComponent, {
      width: '460px',
      panelClass: 'confirm-buy-container'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }
  setOrderBooks(orderBook) {
    if (orderBook !== '') {
      if (orderBook[0].hasOwnProperty('buyOrder')) {
        // this.setRecords('buyOrder', orderBook[0].buyOrder);
      }
      if (orderBook[0].hasOwnProperty('sellOrder')) {
        // this.setRecords('sellOrder', orderBook[0].sellOrder);
      }
    }
  }
  setMarketTrades(marketTrades) {
    this.marketTradeData = new MatTableDataSource(marketTrades === '' ? [] : marketTrades);
    this.marketTradeData.sort = this.MarketTradeSort;
  }

  setOrderStats(orderStats) {
    // if (orderStats != "") {

    // }
    this.allOrdersStats = new MatTableDataSource(orderStats === '' ? [] : orderStats);
    this.allOrdersStats.sort = this.allOrderSort;
    this.getOpenBuyOrders(orderStats);
    this.getOpenSellOrders(orderStats);
    this.getuserTradeData(orderStats);
  }
  setMatchedOrders(matchedOrders) {
    // if (matchedOrders != "") {

    // }
    const matchedOrds = matchedOrders === '' ? [] : matchedOrders;

    this.matchedOrdersData = new MatTableDataSource(matchedOrds);
    this.matchedOrdersData.sort = this.MatchdedOrderSort;
  }
  getOpenBuyOrders(orderStats) {
    let openBuyOrders = [];
    if (orderStats !== '') {
      openBuyOrders = orderStats.filter(ele => {
        return ele.type === 'BUY' && ele.status === 'Open';
      });
    }
    this.openBuyOrders = new MatTableDataSource(openBuyOrders);
    this.openBuyOrders.sort = this.OpenBuyOrderSort;
  }
  getOpenSellOrders(orderStats) {
    let openSellOrders = [];
    if (orderStats !== '') {
      openSellOrders = orderStats.filter(ele => {
        return ele.type === 'SELL' && ele.status === 'Open';
      });
    }
    this.openSellOrders = new MatTableDataSource(openSellOrders);
    this.openSellOrders.sort = this.OpenSellOrderSort;
  }
  getuserTradeData(orderStats) {
    let userTradeData = [];
    if (orderStats !== '') {
      userTradeData = orderStats.filter(ele => {
        return ele.status === 'Filled';
      });
    }
    this.userTradeData = new MatTableDataSource(userTradeData);
    this.userTradeData.sort = this.UserTradeSort;
  }
  // setRecords(type, data) {
  //   switch (type) {
  //     case 'buyOrder':
  //       this.buyOrders = new MatTableDataSource(data);
  //       this.buyOrders.sort = this.buyOrderSort;
  //       break;
  //     case 'sellOrder':
  //       this.sellOrders = new MatTableDataSource(data);
  //       this.sellOrders.sort = this.sellOrderSort;
  //       break;
  //     default:
  //       break;
  //   }
  // }

  getSetRecordsInWebsocket(type, data) {
    switch (type) {
      case 'buy':
        this.buyOrders = new MatTableDataSource(data);
        this.buyOrders.sort = this.buyOrderSort;
        break;
      case 'sell':
        this.sellOrders = new MatTableDataSource(data);
        this.sellOrders.sort = this.sellOrderSort;
        break;
      default:
        break;
    }
  }

  getChange(stock, type) {
    return this._market.getProfileLoss(stock, type);
  }
  getUserAccountDetails() {
    this._accDetail.getAccountDetails();
    this.actDetailSubs = this._accDetail.accountDetails.subscribe(data => {
      if (data !== 'error') {
        if (data.length > 0) {
          const primary = this._utils.filteredObj(data, 'isPrimary', true);
          const secondary = this._utils.filteredObj(data, 'isPrimary', false);
          this.accountDetails.primary = this._utils.filteredObj(data, 'isPrimary', true);
          this.accountDetails.secondary = this._utils.filteredObj(data, 'isPrimary', false);
          this.accountDetails.secondary = this._utils.filteredObj(this.accountDetails.secondary, 'idCompanyStock', this.stockInfo.idCompanyStock);
          this.accountDetails.primary = this._utils.filteredObj(this.accountDetails.primary, 'idCompanyStock', this.stockInfo.idCompanyStockPrime);
          this.getWltBal(data);
          this.primaryData = new MatTableDataSource(this.accountDetails.primary);
          this.secondaryData = new MatTableDataSource(secondary);
          this.primaryData.sort = this.PrimarySort;
          this.secondaryData.sort = this.SecondarySort;
        }
      } else if (data === 'error') {
      }
    });
  }
  getWltBal(data) {
    this.wltBalUSD = 0; this.wltBalBTC = 0;
    data.forEach((ele) => {
      this.wltBalUSD = this.wltBalUSD + ele.totalinUSD;
      this.wltBalBTC = this.wltBalBTC + ele.totalinBTC;
    });
  }


  deletePlaceOrders(res, type) {
    const deleteDialog = this._dialog.open(ConfirmBoxComponent, {
      width: '400px',
      // height: '170px',
      data: {
        message: 'Are you sure to delete this Order?'
      }
    });

    deleteDialog.afterClosed().subscribe(data => {
      if (data) {
        this.deleteOrder(res, type);
      } // this.deleteUser(id);
    });
  }

  deleteOrder(res, type) {
    const data = {
      idCompanyStock: res.idCompanyStock
    };
    type === 'buy'
      ? (data['idBuyorder'] = res.idOrder)
      : (data['idSellorder'] = res.idOrder);
    this._req
      .fetchApiData(this._urls.deleteOrderUrl, data, false)
      .subscribe((data: any) => {
        const resSucc = data.data;
        const resErr = data.error;
        if (resSucc !== '') {
          this._sb.openSnackBar(
            'Your Order Has Been Deleted Successfully',
            3000,
            'hybse_success_snackbar',
            'center',
            'top'
          );
          this._market.getStockInfo(this.paramID);
          this.getStockInfo();
        }

        if (resErr !== '') {
          this._sb.openSnackBar(
            resErr['Error Description'],
            3000,
            'hybse_error_snackbar',
            'center',
            'top'
          );
        }
      });
  }
  destroySubscriptions() {
    this._market.stockForkData.next(null);
    if (typeof this.stockListSubs !== 'undefined') {
      this.stockListSubs.unsubscribe();
    }
    if (typeof this.stockDataSubs !== 'undefined') {
      this.stockDataSubs.unsubscribe();
    }
    if (typeof this.actDetailSubs !== 'undefined') {
      this.actDetailSubs.unsubscribe();
    }
    if (typeof this.themeSubs !== 'undefined') {
      this.themeSubs.unsubscribe();
    }
  }
  changeThemeMode() {
    this.themeMode.dayTheme = !this.themeMode.dayTheme;
    this.themeMode.nightTheme = !this.themeMode.nightTheme;
  }

  // Window Resize Events
  @HostListener('window:resize', ['$event'])
  onResize(evt) {
  }
  getUserData() {
    this.userData = this._datum.getUserLoginData();
    if (Object.keys(this.userData).length === 0) { this.userData = ''; }
  }
  toggleFavorites(stock) {
    const url = stock.isFavourite ? this._urls.deleteCompanyFavouriteUrl : this._urls.createCompanyFavouriteUrl;
    const data = {
      idCompanyStock: stock.idCompanyStock
    };
    const favToggle = !stock.isFavourite;
    stock.isFavourite = !stock.isFavourite;
    this._req.fetchApiData(url, data, false).subscribe(
      (data: any) => {
        const resSucc = data.data;
        const resErr = data.error;
        if (resSucc !== '') {
          stock.isFavourite = favToggle;
          // stock.isFavourite = !stock.isFavourite;
          this.getStockLists();
          this._market.getStockInfo(this.paramID);
          this.getStockInfo();
        }
        if (resErr) {
          this._so.handleSessionOutErr(resErr);
        }
      }
    );
  }



  initiateSortable() {
    const seed = this;
    setTimeout(() => {
      // var pckry = new Packery( '.grid', {
      //   itemSelector: '.grid-item',
      //   columnWidth: 100
      // });

      // pckry.getItemElements().forEach( function( itemElem ) {
      //   var draggie = new Draggabilly( itemElem );
      //   pckry.bindDraggabillyEvents( draggie );
      // });

      $('#sortable').sortable({
        revert: true,
        classes: {
          'ui-sortable': 'hyb_blk_sortable',
          'ui-sortable-handle': 'hyb_sortable_handle'
        },
        update: function (event, ui) {
          const data = $('#sortable').sortable('toArray');
          seed._ls.setLocalItem('sortBlocks', data);
        }
      }).disableSelection();
    });
  }

  initiateFavSortable() {
    const seed = this;
    setTimeout(() => {
      // var pckry = new Packery( '.grid', {
      //   itemSelector: '.grid-item',
      //   columnWidth: 100
      // });

      // pckry.getItemElements().forEach( function( itemElem ) {
      //   var draggie = new Draggabilly( itemElem );
      //   pckry.bindDraggabillyEvents( draggie );
      // });

      $('#fav_sortable').sortable({
        revert: true,
        update: function (event, ui) {
          // var data = $( "#sortable" ).sortable('toArray');
          // seed._ls.setLocalItem('sortBlocks',data);
          // console.log(data);
        }
      }).disableSelection();
    }, 400);
  }




  sortBlockItems() {
    setTimeout(() => {
      let blks: any = this._ls.getLocalItems('sortBlocks');
      const parentBlk: any = document.getElementById('sortable');
      if (blks) {
        blks = JSON.parse(blks);
        blks.forEach((ele, ind) => {
          $('#' + ele).insertAfter('#sortable>div:eq(' + ind + ')');
        });
      }
    }, 0);

  }
  toggleddMenu() {
    this.dDMenuStatus = !this.dDMenuStatus;
  }
  getPreferredTheme() {
    const preferredTheme: any = this._ls.getLocalItems('preferredTheme');
    this.themeSubs = this._lo.preferredTheme.subscribe(
      data => {
        if (data != null) {
          this.preferredTheme = data;
        } else {
          this.preferredTheme = preferredTheme === 'true' ? true : false;
        }
      }
    );
  }
  revealDoubleWallet() {
    this.doubleWalletToggle = !this.doubleWalletToggle;
  }
  switchTheme() {
    this.currentTheme = this.currentTheme === 'light' ? 'dark' : 'light';
  }
  logOut() {
    this._logOut.logOut();
    // this._logOut.logOutLoader.subscribe(
    //   data => {
    //     if(data) {
    //       this.logOutLoader = false;
    //       this.menuStatus = false;
    //     }
    //   }
    // )
  }
  ngOnInit() {
    // var savedOrd = $.cookie('sortOrder');
    // if (savedOrd) {
    //     $.each(savedOrd.split(','), function (i, e) {
    //         $('#' + e).insertAfter('#sortable>div:eq(' + i + ')');
    //     });
    // }
    this.getPreferredTheme();
    this.sortBlockItems();
    this.getStockLists();
    this.buyOrderFormInit();
    this.sellOrderFormInit();
    this.buyOrderLimitFormInit();
    this.sellOrderLimitFormInit();
    this.buyOrderStopLimitFormInit();
    this.sellOrderStopLimitFormInit();
    this.initiateSortable();
    this.initiateFavSortable();
    this.getUserData();
    this.isAuthenticated = this._lo.getAuthenticated();
    this._ar.params.subscribe((params: any) => {
      if (params.id) {
        this.paramID = params.id;
        this._market.getStockInfo(this.paramID);
        this.getStockInfo();
      }
    });
    this._ar.data.subscribe(data => {
      this.routeData = data.name;
    });


    this._utils.doubleWalletToggle.subscribe(
      data => {
        this.doubleWalletToggle = data;
        this.isUserWalletBoolen.next(this.doubleWalletToggle);

      });
    this.getWebsocketOrderBook();
  }
public isCompanyStock:boolean =false;
  getWebsocketOrderBook(): void {
    this.sub = setInterval(() => {
      let data = {
        idCompanyStock: this.paramID
      }
      this._accDetail.userDetails(data).subscribe((response: any) => {
        const resSucc = response.companyData;
        this.userWallet = response.userWallet;

        this.setMatchedOrders(response.txnHistory);
        localStorage.setItem('companyWallet', JSON.stringify(resSucc));

        if (resSucc) {
          this.orderBookData = resSucc.data;
          this.getSetRecordsInWebsocket('buy', resSucc.buy);
          this.getSetRecordsInWebsocket('sell', resSucc.sell);
        }

        this.availableAmount = 0;
        this.availableShares = 0;
        if (this.isAuthenticated) {
          this.setOrderStats(response.userorder);
          localStorage.setItem('userWallet', JSON.stringify(this.userWallet));
          let usrWlt = this._utils.filteredObj(this.userWallet, 'isDemo', false);
          usrWlt = this._utils.filteredObj(usrWlt, 'isDemo', false);
          usrWlt.forEach(user => {
            if (user.targetCurr === this.targetCurrency) {
              if (user.isPrimary === 1) {
                this.availableAmount = user.availableAmount;
              }
            }
            if (user.idCompanyStock === +(this.paramID)) {
              this.availableShares = user.availableAmount;
            }
          });
        }
      });
    }, 1000);


    // this.sub = this.socketService.orderBook.subscribe(orderBook => {
    //   if (orderBook) {
    //     this.orderBookData = orderBook.data;
    //     this.getSetRecordsInWebsocket('buy', orderBook.buy)
    //     this.getSetRecordsInWebsocket('sell', orderBook.sell)
    //   }
    // });
  }

  ngOnDestroy() {
    this.destroySubscriptions();
    clearInterval(this.sub);

  }

  navToMarket(stock) {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.showLoader = true;
    setTimeout(() => {
      this.showLoader = false;
    }, 400);
    this._router.navigate(['market-info-pro', stock.idCompanyStock])

  }
}
