import { environment } from '../../environments/environment';

export class HybseConfig {
  // authURL: string = 'https://batp-auth.business-software.in/api/';

  // apiURL:string = 'https://api.hybse.dev/';
  // apiURL:string = 'http://127.0.0.1:8000/';
  // apiURL:string = 'https://apollo.hybsedev.ddns.net/';
  // apiURL:string = 'https://apollo.business-software.in/api/';

  // apiURL:string = environment.apiUrl;
  // apiURL:string = 'https://apollo.hybse.com/api/';


  // apiURL = 'https://apiv2.hybse.com/api/';
  // authURL = 'https://stageauth.hybse.com/api/';

  // old_version
  // apiURL:string = 'https://stockv3.hybse.com/api/';
  // authURL: string = 'https://authv3.hybse.com/api/';

  // new_version
  // apiURL:string = 'https://stockv3.hybse.com/api/';
  // authURL: string = 'https://authv3.hybse.com/api/';

  // bex dev version
  /*apiURL:string = 'https://hbv3.business-software.in/api/';
  authURL: string = 'https://hbauth.business-software.in/api/';
  pollingURL:string = `https://hybsewebsocket.business-software.in/polling`;
  chartURL:string = 'https://hbv3.business-software.in/statistics/';*/

  // bex prod version
  apiURL: string = 'https://v3.bex.global/api/';
  authURL: string = 'https://auth.bex.global/api/';
  pollingURL: string = `https://ws.bex.global/polling`;
  chartURL: string = 'https://v3.bex.global/statistics/';

  kycURL = `https://knowmenow.com/merchantnewclient/api/v1/`;
}

export const fsKey: string = environment.fsKey;

export const devEnv: boolean = environment.production ? false : true;

export const captchaKey: string = environment.captchaKey;

export const idNowUrl: string = environment.idNowUrl;

export const nemApiUrl: string = environment.nemApiUrls;

export const hybse_versionNo = '3.0.1';

export const hybse_buildNo = '219012';

// export const hybse_versionNo:string ='2.2.25';

// export const hybse_buildNo:string ='218630';

export const _ibin_api_url = 'https://ibin.global/api/';

export const _tvDatafeedUrl = environment.tvDatafeedUrl;

// export const _tvDatafeedUrl = "https://apollo.business-software.in/statistics";
// export const _tvDatafeedUrl = "https://apiv2.hybse.com/api/statistics";
