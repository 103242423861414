import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../../../services/utilities/utility.service';


declare var $:any;
@Component({
  selector: 'batp-press-center',
  templateUrl: './press-center.component.html',
  styleUrls: ['./press-center.component.css']
})
export class PressCenterComponent implements OnInit {

  constructor(private _utils:UtilityService) { }

  goTocontact() {
    let ele = document.getElementById('press_ct_blk');
    this._utils.scrollToY(ele.offsetTop, 400, 'easeInOutQuint');
  }


  ngOnInit() {
  }

}
