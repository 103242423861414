import { Component, OnInit } from '@angular/core';
import { DatumService } from 'src/app/services/datum/datum.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';

@Component({
  selector: 'batp-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  public walletAddress: string;
  constructor(private _datum: DatumService, private _sb: SnackbarService) { }

  elementType: 'url' | 'canvas' | 'img' = 'url';
  value: string;
  width: string = '200px';
  height: string = '200px';
  completeRegStep() {

  }
  ngOnInit() {
    const userDetails = this._datum.getUserDetails();
    this.walletAddress = userDetails.internalAddress;
    this.value = userDetails.internalAddress;
  }
  copyAddress(input) {
    input.select();
    document.execCommand('copy');
    this._sb.openSnackBar('Wallet Address Copied to Clipboard', 2000, 'hybse_success_snackbar', 'right', 'top');
  }
}
