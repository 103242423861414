import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import { Jsonp, JsonpModule } from '@angular/http';
import { ApiUrlService } from 'src/app/services/api-url/api-url.service';

import { ApirequestService } from 'src/app/services/apirequest/apirequest.service';

@Component({
  selector: 'batp-high-area-charts',
  templateUrl: './high-area-charts.component.html',
  styleUrls: ['./high-area-charts.component.css']
})
export class HighAreaChartsComponent implements OnInit, OnChanges {
  @ViewChild('container', { read: ElementRef }) container: ElementRef;
  @Input() idCompanyStock: any = '';
  @Input() viewMode: any = '';
  @Input() standardView: any = '';
  Highcharts = Highcharts;
  public chartOptions: any;
  arrayData: any;

  constructor(private _urls: ApiUrlService, private _req: ApirequestService) {
    Highcharts.theme = {
      colors: ['#2b908f', '#90ee7e', '#f45b5b', '#7798BF', '#aaeeee', '#ff0066',
        '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
      chart: {
        backgroundColor: {
          linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
          stops: [
            [0, 'rgb(22, 26, 35)'],
            [1, 'rgb(22, 26, 35)']
          ]
        },
        style: {
          fontFamily: '\'Unica One\', sans-serif'
        },
        plotBorderColor: '#606063'
      },
      title: {
        style: {
          color: '#E0E0E3',
          textTransform: 'uppercase',
          fontSize: '20px'
        }
      },
      subtitle: {
        style: {
          color: '#E0E0E3',
          textTransform: 'uppercase'
        }
      },
      xAxis: {
        gridLineColor: '#707073',
        labels: {
          style: {
            color: '#E0E0E3'
          }
        },
        lineColor: '#707073',
        minorGridLineColor: '#505053',
        tickColor: '#707073',
        title: {
          style: {
            color: '#A0A0A3'

          }
        }
      },
      yAxis: {
        gridLineColor: '#707073',
        labels: {
          style: {
            color: '#E0E0E3'
          }
        },
        lineColor: '#707073',
        minorGridLineColor: '#505053',
        tickColor: '#707073',
        tickWidth: 1,
        title: {
          style: {
            color: '#A0A0A3'
          }
        }
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        style: {
          color: '#F0F0F0'
        }
      },
      plotOptions: {
        series: {
          dataLabels: {
            color: '#B0B0B3'
          },
          marker: {
            lineColor: '#333'
          }
        },
        boxplot: {
          fillColor: '#505053'
        },
        candlestick: {
          lineColor: 'white'
        },
        errorbar: {
          color: 'white'
        }
      },
      legend: {
        itemStyle: {
          color: '#E0E0E3'
        },
        itemHoverStyle: {
          color: '#FFF'
        },
        itemHiddenStyle: {
          color: '#606063'
        }
      },
      credits: {
        style: {
          color: '#666'
        }
      },
      labels: {
        style: {
          color: '#707073'
        }
      },

      drilldown: {
        activeAxisLabelStyle: {
          color: '#F0F0F3'
        },
        activeDataLabelStyle: {
          color: '#F0F0F3'
        }
      },

      navigation: {
        buttonOptions: {
          symbolStroke: '#DDDDDD',
          theme: {
            fill: '#505053'
          }
        }
      },

      // scroll charts
      rangeSelector: {
        buttonTheme: {
          fill: '#505053',
          stroke: '#000000',
          style: {
            color: '#CCC'
          },
          states: {
            hover: {
              fill: '#707073',
              stroke: '#000000',
              style: {
                color: 'white'
              }
            },
            select: {
              fill: '#000003',
              stroke: '#000000',
              style: {
                color: 'white'
              }
            }
          }
        },
        inputBoxBorderColor: '#505053',
        inputStyle: {
          backgroundColor: '#333',
          color: 'silver'
        },
        labelStyle: {
          color: 'silver'
        }
      },

      navigator: {
        handles: {
          backgroundColor: '#666',
          borderColor: '#AAA'
        },
        outlineColor: '#CCC',
        maskFill: 'rgba(255,255,255,0.1)',
        series: {
          color: '#7798BF',
          lineColor: '#A6C7ED'
        },
        xAxis: {
          gridLineColor: '#505053'
        }
      },

      scrollbar: {
        barBackgroundColor: '#808083',
        barBorderColor: '#808083',
        buttonArrowColor: '#CCC',
        buttonBackgroundColor: '#606063',
        buttonBorderColor: '#606063',
        rifleColor: '#FFF',
        trackBackgroundColor: '#404043',
        trackBorderColor: '#404043'
      },

      // special colors for some of the
      legendBackgroundColor: 'rgba(0, 0, 0, 0.5)',
      background2: '#505053',
      dataLabelsColor: '#B0B0B3',
      textColor: '#C0C0C0',
      contrastTextColor: '#F0F0F3',
      maskColor: 'rgba(255,255,255,0.3)'
    };
    Highcharts.setOptions(Highcharts.theme);
  }
  ngOnChanges() {
    setTimeout(() => {
      this.getHighCharts();
    }, 400);
  }
  ngOnInit() {
    this.getHighCharts();
  }
  private getHighCharts(): void {
    this._req.fetchApiData(this._urls.areachartsUrl + `idCompanyStock=${this.idCompanyStock}`, {}, true, 'get').subscribe((res: any) => {

      this.arrayData = res;
      Highcharts.stockChart(this.container.nativeElement, {
        rangeSelector: {
          buttons: [{
            type: 'month' as 'month',
            count: 1,
            text: '1m',
            events: {
              click: function (e) {
                console.log('button clickd');
              }
            }
          }, {
            type: 'month',
            count: 3,
            text: '3m'
          }, {
            type: 'month',
            count: 6,
            text: '6m'
          }, {
            type: 'ytd',
            text: 'YTD'
          }, {
            type: 'year',
            count: 1,
            text: '1y'
          }, {
            type: 'all',
            text: 'All'
          }]
        },
        chart: {
          zoomType: 'x',
          events: {
            load: function () {

              // set up the updating of the chart each second
              let series = this.series[0];
              let chartData = res;
              let lastPrice = 0;
              let pauser = 0;

              let companyWallet: any = '';

              this.sub = setInterval(function () {
                if (pauser === 6) {
                  companyWallet = JSON.parse(localStorage.getItem('companyWallet'));
                }
                if (companyWallet !== null && pauser > 5) {
                  var x = (new Date()).getTime(), // current time
                    y = parseFloat(companyWallet.data.currentPrice);

                  //console.log(chartData);

                  if (lastPrice !== y) {
                    lastPrice = y;
                    chartData.push(Array(x, y));
                    series.setData(chartData);
                  }
                  pauser = 0;
                }
                pauser++;
              }, 1000);
            }
          }
        },
        credits: {
          style: {
            color: '#666'
          },
          enabled: false,
        },
        series: [{
          type: 'areaspline',
          name: 'Price',
          data: res,
          tooltip: {
            valueDecimals: 2
          },
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, Highcharts.getOptions().colors[0]],
              [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
            ]
          },
          threshold: null
        },
        ],
        xAxis: {
          events: {
            afterSetExtremes: (e) => {
              // this.button = e.rangeSelectorButton.count;

            }
          }
        },
      });

    },
      (errror) => {

      });

  }

}
