import { Component, OnInit } from "@angular/core";
import { ApiUrlService } from "src/app/services/api-url/api-url.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ApirequestService } from "src/app/services/apirequest/apirequest.service";
import { UtilityService } from "src/app/services/utilities/utility.service";
import { businessActivities, bussActivities } from "src/app/shared/data/business-activities";

@Component({
  selector: "batp-market-details",
  templateUrl: "./market-details.component.html",
  styleUrls: ["./market-details.component.css"]
})
export class MarketDetailsComponent implements OnInit {
  constructor(
    private _ac: ActivatedRoute,
    private _req: ApirequestService,
    private _route: Router,
    private _utils: UtilityService,
    private _urls: ApiUrlService
  ) {}
  isMobile:boolean = this._utils.checkMobileDevice();
  showLoader:boolean = true;
  companyLogo :string ='';
  navtoMktTrade() {
    let marketInfo = this.isMobile ? 'market-info-mobile' : 'market-info-pro';
    this._route.navigate([marketInfo,this.idCompanyStock])
  }
  companyType:string = 'stock';
  titleConfigs:any = {
    stock: 'Company',
    bond: 'Assets / Bond',
    etf: 'Commodity'
  }
  companyDetails:any = {};

  getCompDetails(id) {
    let data = { id: id };
    this._req.fetchApiData(this._urls.companyDetailsUrl, data).subscribe((data: any) => {
      let resSucc = data.data;
      let resErr = data.error;
      if (resSucc != "") {
        this.companyDetails = resSucc;
        this.companyType = this.companyDetails.company.companyType.toLowerCase();
        this.companyLogo = this.getCompanyLogo(this.companyDetails.companyDocument);
        this.showLoader = false;
      }
      if (resErr != "") {

      }
    });
  }

  getCompanyLogo(docs) {
    for (let el of docs) {
      if (el.docTitle === 'logo') {
        return el.docName;
      }
    }
  }

  getMemberImg(img) {
    return img == "" ? "/assets/images/no_member_image.png" : img;
  }

  businessActivities:bussActivities[] = businessActivities;
  getMarket(id) {
    let market = this._utils.getPropValFromObj(this.businessActivities,'val','name',id)
    return market;
  }
  idCompanyStock:any = '';
  ngOnInit() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this._ac.params.subscribe(data => {
      if (typeof data.id !== 'undefined') {
        this.getCompDetails(data.id);
        this.idCompanyStock = data.idStock;
      }
    });
  }
}
