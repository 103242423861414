import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApirequestService } from "src/app/services/apirequest/apirequest.service";
import { SnackbarService } from "src/app/services/snackbar/snackbar.service";
import { ApiUrlService } from "src/app/services/api-url/api-url.service";
import { validNumberWithDecimal } from "src/app/shared/form-validators";

@Component({
  selector: "batp-transfer-box",
  templateUrl: "./transfer-box.component.html",
  styleUrls: ["./transfer-box.component.css"]
})
export class TransferBoxComponent implements OnInit {
  constructor(
    private _fb: FormBuilder,
    private _req: ApirequestService,
    private _sb: SnackbarService,
    private _url: ApiUrlService
  ) {}

  @Input() transferType: string;
  @Input() currencyType: string;
  @Input() idUserWallet: string;
  @Input() idCompanyStock: string;

  @Output() transferStatus: any = new EventEmitter();

  transferAmount: string = "";
  transferForm: FormGroup;
  transferFormInit() {
    this.transferForm = this._fb.group({
      amount: ["", [Validators.required, validNumberWithDecimal]]
    });
  }

  showLoader: boolean = false;
  transferSubmit() {
    if (this.transferForm.valid) {
      this.showLoader = true;
      let formVal = this.transferForm.value;
      let data = {
        idUserWallet: this.idUserWallet,
        idCompanyStock: this.idCompanyStock,
        amount: formVal.amount
      };
      let url =
        this.transferType == "depot"
          ? this._url.depositToTradingWalletUrl
          : this._url.withdrawFromTradingWalletUrl;

      this._req.fetchApiData(url, data, false).subscribe(
        (data: any) => {
          let resSucc = data.data;
          let resErr = data.error;
          this.showLoader = false;
          if (resSucc != "") {
            this._sb.openSnackBar(
              "Amount Transferred Successfully",
              3000,
              "hybse_success_snackbar",
              "right",
              "top"
            );
            this.transferStatus.next("success");
          }
          if (resErr != "") {
            this._sb.openSnackBar(
              resErr["Error Description"],
              3000,
              "hybse_error_snackbar",
              "right",
              "top"
            );
          }
        },
        error => {},
        () => {}
      );
    }
  }

  ngOnInit() {
    this.transferFormInit();
  }
}
