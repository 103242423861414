import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'batp-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {


  constructor() { }
  @Input() loaderType:string = 'loader1';



  ngOnInit() {

  }

}
