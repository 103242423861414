import { Component, OnInit, Input } from '@angular/core';
import { hybse_versionNo, hybse_buildNo } from '../../../config/hybse.config';
import { CetTimeService } from '../../../services/cetTime/cet-time.service';
import { environment } from '../../../../environments/environment';
import { VideoDialogComponent } from '../../widgets/video-dialog/video-dialog.component';
import { MatDialog } from '@angular/material';
import { PageContentService } from 'src/app/services/pageContent/page-content.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LoginService } from 'src/app/services/login/login.service';
import { DatumService } from 'src/app/services/datum/datum.service';
import { learningCenterCatId, learningCenterAlias } from 'src/app/shared/data/content-page-data';
import { ApiUrlService } from 'src/app/services/api-url/api-url.service';
import { ApirequestService } from 'src/app/services/apirequest/apirequest.service';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/localstorage/storage.service';
import { UtilityService } from 'src/app/services/utilities/utility.service';


@Component({
  selector: 'batp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private _cet:CetTimeService,
    private _dialog: MatDialog,
    private _ps: PageContentService,
              private _sanitize: DomSanitizer,
              private _ls: LoginService,
              private _datum: DatumService,
              private _req: ApirequestService,
              private _url: ApiUrlService,
              private _route: Router,
              private _ss: StorageService,
              private _utils:UtilityService) { }
  @Input() currentRoute:string;
  versionNo:any = hybse_versionNo;
  buildNo:any = hybse_buildNo;
  learningCenter:any = [];
  showTime:any;
  showDate:any = new Date();
  showTestNetBar:boolean = environment.showTestNetSections;
  showPO = false;
  currentYear = new Date().getFullYear();
  isMobile:boolean = false;
  openVideo() {
    const dialogRef = this._dialog.open(VideoDialogComponent, {
      width: '800px',
      data: {
        embedUrl: ''
      }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }
  isAuthenticated:boolean = false;
  getLcCont() {
    this._ps.getContentByCategory(learningCenterCatId, learningCenterAlias);
    this._ps.learningCenter
      .subscribe(
        data => {
          if(data) {
            this.learningCenter = data;
          }
        }
      );
  }
  // demoToken
  userData:any;
  getUserData() {
    this.userData = this._datum.getUserLoginData();
  }
  getDemoUrl( prod: boolean = true ) {
    let url = environment.demoUrl + this.userData.demoToken;
    //let url = 'https://demostage.business-software.in?demoToken=' + this.userData.demoToken;
    return url;
  }
  @Input() paramIdHdr:any;
  getMarketRoute(route) {
    let url = [];
    if(this.stockList.length > 0) {
      setTimeout(()=>{
        if(typeof this.paramIdHdr == 'undefined')
        this.paramIdHdr = this.stockList[0].idCompanyStock;
        url = [route,this.paramIdHdr];
        this._ss.setLocalItem('latestView',url[0]);
        this._route.navigate(url);
        this.menuToggle(false);
      },400);
    }
  }
  stockList:any = [];
  getStockList() {
    let data = {};
    if(this.userData != '') data = { idUser: this.userData.idUser };
    this._req.fetchApiData(this._url.stockListUrl,data,true).subscribe(
      (data:any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if(resSucc != '') {
          this.stockList = resSucc;
        }
      }
    )
  }
  menuStatus:boolean = false;
  menuToggle(bool) {
    this.menuStatus = bool;
  }
  ngOnInit() {
    this.isAuthenticated = this._ls.getAuthenticated();
    this.isMobile = this._utils.checkMobileDevice();
    this.getLcCont();
    this.getUserData();
    this._cet.getCetTime();
    this.getStockList();
    this._cet.showTime.subscribe(
      data => {
        this.showTime = data;
      }
    )
  }

}
