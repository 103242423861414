import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/services/utilities/utility.service';

@Component({
  selector: 'batp-document-center',
  templateUrl: './document-center.component.html',
  styleUrls: ['./document-center.component.css']
})
export class DocumentCenterComponent implements OnInit {

  constructor(private _utils:UtilityService) { }

  isMobile:boolean = false;

  ngOnInit() {
    this.isMobile = this._utils.checkMobileDevice();
  }

}
