import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApirequestService } from 'src/app/services/apirequest/apirequest.service';
import { ApiUrlService } from 'src/app/services/api-url/api-url.service';
import { DatumService } from 'src/app/services/datum/datum.service';

@Component({
  selector: 'batp-bronze',
  templateUrl: './bronze.component.html',
  styleUrls: ['./bronze.component.css']
})
export class BronzeComponent implements OnInit {
  public QRCode: string;
  token;
  public type: string;
  public level: string;
  private userDetails;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<BronzeComponent>,
    private _url: ApiUrlService,
    private _req: ApirequestService,
    private _datum: DatumService) {

    this.type = data.type;
    this.level = data.level;
  }

  ngOnInit() {
    this.userDetails = this._datum.getUserLoginData();
    // if (this.type === 'bronze') {
    //   this.dialogRef.updatePosition({ top: '10%', left: '192px' });
    // } else {
    //   this.dialogRef.updatePosition({ top: '10%', left: '557px' });
    // }
    this.qrCode();
  }

  qrCode(): void {
    const data = {
      level: this.level,
      username: this.userDetails.username
    };
    this._req.fetchApiData(this._url.getQRcodeUrl, data, false).subscribe((response: any) => {
      if (response) {
        this.QRCode = 'data:image/png;base64,' + response.data;
        // this.getAddWebHooks();
      }
    });
  }

  getAddWebHooks() {
    const data = {
      url: 'https://apollo.business-software.in/api/updateKmn'
    };
    this._req.fetchPost(this._url.webHooksAddUrl, data, this.token).subscribe((res: any) => {
    });
  }
}
