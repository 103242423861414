import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatumService } from '../../../../../services/datum/datum.service';
import { ApirequestService } from '../../../../../services/apirequest/apirequest.service';
import { ApiUrlService } from '../../../../../services/api-url/api-url.service';
import { SnackbarService } from '../../../../../services/snackbar/snackbar.service';
import { SessionOutService } from '../../../../../services/sessionOut/session-out.service';

@Component({
  selector: 'batp-redeem-box',
  templateUrl: './redeem-box.component.html',
  styleUrls: ['./redeem-box.component.css']
})
export class RedeemBoxComponent implements OnInit {

  constructor(private _fb: FormBuilder,
              private _req: ApirequestService,
              private _urls: ApiUrlService,
              private _sb: SnackbarService,
              private _so: SessionOutService,
              private _datum: DatumService) { }

  voucherForm: FormGroup;
  showLoader: boolean = false;

  userDetails: any;

  voucherFormInit() {
    this.voucherForm = this._fb.group({
      couponCode: ['', [Validators.required]]
    });
  }

  getUserDetails() {
    this.userDetails = this._datum.getUserDetails();
  }

  voucherSubmit() {
    if (this.voucherForm.valid) {
      this.showLoader = true;
      const data = {
        ...this.voucherForm.value
      };
      this._req.fetchApiData(this._urls.redeemCouponUrl, data, false).subscribe(
          (data: any) => {
            const resSucc = data.data;
            const resErr = data.error;
            if (resSucc !== '') {
              this.setStatusMsg(resSucc['Success Description'], 3000, 'batp_success_snackbar', 'right', 'top');
              this.voucherForm.reset();
            }
            if (resErr !== '') {
              this.setStatusMsg(resErr, 3000, 'batp_error_snackbar', 'right', 'top');
              this._so.handleSessionOutErr(resErr);
            }
          },
          error => {

          },
          () => {
            this.showLoader = false;
          }
      );
    }
  }

  setStatusMsg(message, duration, htmlClass, x, y) {
    this._sb.openSnackBar(message, duration, htmlClass, x, y);
  }

  ngOnInit() {
    this.voucherFormInit();
    this.getUserDetails();
  }




  ngOnChanges() {
  }

}
