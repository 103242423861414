import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiUrlService } from "src/app/services/api-url/api-url.service";
import { ApirequestService } from "src/app/services/apirequest/apirequest.service";

@Component({
  selector: "batp-press-release-detail",
  templateUrl: "./press-release-detail.component.html",
  styleUrls: ["./press-release-detail.component.css"]
})
export class PressReleaseDetailComponent implements OnInit {
  constructor(
    private _route: ActivatedRoute,
    private _url: ApiUrlService,
    private _req: ApirequestService
  ) {}

  pressReleaseDetails: any = [];
  showLoader: boolean = true;
  getPressReleaseDetails(alias) {
    let data = {
      alias: alias
    };
    this._req.fetchApiData(this._url.getContentUrl, data).subscribe(
      (data: any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if (resSucc != "") {
          this.pressReleaseDetails = resSucc[0];
        }
        if (resErr != "") {
        }
      },
      error => {},
      () => {
        this.showLoader = false;
      }
    );
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      if (params.alias) {
        this.getPressReleaseDetails(params.alias);
      }
    });
  }
}
