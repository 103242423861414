import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ApirequestService } from '../../../services/apirequest/apirequest.service';
import { ApiUrlService } from '../../../services/api-url/api-url.service';
import { countries, Countries } from '../../../shared/data/countries';
import { validateEmail } from '../../../shared/form-validators';
import { ErrorMessages } from '../../../shared/error-messages';
import { devEnv, captchaKey } from '../../../config/hybse.config';
import { iconSet, messageBoxIcon } from '../../../shared/shared.data';
import { trigger, transition, state, style, animate } from '@angular/animations';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';


declare var $:any;
@Component({
  selector: 'batp-get-in-touch',
  templateUrl: './get-in-touch.component.html',
  styleUrls: ['./get-in-touch.component.css'],
  animations: [
    trigger('FadeAnimation', [
      state('in', style({
        opacity: 1
      })),
      transition(':enter', [
        style({opacity: 0}),
        animate(600 )
      ]),
      transition(':leave',
        animate(600, style({opacity: 0})))
    ])
  ]
})
export class GetInTouchComponent implements OnInit {

  constructor(private _fb: FormBuilder,
              private _location: Location,
              private _route: Router,
    private _req: ApirequestService,
    private _url: ApiUrlService,
    private _sb: SnackbarService) { }
              registrationComp: boolean = false;

    countryList: Countries[] = countries;
  userRegisterForm: FormGroup;
  userRegisterSubmit:boolean = false;
  registerSubmitted:boolean = false;
  errorMessages:any = new ErrorMessages();
  showLoader:boolean = false;
  registerMessage:string = '';
  registerStatus:any = '';
  messIcons:iconSet = messageBoxIcon;
  captchaKey:string = captchaKey;
  initiateRegFlow:boolean = false;
  userLoginFormInit() {
    this.userRegisterForm = this._fb.group({
        'firstName': ['', [ Validators.required ]],
        'lastName': ['', [ Validators.required ]],
        'country': ['', [ Validators.required ]],
        'phone': ['', [ Validators.required ]],
        'email': ['', [ Validators.required, validateEmail ]],
        'investment': [''],
        'googleRecaptcha': ['', devEnv ? [] : [Validators.required]],
        'contactBy': ['', [ Validators.required ]],
        'preferredLanguage': ['', [ Validators.required ]],
        'preferredTime': [''],
        'g_recatcha': ['', devEnv ? [] : [Validators.required] ]
      }
    );
  }

  userRegister() {
    this.userRegisterSubmit = true;
    if(this.userRegisterForm.valid) {
      this.showLoader = true;
      let formVal:any = this.userRegisterForm.value;
      let data:any = {
          firstname: formVal.firstName,
          lastname: formVal.lastName,
          country: formVal.country,
          phone: formVal.phone,
          email: formVal.email,
          invamount: formVal.investment,
          prefcontact: formVal.contactBy,
          preflang: formVal.preferredLanguage,
          preftime: formVal.preferredTime,
          googleRecaptcha: formVal.googleRecaptcha,
      };

        this._req.fetchApiData(this._url.getInTouchUrl,data).subscribe(
            (data:any) => {
                let resSucc = data.data;
                let resErr = data.error;
                if(resSucc) {
                    this._sb.openSnackBar( resSucc['Success Msg'],5000,'batp_success_snackbar','center','top');
                }
                if(resErr) {
                    this._sb.openSnackBar( resErr['Error Description'],5000,'batp_error_snackbar','center','top');
                }
                this.userRegisterForm.reset();
                this.registerSubmitted = resSucc ? true : false;
            },
            error => {

            },
            () => {
                this.showLoader = false;
            }
        )
    }
  }

  handleSuccess(event) {
    this.userRegisterForm.controls['googleRecaptcha'].setValue(event);
  }
  getSlStatus($evt) {
    this.showLoader = true;
    if(Object.keys($evt.error).length != 0) {
      this.registerStatus = false;
      this.showLoader = false;
      this.registerMessage = $evt.error['Error Description'];
    }
  }

  goToPreviousPage() {
    this._location.back();
  }



  ngOnInit() {
    this.userLoginFormInit();
  }


  ngOnDestroy() {
  }
}
