import { Component, OnInit, Output,Input, EventEmitter } from '@angular/core';
import { wizardErrorMessage, wizardMessages } from 'src/app/shared/wizard-error-messages';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material';
import { validNumberWithDecimal, validNumber } from 'src/app/shared/form-validators';

@Component({
  selector: 'batp-wiz-ibo',
  templateUrl: './wiz-ibo.component.html',
  styleUrls: ['./wiz-ibo.component.css']
})
export class WizIboComponent implements OnInit {
  @Output() iboData = new EventEmitter();
  constructor(private _fb:FormBuilder) { }
  IboInfoDetail:any;
  errorMessage:wizardMessages = wizardErrorMessage;
  companyIboForm:FormGroup;


  @Input() companyIboInf:any = '';
  @Output() IboInfoDatas = new EventEmitter();
  @Output() prevStepper = new EventEmitter();

  companyIboFormInit() {
    this.companyIboForm = this._fb.group({
      isibo:['',[Validators.required]],
      fromDate: ['',[]],
      toDate: ['',[]],
      discount: ['',[]],
      volume: ['',[]]
    })
    const fromDate = this.companyIboForm.get('fromDate');
    const toDate = this.companyIboForm.get('toDate');
    const discount = this.companyIboForm.get('discount');
    const volume = this.companyIboForm.get('volume');
    this.companyIboForm.get('isibo').valueChanges.subscribe(
      (mode: string) => {
          if (mode === '1') {
              fromDate.setValidators([Validators.required]);
              toDate.setValidators([Validators.required]);
              volume.setValidators([Validators.required,validNumber]);
              discount.setValidators([Validators.required,validNumberWithDecimal]);
          }
          else {
            fromDate.clearValidators();
            toDate.clearValidators();
            volume.clearValidators();
            discount.clearValidators();
          }
          fromDate.updateValueAndValidity();
          toDate.updateValueAndValidity();
          discount.updateValueAndValidity();
          volume.updateValueAndValidity();
      });
  }
  companyIboFormSubmit() {
    this.IboInfoDetail = this.companyIboForm.value;
    let exportData:any = {
      formData: this.IboInfoDetail,
      goTo: 5
    }
    this.iboData.emit(exportData);
  }
  goToPrevious() {
    this.IboInfoDetail = this.companyIboForm.value;
    let prevExpData:any = {
      formData: this.IboInfoDetail,
      goTo: 3
    };
    this.prevStepper.emit(prevExpData);
  }
  fromDate:any;
  toDate:any;
  datePickerChanged(event: MatDatepickerInputEvent<Date>) {
    const d = new Date(event.value);
    this.fromDate = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
    this.fromDate = new Date(this.fromDate);
  }
  datePicker2Changed(event: MatDatepickerInputEvent<Date>) {
    const d = new Date(event.value);
    this.toDate = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
    this.toDate = new Date(this.toDate);
  }
  setFormValues() {
    setTimeout(()=>{
      if(typeof this.companyIboForm != undefined) {
        this.companyIboForm.patchValue(this.companyIboInf);
      }
    },200);
  }

  ngOnInit() {
    this.companyIboFormInit();
  }
  ngOnChanges() {
    if(this.companyIboInf != '') {
      this.setFormValues();
    }
  }
}
