import { Component, OnInit } from '@angular/core';
import { MarketsService } from 'src/app/services/markets/markets.service';
import { ApirequestService } from 'src/app/services/apirequest/apirequest.service';
import { ApiUrlService } from 'src/app/services/api-url/api-url.service';
import { LoginService } from 'src/app/services/login/login.service';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/services/utilities/utility.service';
import { StorageService } from 'src/app/services/localstorage/storage.service';

@Component({
  selector: 'batp-market-ticker',
  templateUrl: './market-ticker.component.html',
  styleUrls: ['./market-ticker.component.css']
})
export class MarketTickerComponent implements OnInit {

  constructor(private _market: MarketsService,
    private _req: ApirequestService,
    private _url: ApiUrlService,
    private _lo: LoginService,
    private _ls: StorageService,
    private _route: Router,
    private _utils: UtilityService
  ) { }


  isMobile: boolean = this._utils.checkMobileDevice();
  stockLists: any = [];
  newsLists: any = [];
  showLoader: boolean = true;
  getStockList() {
    this._market.getStockList();
    this._market.stockList.subscribe(
      (data: any) => {
        if (data != null) {
          if (data != 'error') {
            this.stockLists = data;
            this.stockLists.sort(this._utils.dynamicSort('companyName'));
            this.initiateTicker();
            this.showLoader = false;
          } else {
            this.showLoader = false;
          }
        } else {
        }
      },
      error => {

      },
      () => {

      }
    );

    // this._req.fetchApiData(this._url.demoStockListUrl,{},false).subscribe(
    //   (data:any) => {
    //     let resSucc = data.data;
    //     let resErr = data.error;
    //     if(resSucc != '') {
    //       this.stockLists = resSucc;
    //       this.stockLists.sort(this._utils.dynamicSort('companyName'))
    //       this.initiateTicker();
    //       this.showLoader = false;
    //     }

    //     if(resErr != '') {
    //     }
    //   }
    // )
  }

  getNewsList() {
    this._market.getNewsList();
    this._market.newsListsRes.subscribe(
        (data:any) => {
          if (data != null) {
            if (data.data.length > 0) {
              this.newsLists = data.data;
              // this.iboLists.sort(this._utils.dynamicSort("idCompany"));
              this.newsLists = this._utils.filterArrayObj(this.newsLists, 'ispublished', false);
              this.initiateNewsTicker();
              this.showLoader = false;
            }
            if (data.error) {
              this.showLoader = false;
            }
          }
        }
    );
  }

  getChange(stock, type) {
    return this._market.getProfileLoss(stock, type);
  }

  initiateTicker() {
    setTimeout(() => {
     (<any> $('.ticker_carousel')).carouselTicker();
    }, 100);
  }

  initiateNewsTicker() {
    setTimeout(()=>{
      (<any> $('.news_ticker_carousel')).carouselTicker();
    },100);
  }

  getProfileLoss(stock, type = 'sign') {
    return this._market.getProfileLoss(stock, type);
  }

  getLoginStatus() {
    this._lo.loginSuccess.subscribe(
      data => {
        this.getStockList();
        if (data) {
        } else {
          let userData = this._utils.getFromStorage('hyb_demo_udata');
          if (userData) {
            this.getStockList();
          }
        }
      });
  }
  preferredTheme:boolean;
  getPreferredTheme() {
    let preferredTheme:any = this._ls.getLocalItems('preferredTheme');
    this._lo.preferredTheme.subscribe(
      data => {
        if(data != null) {
          this.preferredTheme = data;
        } else {
          this.preferredTheme = preferredTheme === 'true' ? true : false;
        }
      }
    );
  }
  navToMarketTrade(stock) {
    let latestView = this._ls.getLocalItems('latestView');
    let marketInfo = 'market-info-pro';
    // if (latestView) {
    //   marketInfo = this.isMobile ? 'market-info-mobile' : latestView;
    // } else {
    //   marketInfo = this.isMobile ? 'market-info-mobile' : 'market-info-pro';
    // }
    this._route.navigate([marketInfo, stock.idCompanyStock]);
  }

  ngOnInit() {
    this.getLoginStatus();
    this.getNewsList();
  }

  ngOnChanges() {

  }

}
