import { Component, OnInit } from '@angular/core';
import { PageContentService } from '../../../services/pageContent/page-content.service';
import { learningCenterCatId, learningCenterAlias } from '../../../shared/data/content-page-data';
import { DatumService } from '../../../services/datum/datum.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LoginService } from '../../../services/login/login.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'batp-learning-center',
  templateUrl: './learning-center.component.html',
  styleUrls: ['./learning-center.component.css']
})
export class LearningCenterComponent implements OnInit {

  constructor(private _ps: PageContentService,
              private _sanitize: DomSanitizer,
              private _ls: LoginService,
              private _datum: DatumService) { }
  learningCenter:any = [];
  isAuthenticated:boolean = false;
  getLcCont() {
    this._ps.getContentByCategory(learningCenterCatId, learningCenterAlias);
    this._ps.learningCenter
      .subscribe(
        data => {
          if(data) {
            this.learningCenter = data;
          }
        }
      );
  }
  // demoToken
  userData:any;
  getUserData() {
    this.userData = this._datum.getUserLoginData();
  }
  getDemoUrl( prod: boolean = true ) {
    let url = environment.demoUrl + this.userData.demoToken;
    //let url = 'https://demostage.business-software.in?demoToken=' + this.userData.demoToken;
    return url;
  }

  ngOnInit() {
    this.isAuthenticated = this._ls.getAuthenticated();
    this.getLcCont();
    this.getUserData();
  }

}
