import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { validNumber, validPhoneNumber, ValidAge } from '../../../../../shared/form-validators';
import { ApiUrlService } from 'src/app/services/api-url/api-url.service';
import { ApirequestService } from 'src/app/services/apirequest/apirequest.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'batp-silver-plus',
  templateUrl: './silver-plus.component.html',
  styleUrls: ['./silver-plus.component.css']
})
export class SilverPlusComponent implements OnInit {
  public employee = true;
  silverForm: FormGroup;
  @Input() checked: Boolean;
  public frondDoc;
  public backDoc;
  public residence;

  public tags = [{
    name: 'Employed',
    checked: true
  }, {
    name: 'Self Employed',
    checked: false
  }, {
    name: 'Retired',
    checked: false
  }, {
    name: 'Student',
    checked: false
  }, {
    name: 'Unemployed',
    checked: false
  }];
  public name = 'Employed';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<SilverPlusComponent>,
    private _url: ApiUrlService,
    private _req: ApirequestService, private _fb: FormBuilder) {

  }

  ngOnInit() {
    this.silverPlusFormInit();
    this.getDoc();
  }
  silverPlusFormInit() {
    this.silverForm = this._fb.group({
      idNowGrp: this._fb.group({
        title: ['', [Validators.required]],
        name: ['', [Validators.required]],
        gender: ['Male', [Validators.required]],
        nationality: ['', [Validators.required]],
        pob: ['', [Validators.required]],
        dob: ['', [Validators.required, ValidAge]],
        address1: ['', [Validators.required]],
        address2: [''],
        tin: ['', [Validators.required]],
        mobile: ['', [Validators.required, validPhoneNumber]],
        phone: ['', [Validators.required, validPhoneNumber]],
        other: [null],
        isEmployed: [null],
        isSelfEmployed: [null],
        isRetired: ['true'],
        isStudent: [null],
        isUnemployed: [null],
        businessName: [null],
        occupation: [null],
        position: [null],
        experience: [null],
        industrySector: [null],
        self_businessName: [null],
        self_occupation: [null],
        self_position: [null],
        self_experience: [null],
        self_industrySector: [null],
        annualIncome: ['', [Validators.required]],
        sourcefunds: ['', [Validators.required]],
        relevant1: [null],
        relevant2: [null],
        relevant3: [null],
        argreement1: [null],
        argreement2: [null],
        argreement3: [null]
      })
    });
  }


  changeEmployee(data: string, event: MouseEvent): void {
  }

  public changeCheckbox(i, tag) {
    this.name = tag.name;


    if (this.tags) {
      this.tags[i].checked = !this.tags[i].checked;
    }
  }

  silverFormSubmit(): void {
  }
  public getDoc(): void {
    const dat = { data: 'dsfdsf' };
    this._req.fetchApiData(this._url.getUserKycUrl, dat, false).subscribe(
      (data) => {
        if (data) {
        }
      });
  }
}
