import { Component } from '@angular/core';
import { UtilityService } from './services/utilities/utility.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { HeartBeatService } from './services/heartBeat/heart-beat.service';
import { hyb_udata } from './shared/data/constant-data';

@Component({
  selector: 'batp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor( private _utils:UtilityService,
               private router:Router,
               private _ar:ActivatedRoute,
               private _hb:HeartBeatService ) { }

  changeOfRoutes(event) {
    this._utils.scrollToY(0,400,'easeInOutSine');
  }
  logOutAllTab() {
    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        let token = localStorage.getItem(hyb_udata);
        if(token == undefined) {
            this.router.navigate(['/']);
            window.location.reload();
        }
      }
    }, false);
  }


  paramId:any = '';
  routeData:string = '';
  ngOnInit() {
    this._hb.heartBeat();
    this.logOutAllTab();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.routeData = this._ar.root.firstChild.snapshot.data['name'];
      let paramProps = (this.routeData == 'market-details') ? 'idStock' : 'id';
      this.paramId = this._ar.root.firstChild.snapshot.params[paramProps];
    });
  }
}
