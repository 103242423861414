import { Component, OnInit } from '@angular/core';
import {ApiUrlService} from '../../../../../services/api-url/api-url.service';
import {ApirequestService} from '../../../../../services/apirequest/apirequest.service';
import {SnackbarService} from '../../../../../services/snackbar/snackbar.service';
import {DatumService} from '../../../../../services/datum/datum.service';
import { HandleSessionID, CreateIFrame, RegisterListener } from '../../../../../services/arritech-cfm-controller/arritech.service';
import {Router} from '@angular/router';

@Component({
  selector: 'batp-gold',
  templateUrl: './gold.component.html',
  styleUrls: ['./gold.component.css']
})
export class GoldComponent implements OnInit {

    private userDetails;
    public qgenSession: string;
    public showLoader = true;

  constructor(
      private _url: ApiUrlService,
      private _req: ApirequestService,
      private _sb: SnackbarService,
      private _router: Router,
      private _datum: DatumService
  ) { }

  ngOnInit() {
      this.userDetails = this._datum.getUserDetails();
      this.processQgenSteps();
  }

    processQgenSteps() {
        const kycSessionData = {
            level: this.userDetails.userLevel
        };

        this._req.fetchApiData(this._url.checkKycSessionUrl, kycSessionData, false).subscribe((res2: any) => { console.log(res2);
            if (res2.data) {
                this.qgenSession = res2.data.sessionId;
                this.initQgenSession();
            } else {
                this._sb.openSnackBar(
                    'KYC Session Error! Failed to initiate KYC Session. Please contact support.',
                    3000,
                    'batp_error_snackbar',
                    'center',
                    'top'
                );
            }
        });
    }

    initQgenSession() {
        HandleSessionID(this.qgenSession).then((sessRes) => {
            this.showLoader = false;
            CreateIFrame('qgen-kyc', 'bex-kyc');

            RegisterListener((eventData) => {
                console.log("Process completed: ");
                console.log(eventData);

                if (eventData.type === 'steps_completed_event' || eventData.type === 'close_cfm_event') {
                    const levelData = {
                        level: this.userDetails.userLevel
                    };
                    this._req.fetchApiData(this._url.updateKycLevelURL, levelData, false).subscribe((res2: any) => {
                    });
                    this._router.navigate(['/']);
                }
            });
        });
        /*window.open('https://qgenonlinestaging.com/cfm/?session_id=' + sessId, '_blank');
        this._router.navigate(['profile/account']);*/
    }

    getUnixTimestamp() {
        return Math.floor(Date.now() / 1000);
    }

}
