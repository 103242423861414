import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiUrlService } from '../../../../services/api-url/api-url.service';
import { ApirequestService } from '../../../../services/apirequest/apirequest.service';

@Component({
  selector: 'batp-legal-detail-content',
  templateUrl: './legal-detail-content.component.html',
  styleUrls: ['./legal-detail-content.component.css']
})
export class LegalDetailContentComponent implements OnInit {

  constructor(private _route:ActivatedRoute,
              private _url:ApiUrlService,
              private _req:ApirequestService) { }

  legalDetails:any = [];
  showLoader:boolean = true;
  getLegalDetails(alias) {
    let data = {
      alias: alias
    };
    this._req.fetchApiData(this._url.getContentUrl,data).subscribe(
      (data:any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if( resSucc != '' ) {

          this.legalDetails = resSucc[0];
        }
        if( resErr != '' ) {

        }
      },
      error => {

      },
      () => {
        this.showLoader = false;
      }
    )
  }


  ngOnInit() {
    this._route.params.subscribe(
      params => {
        if( params.alias ) {
          this.getLegalDetails(params.alias);
        }
      }
  )
  }

}
