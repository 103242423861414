import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';

@Component({
  selector: 'batp-dim-currency',
  templateUrl: './dim-currency.component.html',
  styleUrls: ['./dim-currency.component.css']
})
export class DimCurrencyComponent implements OnInit {

  constructor(private _sb:SnackbarService) { }
  @Input() address:string = '';
  @Output() registerStatus = new EventEmitter();
  @Input() showBtn:boolean = true;

  completeRegister() {
    this.registerStatus.emit(true);
  }

  copyAddress(input) {
    input.select();
    document.execCommand("copy");
    this._sb.openSnackBar('Wallet Address Copied to Clipboard',2000,'hybse_success_snackbar','right','top');
  }
  ngOnInit() {
  }

}
