import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'batp-dim-logo',
  templateUrl: './dim-logo.component.html',
  styleUrls: ['./dim-logo.component.css']
})
export class DimLogoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
