import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ArritechService {
    constructor() { }
}

let qgenEndpoint = 'https://qgenonlinestaging.com';

interface ApplicantData {
    caseTypeID: string;
    email: string;
    gender: string;
    mobile: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    placeOfBirth: string;
    countryOfBirth: string;
    addressLine1: string;
    addressLine2: string;
    postCode: string;
    country: string;
    nationality: string;
    title: string;
}

/**
 * Initiates an application process by sending applicant data to a specified endpoint from the client side.
 * This function performs a POST request with the provided applicant data to the endpoint,
 * and handles the response by extracting a session ID, using a custom response handler provided as `handleResponse`.
 *
 * Upon successfully obtaining a session ID, it proceeds with the `HandleSessionID` function to manage the session.
 *
 * Note: The function requires a custom response handler (`handleResponse`) to extract the session ID,
 * accommodating different response structures.
 * In scenarios where the API's response structure does not directly provide a session ID in an expected format,
 * or for direct control over the request and session management process, you may bypass this function
 * and directly utilize `HandleSessionID` with the session ID obtained.
 *
 * @param {ApplicantData} data - The applicant data to be sent to the endpoint.
 * @param {string} endpoint - The URL of the endpoint where the application is initiated.
 * @param {Function} handleResponse - A function to handle the extraction of the session ID from the response.
 * @returns {Promise<Object>} An object indicating the success status and, on success, the session ID; on failure, error details.
 */
export const StartApplication = async (data: ApplicantData, endpoint: string, handleResponse: Function) => {
    try {
        // Post the applicant's data to the provided endpoint
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw response;
        }

        // Extract the session ID using the provided response handler
        const sessionID = handleResponse(response);

        // Validate the extracted session ID
        if (!sessionID) {
            throw new Error('Session ID is undefined or not valid.');
        }

        // Handle the session ID with the dedicated function
        await HandleSessionID(sessionID);

        // Return a success status and the session ID
        return { success: true, sessionID };
    } catch (error) {
        // Format the error for consistent error handling
        const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';

        // Return a structured error object
        return {
            success: false,
            errorMessage,
            errorDetail: error
        };
    }
};


/**
 * Handles the processing of a session ID to retrieve an access token and stores both in localStorage.
 * This function sends the session ID and the user's device information to our public endpoint designed to return an access token.
 * Upon successful retrieval, it stores the access token and session ID in the browser's localStorage for future use.
 *
 * Note: This function is designed to interact with a specific endpoint (`qgenEndpoint`), and the success of this operation
 * depends on the session ID's validity and the endpoint's ability to respond with the correct access token.
 *
 * @param {string} sessionID - The session ID obtained from the application initiation process.
 * @returns {Promise<Object>} An object indicating success status; on failure, includes error details.
 */
export const HandleSessionID = async (sessionID: string) => {
    try {
        // Post the session ID and device information to retrieve an access token
        const responseData = await fetch(`${qgenEndpoint}/cfm-api/v1/public/token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                sessionID,
                deviceInfo: window.navigator.userAgent,
            }),
        });

        if (!responseData.ok) {
            throw new Error(`HTTP error! status: ${responseData.status}`);
        }

        const response = await responseData.json();

        // Store the access token and session ID in localStorage
        localStorage.setItem('cfm_access_token', response.data.access_token);
        localStorage.setItem('cfm_session_id', sessionID);

        // Return a success status
        return { success: true };
    } catch (error) {
        // Format the error for consistent error handling
        const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';

        // Return a structured error object
        return {
            success: false,
            errorMessage,
            errorDetail: error
        };
    }
};


export const GetCfmEndpoint = (tokenData?: string, sessionData?: string) => {
    const token = tokenData || localStorage.getItem('cfm_access_token');
    const session = sessionData || localStorage.getItem('cfm_session_id');
    if ((token || session) === '') {
        return {
            err: 'You need to start the application first',
        };
    }
    return `${qgenEndpoint}/cfm/?session=${session}&id=${token}`;
};

/**
 * Creates and appends an iframe element to a specified container in the DOM.
 * This function dynamically generates an iframe with a source URL constructed from provided or stored token and session data.
 * The generated iframe is intended to embed a cfm interface into the web page.
 *
 * Note: The function requires that a valid token and session data are either passed as arguments or available in localStorage.
 * It assumes that the application has been started and these credentials have been stored beforehand.
 *
 * @param {string} divId - The ID of the div element where the iframe is to be appended.
 * @param {string} className - The class name to be assigned to the created iframe for styling purposes.
 * @param {string} [tokenData] - Optional. A token to be used in the iframe's source URL. If not provided, the function will attempt to retrieve it from localStorage.
 * @param {string} [sessionData] - Optional. A session identifier to be used in the iframe's source URL. If not provided, the function will attempt to retrieve it from localStorage.
 * @returns {Object|undefined} Returns an error object if the operation fails, otherwise does not return.
 */
export const CreateIFrame = (divId: string, className: string, tokenData?: string, sessionData?: string) => {
    // Attempt to use provided token and session data or fallback to localStorage values
    const token = tokenData || localStorage.getItem('cfm_access_token');
    const session = sessionData || localStorage.getItem('cfm_session_id');

    // Ensure both token and session data are available before proceeding
    if (!token || !session) {
        console.error('Token or session data is missing. Please start the application first.');
        return {
            success: false,
            err: 'Token or session data is missing. Please start the application first.',
        };
    }

    // Create a new iframe element and set its properties
    const iframe = document.createElement('iframe');
    iframe.src = `${qgenEndpoint}/cfm/?session=${session}&id=${token}`;
    iframe.className = className;
    // iframe.allow = "camera"; // Grant the iframe access to the camera
    iframe.setAttribute('allow', 'camera');

    // Find the target container and append the iframe
    const container = document.getElementById(divId);
    if (container) {
        container.appendChild(iframe);
        return { success: true };
    } else {
        return {
            success: false,
            err: `Container with ID '${divId}' not found.`,
        };
    }
};


/**
 * Registers a listener to handle events from the CFM application iframe.
 *
 * The function sets up an event listener to capture messages sent from the CFM iframe,
 * filtering for messages with a source identifier of 'cfm-event-listener'.
 * Upon receiving a 'steps_completed_event', it invokes the provided callback function with the event's payload,
 * which contains detailed information about the application completion, including acceptance info, case ID, and steps data.
 *
 * @param {Function} callback - The callback function to execute with the event's payload upon receiving a 'steps_completed_event'.
 *
 * Note: It's tailored to only respond to specific event types from the CFM application, ensuring precise and relevant event handling.
 */
export const RegisterListener = (callback: Function) => {
    // Add event listener based on browser compatibility
    const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
    const eventer = window[eventMethod];
    const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

    // Define a specialized listener for CFM events
    const cfmEventListener = (event: MessageEvent) => {
        // Check if the message is from the CFM iframe
        if (event.data.source === 'cfm-event-listener') {
            // The application steps are completed, handle the event
            callback(event.data);
        }
    };

    // Register the listener for CFM-specific message events
    eventer(messageEvent, cfmEventListener, false);
};

// For internal purposes in case we need to use staging enviroment
export const UseEndpoint = (
    endpoint: 'https://qgenonline.com'
) => {
    qgenEndpoint = endpoint;
};

