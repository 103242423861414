import { Component, OnInit, Input } from '@angular/core';
import {
    ChartingLibraryWidgetOptions,
    IChartingLibraryWidget,
    LanguageCode,
    widget
} from '../../../../assets/charting_library/charting_library.min';
import { _tvDatafeedUrl } from '../../../config/hybse.config';
import { AccountDetailsService } from '../../../services/accountDetails/account-details.service';
import { UtilityService } from 'src/app/services/utilities/utility.service';


@Component({
    selector: 'batp-trading-charts',
    templateUrl: './trading-charts.component.html',
    styleUrls: ['./trading-charts.component.css']
})
export class TradingChartsComponent implements OnInit {
    constructor(private _accDetail: AccountDetailsService,
        private _utils: UtilityService) { }

    private _symbol: ChartingLibraryWidgetOptions['symbol'] = 'AAPL';
    private _interval: ChartingLibraryWidgetOptions['interval'] = 'D';
    // private _datafeedUrl = 'https://demo_feed.tradingview.com';
    private _datafeedUrl: string = _tvDatafeedUrl;
    private _libraryPath: ChartingLibraryWidgetOptions['library_path'] = '/assets/charting_library/';
    private _chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'] = 'https://saveload.tradingview.com';
    private _chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'] = '1.1';
    private _clientId: ChartingLibraryWidgetOptions['client_id'] = 'tradingview.com';
    private _userId: ChartingLibraryWidgetOptions['user_id'] = 'public_user_id';
    private _fullscreen: ChartingLibraryWidgetOptions['fullscreen'] = false;
    private _autosize: ChartingLibraryWidgetOptions['autosize'] = true;
    private _containerId: ChartingLibraryWidgetOptions['container_id'] = 'tv_chart_container';
    private _tvWidget: IChartingLibraryWidget | null = null;


    @Input() idCompanyStock: any = '';
    @Input() stockName: any = '';
    @Input() viewMode: any = '';


    @Input()
    set symbol(symbol: ChartingLibraryWidgetOptions['symbol']) {
        this._symbol = symbol || this._symbol;
    }

    @Input()
    set interval(interval: ChartingLibraryWidgetOptions['interval']) {
        this._interval = interval || this._interval;
    }

    @Input()
    set datafeedUrl(datafeedUrl: string) {
        this._datafeedUrl = datafeedUrl || this._datafeedUrl;
    }

    @Input()
    set libraryPath(libraryPath: ChartingLibraryWidgetOptions['library_path']) {
        this._libraryPath = libraryPath || this._libraryPath;
    }

    @Input()
    set chartsStorageUrl(chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url']) {
        this._chartsStorageUrl = chartsStorageUrl || this._chartsStorageUrl;
    }

    @Input()
    set chartsStorageApiVersion(chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version']) {
        this._chartsStorageApiVersion = chartsStorageApiVersion || this._chartsStorageApiVersion;
    }

    @Input()
    set clientId(clientId: ChartingLibraryWidgetOptions['client_id']) {
        this._clientId = clientId || this._clientId;
    }

    @Input()
    set userId(userId: ChartingLibraryWidgetOptions['user_id']) {
        this._userId = userId || this._userId;
    }

    @Input()
    set fullscreen(fullscreen: ChartingLibraryWidgetOptions['fullscreen']) {
        this._fullscreen = fullscreen || this._fullscreen;
    }

    @Input()
    set autosize(autosize: ChartingLibraryWidgetOptions['autosize']) {
        this._autosize = autosize || this._autosize;
    }

    @Input()
    set containerId(containerId: ChartingLibraryWidgetOptions['container_id']) {
        this._containerId = containerId || this._containerId;
    }

    TVChartInit() {


        function getLanguageFromURL(): LanguageCode | null {
            const regex = new RegExp('[\\?&]lang=([^&#]*)');
            const results = regex.exec(location.search);
            return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode;
        }
        if (this.stockName !== '' && typeof this.stockName !== 'undefined') {
            const sym = this.stockName.split('-');
            const widgetOptions: ChartingLibraryWidgetOptions = {
                symbol: sym[0],
                // symbol: this._symbol,
                // symbol: 'DMC-EUR',
                datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(this._datafeedUrl),
                interval: '3',
                container_id: this._containerId,
                library_path: this._libraryPath,
                studies_overrides: {
                    'bollinger bands.median.color': '#252c40',
                },
                custom_css_url: '/assets/css/trading_view.css',
                locale: getLanguageFromURL() || 'en',
                disabled_features: ['use_localstorage_for_settings'],
                enabled_features: ['study_templates'],
                charts_storage_url: this._chartsStorageUrl,
                charts_storage_api_version: this._chartsStorageApiVersion,
                client_id: this._clientId,
                user_id: this._userId,
                fullscreen: this._fullscreen,
                autosize: this._autosize,
            };
            if (this.viewMode === 'market-info-pro' || this.viewMode === 'market-info') {
                widgetOptions.overrides = {
                    'symbolWatermarkProperties.color': '#252c40',
                    'paneProperties.background': '#0f131e',
                    'paneProperties.vertGridProperties.color': '#252c40',
                    'paneProperties.horzGridProperties.color': '#252c40',
                    'paneProperties.crossHairProperties.color': '#252c40',
                    'scalesProperties.textColor': '#eae2e2'
                };
                widgetOptions.toolbar_bg = '#252c40',
                    widgetOptions.loading_screen = {
                        backgroundColor: '#0f131e'
                    };
            }

            // if(this.viewMode == 'market-info') {
            //     widgetOptions.overrides = {
            //         "symbolWatermarkProperties.color" : "#2d2d2d",
            //         "paneProperties.background": "#0f131e",
            //         "paneProperties.vertGridProperties.color": "#2d2d2d",
            //         "paneProperties.horzGridProperties.color": "#2d2d2d",
            //         "paneProperties.crossHairProperties.color": "#2d2d2d",
            //         "scalesProperties.textColor" : "#eae2e2"
            //     };
            //     widgetOptions.toolbar_bg = '#2d2d2d',
            //     widgetOptions.loading_screen = {
            //         backgroundColor: "#0f131e"
            //     };
            // }
            const tvWidget = new widget(widgetOptions);
            this._tvWidget = tvWidget;
            tvWidget.onChartReady(() => {
                const button = tvWidget.createButton();
            });
        }
    }




    ngOnInit() {
        // var widget = new widget();
    }

    ngAfterViewInit() {
    }


    ngOnChanges() {
        setTimeout(() => {
            this.TVChartInit();
        }, 400);

    }

    ngOnDestroy() {
    }
}
