import {Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ApirequestService} from 'src/app/services/apirequest/apirequest.service';
import {ApiUrlService} from 'src/app/services/api-url/api-url.service';
import {DatumService} from 'src/app/services/datum/datum.service';
import {SnackbarService} from '../../../../../services/snackbar/snackbar.service';
import {Router} from '@angular/router';

@Component({
    selector: 'batp-bronze',
    templateUrl: './bronze.component.html',
    styleUrls: ['./bronze.component.css']
})
export class BronzeComponent implements OnInit {
    public QRCode: string;
    token;
    public type: string;
    public level: string;
    private userDetails;
    public qgenToken: string;
    public qgenSession: string;
    public kycRefId: string;

    constructor(
        private _url: ApiUrlService,
        private _req: ApirequestService,
        private _sb: SnackbarService,
        private _router: Router,
        private _datum: DatumService) {
    }

    ngOnInit() {
        this.userDetails = this._datum.getUserLoginData();
        // this.qrCode();
        this.processQgenSteps();
    }

    processQgenSteps() {
        const data = {
            clientID: '6766536335b48e1e90dea2ac',
            clientSecret: 'vTRU<Xp736~n8@95NrsMC%df-1[/c$0g'
        };
        /*this._req.fetchApiData(this._url.checkKycSessionUrl, data, false).subscribe((res2: any) => {
            console.log(this.userDetails);
        });*/
        this._req.fetchApiData(this._url.qgenLoginUrl, data, true).subscribe((response: any) => {
            console.log(response);
            if (response) {
                this.qgenToken = response.data.accessToken;

                this._req.fetchApiData(this._url.checkKycSessionUrl, data, false).subscribe((res2: any) => {
                    console.log(res2);
                    if (!res2.data) {
                        this.kycRefId = this.userDetails.username + '_' + this.getUnixTimestamp();
                        const initSessionData = {
                            // caseTypeId: '666bfca9a711ea181255fcc2',
                            caseTypeId: '666bfca9a711ea181255fccb',
                            referenceId: this.kycRefId,
                            email: this.userDetails.email
                        };
                        this._req.getQRCode(this._url.qgenInitCaseUrl, initSessionData, this.qgenToken).subscribe((caseRes: any) => {
                            console.log(caseRes);
                            this.qgenSession = caseRes.data.sessionId;

                            const kycSessionData = {
                                caseId: caseRes.data.caseId,
                                referenceId: this.kycRefId,
                                sessionId: caseRes.data.sessionId
                            };

                            this._req.fetchApiData(this._url.initKycSessionUrl, kycSessionData, false).subscribe((hbkycres) => {
                                console.log(hbkycres);
                                this.initQgenSession(this.qgenSession);
                            });
                        });
                    } else {
                        const contSessionPost = {
                            sessionId: res2.data,
                            withMessage: true
                        };
                        this.qgenSession = res2.data;
                        this._req.getQRCode(this._url.qgenContinueSessionUrl, contSessionPost, this.qgenToken).subscribe((sessRes: any) => {
                            console.log(sessRes);
                            this.qgenSession = sessRes.data.sessionId;
                            const updateData = {
                                sessionId: sessRes.data.sessionId
                            };

                            this._req.fetchApiData(this._url.updateKycSessionUrl, updateData, false).subscribe((sessUpdRes: any) => {
                                console.log(sessUpdRes);
                                this.initQgenSession(this.qgenSession);
                            });
                        }, (error) => {
                            this.initQgenSession(this.qgenSession);
                        });
                    }
                });
            } else {
                this._sb.openSnackBar(
                    'QGen Login failed!',
                    3000,
                    'batp_error_snackbar',
                    'center',
                    'top'
                );
            }
        });
    }

    initQgenSession(sessId: string) {
        /*HandleSessionID(this.qgenSession).then((sessRes) => {
            CreateIFrame('qgen-kyc', 'bex-kyc');

            RegisterListener((eventData) => {
                console.log('Process completed: ' + eventData);
            });
        });*/
        window.open('https://qgenonlinestaging.com/cfm/?session_id=' + sessId, '_blank');
        this._router.navigate(['profile/verification-level']);
    }

    getUnixTimestamp() {
        return Math.floor(Date.now() / 1000);
    }

    qrCode(): void {
        const data = {
            level: this.level,
            username: this.userDetails.username
        };
        this._req.fetchApiData(this._url.getQRcodeUrl, data, false).subscribe((response: any) => {
            if (response) {
                this.QRCode = 'data:image/png;base64,' + response.data;
                // this.getAddWebHooks();
            }
        });
    }

    getAddWebHooks() {
        const data = {
            url: 'https://apollo.business-software.in/api/updateKmn'
        };
        this._req.fetchPost(this._url.webHooksAddUrl, data, this.token).subscribe((res: any) => {
        });
    }
}
