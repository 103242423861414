import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiUrlService } from 'src/app/services/api-url/api-url.service';
import { ApirequestService } from 'src/app/services/apirequest/apirequest.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { MarketsService } from 'src/app/services/markets/markets.service';
import { validatePrice } from 'src/app/shared/form-validators';
import { AccountDetailsService } from 'src/app/services/accountDetails/account-details.service';
import { UtilityService } from 'src/app/services/utilities/utility.service';
import { MatTableDataSource, MatSort } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ErrorMessages } from 'src/app/shared/error-messages';
import { Router } from '@angular/router';
import {MatDialogRef} from '@angular/material';
@Component({
  selector: 'batp-buy-order',
  templateUrl: './buy-order.component.html',
  styleUrls: ['./buy-order.component.css']
})
export class BuyOrderComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private dialogRef:MatDialogRef<BuyOrderComponent>,
    private _urls: ApiUrlService,
    private _req: ApirequestService,
    private _sb: SnackbarService,
    private _market: MarketsService,
    private _fb: FormBuilder,
    private _accDetail: AccountDetailsService,
    private _utils: UtilityService,
    private _route:Router) { }
  buyOrderForm: FormGroup;
  buyOrderLoader = false;
  sellOrderLoader = false;
  buyOrderSubmitted = false;
  commissionFee = 0.15;
  stockInfo: any = [];
  actDetailSubs: any;
  stockListSubs: any;
  stockList: any = [];
  stockListClone: any = [];

  accountDetails: any = {
    primary: [],
    secondary: []
  };
  primaryData: any = [];
  secondaryData: any = [];


  wltBalUSD: any = 0;
  wltBalBTC: any = 0;
  @ViewChild('PrimarySort')
  PrimarySort: MatSort;
  @ViewChild('SecondarySort')
  SecondarySort: MatSort;
  errorMessages: any = new ErrorMessages();

  buyOrderSubmit() {
    this.buyOrderSubmitted = true;
    if (this.buyOrderForm.valid) {
      this.buyOrderLoader = true;
      const formVal = this.buyOrderForm.value;
      const data = {
        size: formVal.bidSize,
        idCompanyStock: this.data.stockInfo.idCompanyStock
      };
      this._req.fetchApiData(this._urls.iBOBuyorder,data,false).subscribe(
        (data:any) => {
            let resSucc = data.data;
            let resError = data.error;
            if(resSucc != "") {
              this._sb.openSnackBar(
                'Your Order Has Been Placed Successfully',
                3000,
                'hybse_success_snackbar',
                'center',
                'top'
              );
              setTimeout(()=>{
                this._route.navigate(['ibo']);
              },100);
              this.buyOrderLoader = false;
              this.buyOrderForm.controls['bidSize'].setValue('');
              this.buyOrderForm.controls['bidTotal'].setValue('');
              this.dialogRef.close();
            }
            if(resError!="") {
              this._sb.openSnackBar(
                resError['Error Description'],
                3000,
                'hybse_error_snackbar',
                'center',
                'top'
              );
              this.buyOrderLoader = false;
            }
        },
        error => {

        },
        () => {
        }
    );
    }
  }
  sellBuySubmitReq(url, data, form, type = 'buy') {
    this._req.fetchApiData(url, data, false).subscribe(
      (data: any) => {
        const resSucc = data.data;
        const resErr = data.error;

        if (resSucc !== '') {
          this.sellOrderLoader = false;
          this.buyOrderLoader = false;
          this._sb.openSnackBar(
            'Your Order Has Been Placed Successfully',
            3000,
            'hybse_success_snackbar',
            'center',
            'top'
          );

          // (type == "buy") ? this.resetformVal('buyOrderForm','buy') : this.resetformVal('sellOrderForm','sell');
          this[form].reset();
          this[form].controls['commissionFee'].setValue('0.0015');
          // this.getStockLists();
          // // this._market.getStockInfo(this.paramID);
          // this.getStockInfo();
          // this.sellBuySuccAction(form);
        }
        if (resErr !== '') {
          this._sb.openSnackBar(
            resErr['Error Description'],
            3000,
            'hybse_error_snackbar',
            'center',
            'top'
          );
        }
      },
      error => { },
      () => {
        this.sellOrderLoader = false;
        this.buyOrderLoader = false;
      }
    );
  }
  getStockLists() {
    this._market.getStockList();
    this.stockListSubs = this._market.stockList.subscribe((data: any) => {
      if (data != null) {
        if (data !== 'error') {
          this.stockList = []; this.stockListClone = [];
          this.stockList = data;
       //   this.addPropToStockList(data);
          // this.stockListClone = new MatTableDataSource(this.stockList);
          // if (this.routeData === 'market-info') { this.stockListClone.sort = this.sort; }
          // if (this.routeData === 'market-info-pro') { this.stockListClone.sort = this.stockListProSort; }
          // this.stockListClone.sort.active = 'volume';
         // this.getFavorites(data);
        }
      }
    });
  }

  getTotal(type, price, size) {
    let orderPrice, orderSize, bidTotal, totalCommission;
    let form = this.buyOrderForm;
    const total = type === 'buy' ? 'bidTotal' : 'askTotal';
    orderPrice = form.controls[price].value;
    orderSize = form.controls[size].value;
    bidTotal = orderPrice * orderSize;
    totalCommission = (this.commissionFee / 100) * bidTotal;
    if (!isNaN(orderSize) && orderSize !== '') {
      const valIncComm = totalCommission + bidTotal;

      form.controls[total].setValue((valIncComm).toFixed(6));
      const commisionVal: any = 0.0015 > (valIncComm - bidTotal) ? 0.0015 : (valIncComm - bidTotal);
      form.controls['commissionFee'].setValue((commisionVal).toFixed(6));
    } else {
      form.controls[total].setValue('');
      form.controls[total].setValue(0);
      form.controls['commissionFee'].setValue('0.0015');
    }
  }
  buyOrderFormInit() {
    this.buyOrderForm = this._fb.group({
      bidPrice: ['', [Validators.required, validatePrice]],
      bidSize: ['', [Validators.required, validatePrice]],
      bidTotal: ['', [Validators.required, validatePrice]],
      commissionFee: ['0.0015']
    });
  }


  ngOnInit() {
    this.buyOrderFormInit();
    this.buyOrderForm.controls['bidPrice'].setValue(this.data.stockInfo.askPrice - (this.data.stockInfo.askPrice*(this.data.stockInfo.cutRate/100)));
  }

}
