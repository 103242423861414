import { Countries } from 'src/app/shared/data/countries-bk';
import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as rootState from '../../../../app.reducer';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { validateEmail, validPhoneNumber, validNumber, ValidAge } from 'src/app/shared/form-validators';
import { ApirequestService } from 'src/app/services/apirequest/apirequest.service';
import { ApiUrlService } from 'src/app/services/api-url/api-url.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { SessionOutService } from 'src/app/services/sessionOut/session-out.service';
import { LoginService } from 'src/app/services/login/login.service';
import { FsUploadService } from 'src/app/services/fs-upload/fs-upload.service';
import { ConfirmBoxComponent } from 'src/app/components/widgets/confirm-box/confirm-box.component';
import { MatDialog, MatDatepickerInputEvent } from '@angular/material';
import { countries } from 'src/app/shared/data/countries';
import { wizardErrorMessage, wizardMessages } from 'src/app/shared/wizard-error-messages';


@Component({
  selector: 'batp-management-information',
  templateUrl: './management-information.component.html',
  styleUrls: ['./management-information.component.css']
})
export class ManagementInformationComponent implements OnInit {

  public toggle1 = false;
  public toggle2 = true;
  constructor(private store:Store<rootState.State>,
              private _req:ApirequestService,
              private _url:ApiUrlService,
              private _sb: SnackbarService,
              private _dialog:MatDialog,
              private _so:SessionOutService,
              private _ls:LoginService,
              private _fs:FsUploadService,
              private _fb:FormBuilder) { }

  companyDetails:any;
  managementInfo:any = [];
  ofcrphonee:any = [];
  errorMessage:wizardMessages = wizardErrorMessage;
  managementTitles:any = {
    btnText: 'Add Member',
    title: 'Add new Member of the Management Board'
  };
  getCompanyDetails() {
    this.store.pipe(select('authData')).subscribe(
      (data:any) => {
        this.companyDetails = data.companyDetail;
        this.managementInfo = data.companyDetail.companyManagement;
      }
    );
  }
  countriesList:Countries[] = countries;
  mgmtForm:FormGroup;
  mgmtFormInit() {
    this.mgmtForm = this._fb.group({
      ofcrfirstname: ['',[Validators.required]],
      ofcrlastname: ['',[Validators.required]],
      position: ['',[Validators.required]],
      dateOfBirth: ['',[Validators.required,ValidAge]],
      passportNumber: ['',[Validators.required]],
      telNumber: ['',[Validators.required]],
      areaCode:['',[Validators.required]],
      countryCode:['',[Validators.required]],
      ofcrmail: ['',[Validators.required,validateEmail]],
      memberImage: ['',[]],
      ofcrWebsite: ['',[]],
      ofcrLinkedin:['',[]],
      ofcrphone:['',[]],
    })
  }

  memberEditData:any = null;
  editMgmt(mgmt) {
    let split = mgmt.ofcrphone.split('-');
    let countryCode = split[0];
    let areaCode = split[1];
    let telPhone = split[2];
    this.mgmtForm.patchValue({
      ofcrmail: mgmt.ofcremail,
      ofcrfirstname: mgmt.ofcrfirstname,
      ofcrlastname: mgmt.ofcrlastname,
      countryCode: countryCode,
      areaCode: areaCode,
      telNumber: telPhone,
      ofcrshares: parseInt(mgmt.ofcrshares),
      position: mgmt.position,
      dateOfBirth: mgmt.dateOfBirth,
      passportNumber: mgmt.passportNumber,
      memberImage: mgmt.memberImage,
      ofcrWebsite: mgmt.ofcrWebsite,
      ofcrLinkedin: mgmt.ofcrLinkedin,
      ofcrphone: mgmt.ofcrphone
    });
    this.setEditActions(mgmt)
  }
  editMode:boolean = false;
  updateProfile() {
    this.editMode = true;
  }

  setEditActions(mgmt) {
    this.memberEditData = mgmt;
    this.managementTitles = {
      title: 'Save Member of the Management Board',
      btnText: 'Save Member'
    }
  }
  dateOfBirth:any;
  datePickerChanged(event: MatDatepickerInputEvent<Date>) {
    const d = new Date(event.value);
    this.dateOfBirth = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
    this.dateOfBirth = new Date(this.dateOfBirth);
  }

  confirmModalBox(mgmt) {
    let message = 'Are You Sure You Want To Delete this Member?';
    const dialogRef = this._dialog.open(ConfirmBoxComponent, {
      width: '450px',
      data: {
        message: message
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.deleteMgmt(mgmt)
      }
    });
  }
  deleteMgmt(mgmt) {
    let data = {
      idCompanyManagement : mgmt.idCompanyManagement,
      idCompany: this.companyDetails.company.idCompany
    };
    this._req.fetchApiData(this._url.deleteCompanyManagementUrl,data,false).subscribe(
      (data:any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if(resSucc != '') {
          this._sb.openSnackBar('Member Has Been Deleted Successfully',3000,'hybse_success_snackbar','right','top');
          this._ls.getCompanyDetails(this.companyDetails.company.idCompany);
        }
        if(resErr !='')
        {
          this._sb.openSnackBar( resErr['Error Description'],5000,'hybse_error_snackbar','center','top');
        }
      },
      error => {

      },
      () => {

      }
    )
  }

  /* Saving Management Information */
  memberEdit:boolean = false;
  showLoader:boolean = false;
  createUpdateMgmtMember(form) {
    if(this.mgmtForm.valid) {
      this.showLoader = true;
      let formVal = this.mgmtForm.value;
      let url = this._url.addCompanyManagementUrl;
      let data = {
        id: this.companyDetails.company.idCompany,
        managementInfo: [{...formVal}]
      }
      if(this.memberEditData) {
        data.managementInfo[0].idCompanyManagement = this.memberEditData.idCompanyManagement;
        url = this._url.companyUpdateUrl;
      }
      this._req.fetchApiData(url,data,false).subscribe(
        (data:any) => {
          let resSucc = data.data;
          let resErr = data.error;
          if(resSucc != '') {
            this.showLoader = false;
            let message = 'Member Has Been Created Successfully';
            if(this.memberEditData) message = 'Member Has Been Updated Successfully';
            this._sb.openSnackBar(message,3000,'hybse_success_snackbar','right','top');
            form.resetForm();
            this._ls.getCompanyDetails(this.companyDetails.company.idCompany);
            if(this.memberEditData) this.memberEditData = null;
          }
          if(resErr != '') {
            this._so.handleSessionOutErr(resErr);
          }

        },
        error => {

        },
        () => {

        }
      )
    }
  }
  getOfcPhone() {
    const countryCode = this.mgmtForm.get('countryCode').value
    const areaCode = this.mgmtForm.get('areaCode').value
    const telNumber = this.mgmtForm.get('telNumber').value
    this.mgmtForm.get('ofcrphone').setValue(`${countryCode}-${areaCode}-${telNumber}`)
  }


  uploadProfileImage() {
    let fileAccepted = ["image/*"];
    let maxSize = 2097152;
    this._fs.uploadFiles(fileAccepted,maxSize).then((res)=>{
      if(res.filesUploaded.length > 0) this.fileUploadHandler(res)
    });
  }

  fileUploadHandler(res) {
    let filesFailed = res.filesFailed;
    let fileUploaded = res.filesUploaded;
    if(fileUploaded.length > 0 ) {
      //fileUploaded[0].url
      this.mgmtForm.controls['memberImage'].setValue(fileUploaded[0].url);
    }
    if(filesFailed.length > 0 ) {

    }
  }

  ngOnInit() {
    this.getCompanyDetails();
    this.mgmtFormInit();
  }

}
