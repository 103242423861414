import { Injectable } from '@angular/core';
import { ApirequestService } from '../apirequest/apirequest.service';
import { ApiUrlService } from '../api-url/api-url.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CetTimeService {

  constructor(private _req:ApirequestService,
              private _url:ApiUrlService) { }


  cetTime:any;

  getCetTime() {
    this._req.fetchApiData(this._url.cetTimeUrl,{},true,'get').subscribe(
      (data:any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if(resSucc != '') {
          this.cetTime = data;
          this.showTimer();
        }
      }
    )
  }
  showTime:any = new Subject();
  showTimer() {
    var utcSeconds = this.cetTime;
    var d = new Date(0);
    var ms = d.setUTCSeconds(utcSeconds);
    var date = this.getCETorCESTDate(ms);
    var h:any = date.getHours();
    var m:any = date.getMinutes();
    var s:any = date.getSeconds();
    var session = "";
    h = (h < 10) ? "0" + h : h;
    m = (m < 10) ? "0" + m : m;
    s = (s < 10) ? "0" + s : s;
    this.showTime.next(h + ":" + m + ":" + s + " " + session);
    setTimeout(()=>{
      this.cetTime = this.cetTime + 1;
      this.showTimer();
    },1000);
  }
  getCETorCESTDate(ms){
    var localDate = new Date(ms);
    var utcOffset = localDate.getTimezoneOffset();
    var cetOffset = utcOffset + 60;
    var cestOffset = utcOffset + 120;
    var cetOffsetInMilliseconds = cetOffset * 60 * 1000;
    var cestOffsetInMilliseconds = cestOffset * 60 * 1000;

    var cestDateStart = new Date();
    var cestDateFinish = new Date();
    var localDateTime = localDate.getTime();
    var cestDateStartTime;
    var cestDateFinishTime;
    var result;

    cestDateStart.setTime(Date.parse('29 March ' + localDate.getFullYear() + ' 02:00:00 GMT+0100'));
    cestDateFinish.setTime(Date.parse('25 October ' + localDate.getFullYear() + ' 03:00:00 GMT+0200'));

    cestDateStartTime = cestDateStart.getTime();
    cestDateFinishTime = cestDateFinish.getTime();

    if(localDateTime >= cestDateStartTime && localDateTime <= cestDateFinishTime) {
        result = new Date(localDateTime + cestOffsetInMilliseconds);
    } else {
        result = new Date(localDateTime + cetOffsetInMilliseconds);
    }

    return result;
  }
}
