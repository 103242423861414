import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberDecimalFixed'
})
export class NumberDecimalFixedPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let fixedDecimal = (typeof args == 'undefined') ? 6 : args;
    let val = parseFloat(value);
    return val.toFixed(fixedDecimal);
  }

}
