import { Component, OnInit } from "@angular/core";
import { DatumService } from "../../../../services/datum/datum.service";
import { countries, Countries } from "../../../../shared/data/countries";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ErrorMessages } from "../../../../shared/error-messages";
import {
  validPhoneNumber,
  validNumber,
  ValidAge
} from "../../../../shared/form-validators";
import { FsUploadService } from "../../../../services/fs-upload/fs-upload.service";
import { ApirequestService } from "../../../../services/apirequest/apirequest.service";
import { ApiUrlService } from "../../../../services/api-url/api-url.service";
import { LoginService } from "../../../../services/login/login.service";
import { Store, select } from "@ngrx/store";
import * as rootState from "../../../../app.reducer";
import { SessionOutService } from "../../../../services/sessionOut/session-out.service";
import { SnackbarService } from "../../../../services/snackbar/snackbar.service";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { Router } from "@angular/router";
import { CryptoService } from "src/app/services/crypto/crypto.service";
import { InitialDepositModalComponent } from "src/app/components/widgets/initial-deposit-modal/initial-deposit-modal.component";
import { MatDialog } from "@angular/material";

@Component({
  selector: "batp-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.css"]
})
export class SettingsComponent implements OnInit {
  constructor(
    private _datum: DatumService,
    private _sb: SnackbarService,
    private _fs: FsUploadService,
    private _cs: CryptoService,
    private _req: ApirequestService,
    private _dialog: MatDialog,
    private _urls: ApiUrlService,
    private _route: Router,
    private store: Store<rootState.State>,
    private _ls: LoginService,
    private _so: SessionOutService,
    private _fb: FormBuilder
  ) {}
  userDetails: any = {};
  countryList: Countries[] = countries;
  updateProfileSec: boolean = false;
  updateDocSec: boolean = false;
  showLoaderProf: boolean = false;
  showLoaderWallet: boolean = false;
  toggle1: boolean = false;
  toggle2: boolean = false;
  toggle3 :boolean =false;
  updateProfForm: FormGroup;
  updateWalletForm: FormGroup;
  errorMessages: any = new ErrorMessages();
  profileImageUrl: any = "";
  updateProfStat: any = {
    message: "",
    icon: "",
    messageType: ""
  };
  updateWalletStat: any = {
    message: "",
    icon: "",
    messageType: ""
  };
  updateProfFormInit() {
    this.updateProfForm = this._fb.group({
      gender: ["Male", [Validators.required]],
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      dobDate: ["", [Validators.required, ValidAge]],
      country: ["", [Validators.required]],
      address1: ["", [Validators.required]],
      address2: [""],
      zipCode: ["", [Validators.required, validNumber]],
      city: ["", [Validators.required]],
      countryCode: ["", [Validators.required]],
      mobile: ["", [Validators.required, validPhoneNumber]]
    });
  }
  updateWalletFormInit() {
    this.updateWalletForm = this._fb.group({
      nemAddress1: ["", [Validators.required]],
      nemAddress2: [""],
      nemAddress3: [""]
    });
  }
  getKeysForm: FormGroup;
  getKeysFormInit() {
    this.getKeysForm = this._fb.group({
      password: ["", [Validators.required]]
    });
  }
  showLoaderUserKeys: boolean = false;
  getUserKeys() {
    if (this.getKeysForm.valid) {
      this.showLoaderUserKeys = true;
      let data = this.getKeysForm.value;
      this._req
        .fetchApiData(this._urls.getUserAPIKeysUrl, data, false)
        .subscribe((data: any) => {
          let resSucc = data.data;
          let resErr = data.error;
          this.showLoaderUserKeys = false;
          if (resSucc != "") {
            this.getEncKeys(data);
          }
          if (resErr != "") {
            this._sb.openSnackBar(
              resErr["errorDescription"],
              3000,
              "hybse_error_snackbar",
              "center",
              "top"
            );
            this._so.handleSessionOutErr(resErr);
          }
        });
    }
  }
  getEncKeys(data) {
    let username = this.userDetails.username;
    let ip_add = data["Client IP"];
    let passPhrase = `${username}.${ip_add}`;
    let encData = data.data;
    let decData = this._cs.deCypherData(encData, "string", passPhrase);
    if (decData !== "error") this.showApiKeys(decData);
  }
  apiKeys: any = null;
  showApiKeys(data) {
    this.apiKeys = JSON.parse(data);
  }

  updateWalletSubmit() {
    if (this.updateWalletForm.valid) {
      this.showLoaderWallet = true;
      let data = this.updateWalletForm.value;
      this._req
        .fetchApiData(this._urls.updateFinancialDetailsUrl, data, false)
        .subscribe(
          (data: any) => {
            let resSucc = data.data;
            let resErr = data.error;
            if (resSucc != "") {
              this._ls.getUserDetails(); // From Api
              this.getUserDetails(); // From Local Class
              this.setMessage(
                "Your Wallet Address has been Updated Successfully",
                "fal fa-check-circle",
                "success",
                "wallet"
              );
            }
            if (resErr != "") {
              this.setMessage(
                resErr["Error Description"],
                "fal fa-check-circle",
                "error",
                "wallet"
              );
              this._so.handleSessionOutErr(resErr);
            }
          },
          error => {},
          () => {
            this.showLoaderWallet = false;
          }
        );
    }
  }

  updateProfileImage() {
    let fileAccepted = ["image/*"];
    let maxSize = 2097152;
    this._fs.uploadFiles(fileAccepted, maxSize).then(res => {
      if (res.filesUploaded.length > 0) this.uploadToHyb(res.filesUploaded[0]);
    });
  }
  uploadToHyb(file) {
    let data = {
      image: file.url
    };
    this._req
      .fetchApiData(this._urls.updateProfileImageUrl, data, false)
      .subscribe((data: any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if (resSucc != "") {
          this.profileImageUrl = file.url;
          this._sb.openSnackBar(
            resSucc,
            3000,
            "hybse_success_snackbar",
            "center",
            "top"
          );
          this._ls.getUserDetails();
        }
        if (resErr != "") {
          this._sb.openSnackBar(
            resErr["Error Description"],
            3000,
            "hybse_error_snackbar",
            "center",
            "top"
          );
          this._so.handleSessionOutErr(resErr);
        }
      });
  }

  updateProfFormSubmit() {
    if (this.updateProfForm.valid) {
      this.showLoaderProf = true;
      let data = this.updateProfForm.value;
      data.dateofBirth = this.dobDate;
      this._req
        .fetchApiData(this._urls.updateUserDetailsUrl, data, false)
        .subscribe(
          (data: any) => {
            let resSucc = data.data;
            let resErr = data.error;
            if (resSucc != "") {
              this._ls.getUserDetails(); // From Api
              this.getUserDetails(); // From Local Class
              this.setMessage(
                "Your Profile has been Updated Successfully",
                "fal fa-check-circle",
                "success"
              );
            }
            if (resErr != "") {
              this.setMessage(
                resErr["Error Description"],
                "fal fa-check-circle",
                "error"
              );
              this._so.handleSessionOutErr(resErr);
            }
          },
          error => {},
          () => {
            this.showLoaderProf = false;
          }
        );
    }
  }

  setMessage(message, icon, messageType, type = "profile") {
    if (type == "profile") {
      this.updateProfStat.message = message;
      this.updateProfStat.icon = icon;
      this.updateProfStat.messageType = messageType;
    } else {
      this.updateWalletStat.message = message;
      this.updateWalletStat.icon = icon;
      this.updateWalletStat.messageType = messageType;
    }
    setTimeout(() => {
      this.updateProfStat.message = "";
      this.updateWalletStat.message = "";
    }, 5000);
  }

  getUserDetails() {
    this.store.pipe(select("authData")).subscribe((data: any) => {
      this.userDetails = data.userDetail;
      this.profileImageUrl = this.userDetails.avatar;
    });
  }
  setProfDetails() {
    this.updateProfForm.patchValue({
      gender: this.getValue(this.userDetails.gender, "Male"),
      firstName: this.getValue(this.userDetails.firstName, ""),
      lastName: this.getValue(this.userDetails.lastName, ""),
      dobDate: this.getValue(this.userDetails.dateofBirth, ""),
      country: this.getValue(this.userDetails.country, ""),
      address1: this.getValue(this.userDetails.address1, ""),
      address2: this.getValue(this.userDetails.address2, ""),
      zipCode: this.getValue(this.userDetails.zipCode, ""),
      city: this.getValue(this.userDetails.city, ""),
      countryCode: this.getValue(this.userDetails.telcode, ""),
      mobile: this.getValue(this.userDetails.mobile, "")
    });
  }
  setWalletDetails() {
    this.updateWalletForm.patchValue({
      nemAddress1: this.getValue(this.userDetails.nemAddress1, ""),
      nemAddress2: this.getValue(this.userDetails.nemAddress2, ""),
      nemAddress3: this.getValue(this.userDetails.nemAddress3, "")
    });
    //Ask Kar and change to nemAddress
  }

  getValue(val, retVal: string = "N/A") {
    return val == "" || val == "N/A" ? retVal : val;
  }

  getAddress() {
    if (this.userDetails.address1 != "N/A") {
      return (
        this.userDetails.address1 +
        " " +
        this.userDetails.address2 +
        ", " +
        this.userDetails.city +
        ", " +
        this.userDetails.zipCode
      );
    } else {
      return "N/A";
    }
  }

  dobDate: any;
  datePickerChanged(event: MatDatepickerInputEvent<Date>) {
    const d = new Date(event.value);
    this.dobDate = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
    this.dobDate = new Date(this.dobDate);
  }

  updateProfile(user = "investor") {
    if (user == "investor") {
      this.updateProfileSec = true;
      this.updateDocSec = false;
      this.setProfDetails();
      this.setWalletDetails();
    } else {
      this._route.navigate(["issuer-profile"]);
    }
  }
  updateDocument() {
    this.updateDocSec = true;
    this.updateProfileSec = false;
  }

  openDepositModal() {
    const dialogRef = this._dialog.open(InitialDepositModalComponent, {
      width: "700px",
      data: {
        showPayment: true,
        userDetail: this.userDetails
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  ngOnInit() {
    this.getUserDetails();
    this.updateProfFormInit();
    this.getKeysFormInit();
    this.updateWalletFormInit();
  }
}
