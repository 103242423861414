import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'batp-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
