import { Component, OnInit } from '@angular/core';
import { DatumService } from '../../../services/datum/datum.service';
import { HeartBeatService } from '../../../services/heartBeat/heart-beat.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { UtilityService } from 'src/app/services/utilities/utility.service';

@Component({
  selector: 'batp-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('300ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})
export class ProfileComponent implements OnInit {

  constructor(private _datum:DatumService,
              private _hb:HeartBeatService,
              private _utils:UtilityService) { }

  changeOfRoute($e) {
    this._hb.heartBeat();
  }

  userDetails:any = '';
  getUserDetails() {
    this.userDetails = this._datum.getUserDetails();
  }
  menuStatus:boolean = false;
  menuToggle() {
    this.menuStatus = !this.menuStatus;
  }

  isMobile:boolean = false;

  ngOnInit() {
    this.isMobile = this._utils.checkMobileDevice();
    this.getUserDetails();
  }

}
