import { businessActivities } from "./data/business-activities";

export interface wizardMessages {
  companyNameRequired:string,
  companyWebRequired:string,
  companyEmailRequired:string,
  companyEmailValid:string,
  companyPhCountryRequired:string,
  companyPhoneRequired:string,
  companyAddressRequired:string,
  companyPostalRequired:string,
  companyLegalRequired:string,
  companyPhoneValid:string,
  websiteUrlValid:string,
  employeeRequired:string,
  numberValid:string,
  desiredListingDate:string,
  desiredLDateValid:string,
  incorpCountryReq:string,
  incorpCityReq:string,
  incorpYearReq:string,
  incorpNumberReq:string,
  incorpLegalform:string,
  valueReq:string,
  shareValValidity:string,
  compActivities:string,
  dunsRequired:string,
  desiredList:string,
  isinReq:string,
  ibinReq:string,
  shareCapitalReq:string,
  paidInReq:string,
  totalSharesReq:string,
  nominalValueReq:string,
  blockShareReq:string,
  typeReq:string,
  symbolReq:string,
  sectorReq:string,
  marketReq:string,
  auditorReq:string,
  currencyReq:string,
  businessActivities:string,
  mgmtFirstNameReq:string,
  mgmtLastNameReq:string,
  mgmtTelephoneReq:string,
  mgmtEmailReq:string,
  mgmtSharesReq:string,
  mgmtpassportReq:string,
  mgmtpassportimgReq:string,
  mgmtdobReq:string,
  mgmtPositionReq:string,
  maxHundered:String,
  dateFromReq:string,
  dateToReq:string,
  discountReq:string,
  volumeReq:string
}
export const wizardErrorMessage:wizardMessages = {
  companyNameRequired: "Please Enter Your Company Name",
  companyWebRequired: "Please Enter Your Company Website",
  companyEmailRequired: "Please Enter Your Company Email",
  companyEmailValid: "Please Enter Valid Email",
  companyPhCountryRequired: "Please Select Country",
  companyPhoneRequired: "Please Enter Your Company Phone",
  companyPhoneValid: "Please Enter Valid Phone Number",
  companyAddressRequired:"Please enter Street1",
  companyPostalRequired:"Please enter postal code",
  companyLegalRequired:"Please enter legal seat",
  websiteUrlValid: "Please Enter Valid Url",
  employeeRequired: "Please Enter No Of Employees",
  numberValid: "Please Enter Valid Number",
  desiredListingDate: "Please Enter Desired Listing Date",
  desiredLDateValid: "Please Enter Date greater than 30 days",
  incorpCountryReq:"Please Select Incorporated Country",
  incorpCityReq:"Please Enter Incorporated City",
  incorpYearReq:"Please Enter Incorporated Year",
  incorpNumberReq:"Please Enter Incorporated Number",
  incorpLegalform:"Please Enter Incorporated Legal Form",
  valueReq:"Please Enter a Value",
  shareCapitalReq:"Please Enter Share Capital",
  paidInReq:"Please Enter Paid In",
  totalSharesReq:"Please Enter Total Shares",
  nominalValueReq:"Please Enter Nominal Value",
  blockShareReq:"Please Enter Block Share",
  typeReq:"Please Enter Type",
  symbolReq:"Please Enter Symbol",
  sectorReq:"Please Select Sector",
  marketReq:"Please Enter Marketplace",
  shareValValidity: "Please Enter Value not more than 20",
  compActivities:"Please Enter Company Description",
  auditorReq:"Please enter auditor",
  currencyReq:"Please enter currency",
  dunsRequired: "Please Enter Duns Value",
  desiredList:"Please Select a Date",
  isinReq:"Please Enter Your isin Number",
  ibinReq:"Please Enter Your ibin Number",
  businessActivities:"Please Select Your Market",
  mgmtFirstNameReq:"Please Enter First Name",
  mgmtLastNameReq:"Please Enter Last Name",
  mgmtTelephoneReq:"Please Enter Telephone Number",
  mgmtEmailReq:"Please Enter Your Email Address",
  mgmtSharesReq:"Please Enter Share Value",
  mgmtpassportReq:"Please Enter Passport Number",
  mgmtpassportimgReq:"Please Upload passport Image",
  mgmtdobReq:"Please Enter Date of Birth",
  mgmtPositionReq:"Please Enter Position",
  maxHundered:"Please Enter Number Less than 100",
  discountReq:"Please Enter Cut Rate",
  volumeReq:"Please Enter limit shares",
  dateFromReq:"Please enter from date",
  dateToReq:"Please enter to date"
}
