import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CryptoService } from '../crypto/crypto.service';
import { StorageService } from '../localstorage/storage.service';
import { hyb_udata } from '../../shared/data/constant-data';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class UtilityService {

  constructor( private _ar: ActivatedRoute,
               private _cry: CryptoService,
               private _lstore: StorageService ) { }
  /* Scroll to Section */
  scrollToY(scrollTargetY, speed, easing) {
    (<any>window).requestAnimFrame = (function(){
      return  window.requestAnimationFrame       ||
              window.webkitRequestAnimationFrame ||
              (<any>window).mozRequestAnimationFrame    ||
              function( callback ){
                window.setTimeout(callback, 1000 / 60);
              };
    })();


    var scrollY = window.scrollY,
        scrollTargetY = scrollTargetY || 0,
        speed = speed || 2000,
        easing = easing || 'easeOutSine',
        currentTime = 0;

    var time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8));

    var PI_D2 = Math.PI / 2,
        easingEquations = {
            easeOutSine: function (pos) {
                return Math.sin(pos * (Math.PI / 2));
            },
            easeInOutSine: function (pos) {
                return (-0.5 * (Math.cos(Math.PI * pos) - 1));
            },
            easeInOutQuint: function (pos) {
                if ((pos /= 0.5) < 1) {
                    return 0.5 * Math.pow(pos, 5);
                }
                return 0.5 * (Math.pow((pos - 2), 5) + 2);
            }
        };

    function tick() {
        currentTime += 1 / 60;
        var p = currentTime / time;
        var t = easingEquations[easing](p);

        if (p < 1) {
          (<any>window).requestAnimFrame(tick);

          if (scrollTargetY == 0) {
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
          } else {
              setTimeout(() => window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t)));
          }
        } else {
            if (scrollTargetY == 0) {
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            } else {
                setTimeout(() => window.scrollTo(0, scrollTargetY));
            }
        }
    }
    tick();
  }

  doubleWalletToggle:any = new BehaviorSubject(false);

  checkMobileDevice() {
    return ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) );
  }


  filterArrayObj(arr,prop,val) {
    let filteredVal = arr.filter( item => {
      return item[prop] != val;
    });
    return filteredVal;
  }
  filteredArray(arr,prop,val) {
    let filteredVal = arr.filter( item => {
      return item[prop] == val;
    });
    return filteredVal;
  }

  changeArrProp(arr,prop,val) {
    arr.forEach( item => {
      item[prop] = val;
    });
    return arr;
  }

  filteredObj(arr,prop,val) {
    let filteredVal = arr.filter( item => {
      return item[prop] == val;
    });
    return filteredVal;
  }

  filteredObjOnArray(subject, fltr, prop, include) {
    let filteredVal = subject.filter( item => {
        if (include) {
            return fltr.includes(item[prop]);
        } else {
            return !fltr.includes(item[prop]);
        }
    });
    return filteredVal;
}

  changeArrayObjProp(arr,match,value,prop,val) {
    arr.forEach(function(item, index, array) {
      if( item[match] == value ) item[prop] = val;
    });
    return arr;
  }

  addPropInObj(obj,prop,val) {
    if(obj.length > 0 ) {
      obj.forEach(ele => {
        prop.forEach((item,index) => {
          ele[item] = val[index];
        });
      });
    }
    return obj;
  }

  getPropValFromObj(obj,check_prop,prop,val) {
    let propVal = '';
    if(obj.length > 0 ) {
      obj.forEach(ele => {
        if( ele[check_prop] == val ) propVal = ele[prop];
      });
    }
    return propVal;
  }


  dynamicSort(property) {
    var sortOrder = 1;

    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a,b) {
        if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
        }else{
            return a[property].localeCompare(b[property]);
        }
    }
  }


  paginatedItems(arr,start,end) {
    return arr.slice(start,end);
  }


  compareSort(a: any, b: any, isAsc: boolean) {
    // return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    let item1 = a; let item2 = b;
    if( !isNaN(a) ) item1 = parseFloat(a);
    if( !isNaN(b) ) item2 = parseFloat(b);
    return (item1 < item2 ? -1 : 1) * (isAsc ? 1 : -1);
  }


  getFromStorage(getItem) {
    let storageData = this._lstore.getLocalItems(getItem);
    if(storageData != null) {
      let deCypherData = this._cry.deCypherData(storageData,'obj');
      return deCypherData;
    }
    return null;
  }

}
