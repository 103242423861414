import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import * as rootState from '../../../../app.reducer';
import { MatDatepickerInputEvent, MatDialog } from '@angular/material';
import { wizardErrorMessage, wizardMessages } from 'src/app/shared/wizard-error-messages';
import { ApiUrlService } from 'src/app/services/api-url/api-url.service';
import { ApirequestService } from 'src/app/services/apirequest/apirequest.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { SessionOutService } from 'src/app/services/sessionOut/session-out.service';
import { LoginService } from 'src/app/services/login/login.service';
import { validNumberWithDecimal, validNumber } from 'src/app/shared/form-validators';

@Component({
  selector: 'batp-ibo-company-information',
  templateUrl: './ibo-company-information.component.html',
  styleUrls: ['./ibo-company-information.component.css']
})
export class IboCompanyInformationComponent implements OnInit {

  constructor(private _fb:FormBuilder,
    private store:Store<rootState.State> ,
    private _req:ApirequestService,
    private _url:ApiUrlService,
    private _sb: SnackbarService,
    private _dialog:MatDialog,
    private _so:SessionOutService,
    private _ls:LoginService) { }
  editMode:boolean = false;
  showLoader:boolean = false;

  updateProfile() {
    this.editMode = true;
  }
  companyIBOForm:FormGroup;
  companyDetails:any;
  IboInfo:any;
  errorMessage:wizardMessages = wizardErrorMessage;
  companyIBOFormInit() {
    this.companyIBOForm = this._fb.group({
      isibo:['',[Validators.required]],
      fromDate: ['',[]],
      toDate: ['',[]],
      discount: ['',[]],
      volume: ['',[]]
    })
    const fromDate = this.companyIBOForm.get('fromDate');
    const toDate = this.companyIBOForm.get('toDate');
    const discount = this.companyIBOForm.get('discount');
    const volume = this.companyIBOForm.get('volume');
    this.companyIBOForm.get('isibo').valueChanges.subscribe(
      (mode: string) => {
          if (mode === '1') {
              fromDate.setValidators([Validators.required]);
              toDate.setValidators([Validators.required]);
              volume.setValidators([Validators.required,validNumber]);
              discount.setValidators([Validators.required,validNumberWithDecimal]);
          }
          else {
            fromDate.clearValidators();
            toDate.clearValidators();
            volume.clearValidators();
            discount.clearValidators();
          }
          fromDate.updateValueAndValidity();
          toDate.updateValueAndValidity();
          discount.updateValueAndValidity();
          volume.updateValueAndValidity();
      });
      this.companyIBOForm.patchValue({
        isibo:this.IboInfo.isibo,
        fromDate: this.IboInfo.fromDate,
        toDate: this.IboInfo.toDate,
        volume: this.IboInfo.volume,
        discount: this.IboInfo.discount
      })
  }
  saveChanges()
  {
    if(this.companyIBOForm.valid) {
      this.showLoader = true;
      let formVal = this.companyIBOForm.value;
      let url = this._url.companyUpdateUrl;
      let data = {
        id: this.companyDetails.company.idCompany,
        idIboPref: this.companyDetails.iboPref.idIboPref,
        fromDate: formVal.fromDate,
        toDate: formVal.toDate,
        volume: formVal.volume,
        discount: formVal.discount
      }
      this._req.fetchApiData(url,data,false).subscribe(
        (data:any) => {
          let resSucc = data.data;
          let resErr = data.error;
          if(resSucc != '') {
            this.showLoader = false;
            let message = 'Ibo Details Has Been Updated Successfully';
            this._sb.openSnackBar(message,3000,'hybse_success_snackbar','right','top');
            this._ls.getCompanyDetails(this.companyDetails.company.idCompany);
          }
          if(resErr != '') {
            this._so.handleSessionOutErr(resErr);
          }

        },
        error => {

        },
        () => {

        }
      )
    }
  }
  fromDate:any;
  toDate:any;
  datePickerChanged(event: MatDatepickerInputEvent<Date>) {
    const d = new Date(event.value);
    this.fromDate = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
    this.fromDate = new Date(this.fromDate);
  }
  datePicker2Changed(event: MatDatepickerInputEvent<Date>) {
    const d = new Date(event.value);
    this.toDate = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
    this.toDate = new Date(this.toDate);
  }
  getCompanyDetails() {
    this.store.pipe(select('authData')).subscribe(
      (data:any) => {
        this.companyDetails = data.companyDetail;
        this.IboInfo = data.companyDetail.iboPref;
      }
    );
  }
  ngOnInit() {
    this.getCompanyDetails();
    this.companyIBOFormInit();
  }

}
