import { Injectable } from '@angular/core';
import { ApiUrlService } from '../api-url/api-url.service';
import { ApirequestService } from '../apirequest/apirequest.service';
import { BehaviorSubject } from 'rxjs';
import { learningCenterAlias, legalAlias, faqAlias,pressCenterAlias } from '../../shared/data/content-page-data';

@Injectable({
  providedIn: 'root'
})
export class PageContentService {

  constructor(private _req:ApirequestService,
              private _url:ApiUrlService) { }


  learningCenter:any = new BehaviorSubject<any>(null);
  legalContent:any = new BehaviorSubject<any>(null);
  faqContent:any = new BehaviorSubject<any>(null);
  pressContent:any = new BehaviorSubject<any>(null);
  getContentByCategory(id,cat) {
    let data = {
      idContentCategory: id
    };
    this._req.fetchApiData(this._url.getContentByCategoryUrl,data).subscribe(
      (data:any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if( resSucc != '' ) {
          this.sendContent(resSucc,cat);
        }
        if( resErr != '' ) {

        }
      },
      error => {

      },
      () => {

      }
    )
  }

  sendContent(data,cat) {
    switch (cat) {
      case learningCenterAlias:
        this.learningCenter.next(data);
        break;
      case legalAlias:
        this.legalContent.next(data);
        break;
      case faqAlias:
        this.faqContent.next(data);
        break;
      case pressCenterAlias:
        this.pressContent.next(data);
        break;
      default:
        break;
    }
  }



}
