import { Component, OnInit } from '@angular/core';
import { animate, group, style, transition, state, trigger } from '@angular/animations';
import { UtilityService } from '../../../services/utilities/utility.service';

@Component({
  selector: 'batp-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css'],
  animations: [
    trigger('FadeAnimation', [
      state('in', style({
        opacity: 1
      })),
      transition(':enter', [
        style({opacity: 0}),
        animate(600 )
      ]),
      transition(':leave',
        animate(300, style({opacity: 0})))
    ])
  ]
})
export class AboutUsComponent implements OnInit {

  constructor( private _utils:UtilityService ) { }
  showContent:boolean = false;
  isMobile:boolean = false;
  showContentEvt() {
    this.showContent = !this.showContent;
  }
  ngOnInit() {
    this.isMobile = this._utils.checkMobileDevice();
    if(this.isMobile) this.showContent = true;
  }

}
