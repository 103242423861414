import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Router,ActivatedRouteSnapshot,RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import * as rootReducer from '../../app.reducer';


@Injectable()
export class IssuerRegisterGuard implements CanActivate, CanActivateChild {


    constructor( public route:Router,
                 public store: Store<rootReducer.State> ) { }

    canActivate() {
      let newReg;
      this.store.pipe(select('authData')).subscribe(
        (data:any) => {
          newReg = data.userData.newReg;
        }
      );
      if(newReg == '1') {
        return true;
      } else {
        this.route.navigate(['/']);
        return false;
      }
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return this.canActivate();
    }


}
