import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { validatePrice } from 'src/app/shared/form-validators';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ApiUrlService } from 'src/app/services/api-url/api-url.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { ApirequestService } from 'src/app/services/apirequest/apirequest.service';
import { MarketsService } from 'src/app/services/markets/markets.service';

@Component({
  selector: 'batp-limit-buy',
  templateUrl: './limit-buy.component.html',
  styleUrls: ['./limit-buy.component.css']
})
export class LimitBuyComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data:any,
  private _fb: FormBuilder,
  private _urls: ApiUrlService,
  private _req: ApirequestService,
  private _sb: SnackbarService,
  private _market: MarketsService,
  private dialogRef:MatDialogRef<LimitBuyComponent>) { }
  buyOrderLimitForm: FormGroup;
  sellOrderLimitForm: FormGroup;
  commissionFee = 0.15;
  buyOrderLimitSubmitted:boolean = false;
  buyOrderLoader:boolean = false;
  paramID: any;
  buyOrderLimitFormInit() {
    this.buyOrderLimitForm = this._fb.group({
      bidPrice: ['', [Validators.required, validatePrice]],
      bidSize: ['', [Validators.required, validatePrice]],
      bidTotal: ['', [Validators.required, validatePrice]],
      commissionFee: ['0.000001']
    });
  }
  getTotal(type, price, size, formName: string = 'market') {
    let orderPrice, orderSize, bidTotal, totalCommission;
    let form;
    // if (stopLimit) {
    //   form = type == "buy" ? this.buyOrderStopLimitForm : this.sellOrderStopLimitForm;
    // }
    switch (formName) {
      case 'limit':
        form = type === 'buy' ? this.buyOrderLimitForm : this.sellOrderLimitForm;
        break;
      default:
        break;
    }
    const total = type === 'buy' ? 'bidTotal' : 'askTotal';
    orderPrice = form.controls[price].value;
    if (formName === 'market') { orderPrice = this.data.stockInfo.priceCurrent; }
    orderSize = form.controls[size].value;
    bidTotal = orderPrice * orderSize;
    totalCommission = (this.commissionFee / 100) * bidTotal;
    if (!isNaN(orderSize) && orderSize !== '') {
      const valIncComm = totalCommission + bidTotal;
      form.controls[total].setValue((valIncComm).toFixed(6));
      const commisionVal: any = 0.000001 > (valIncComm - bidTotal) ? 0.000001 : (valIncComm - bidTotal);
      form.controls['commissionFee'].setValue((commisionVal).toFixed(6));
      // form.controls['totalWithComission'].setValue(totalCommission + bidTotal);
    } else {
      form.controls[total].setValue('');
      form.controls[total].setValue(0);
      form.controls['commissionFee'].setValue('0.000001');
      // form.controls['totalWithComission'].setValue('');
    }
  }
  buyOrderLimitSubmit() {
    this.buyOrderLimitSubmitted = true;
    if (this.buyOrderLimitForm.valid) {
      this.buyOrderLoader = true;
      const formVal = this.buyOrderLimitForm.value;
      const data = {
        type: 'limit',
        stop: 0,
        bidPrice: formVal.bidPrice,
        bidSize: formVal.bidSize,
        idCompanyStock: this.data.stockInfo.idCompanyStock,
        idCompany: this.data.stockInfo.idCompany
      };
      this.sellBuySubmitReq(
        this._urls.buyOrderUrl,
        data,
        'buyOrderLimitForm'
      );
    }
  }
  sellBuySubmitReq(url, data, form, type = 'buy') {
    this._req.fetchApiData(url, data, false).subscribe(
      (data: any) => {
        const resSucc = data.data;
        const resErr = data.error;

        if (resSucc !== '') {
         // this.sellOrderLoader = false;
          this.buyOrderLoader = false;
          this._sb.openSnackBar(
            'Your Order Has Been Placed Successfully',
            3000,
            'hybse_success_snackbar',
            'center',
            'top'
          );
          this.buyOrderLimitForm.controls['bidSize'].setValue('');
          this.buyOrderLimitForm.controls['bidTotal'].setValue('');
          this.buyOrderLimitForm.controls['commissionFee'].setValue('0.000001');
          this.dialogRef.close();
          // this.getStockLists();
          // this._market.getStockInfo(this.paramID);
          // this.getStockInfo();
          this.buyOrderLimitSubmitted = false;
        }
        if (resErr !== '') {
          this._sb.openSnackBar(
            resErr['Error Description'],
            3000,
            'hybse_error_snackbar',
            'center',
            'top'
          );
        }
      },
      error => { },
      () => {
       // this.sellOrderLoader = false;
        this.buyOrderLoader = false;
      }
    );
  }

  ngOnInit() {
   this.buyOrderLimitFormInit();
   this.buyOrderLimitForm.controls['bidPrice'].setValue(this.data.bidPrice);
  }

}
