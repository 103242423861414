import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'batp-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.css']
})
export class VideoDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data:any,
              private _router:Router,
              public dialogRef: MatDialogRef<VideoDialogComponent>) { }



  navToLearningCenter() {
    this._router.navigate(['/learning-center']);
    this.dialogRef.close();
    return false;
  }


  ngOnInit() {

  }

}
