import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {ModalBoxComponent} from '../../../widgets/modal-box/modal-box.component';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {BronzeComponent} from './bronze/bronze.component';
import {Title} from '@angular/platform-browser';
import {SilverPlusComponent} from './silver-plus/silver-plus.component';
import {UpdateProfileModalComponent} from 'src/app/components/widgets/update-profile-modal/update-profile-modal.component';
import {Store, select} from '@ngrx/store';
import * as rootState from '../../../../app.reducer';
import {InitialDepositModalComponent} from 'src/app/components/widgets/initial-deposit-modal/initial-deposit-modal.component';
import {GoldComponent} from './gold/gold.component';
import {SnackbarService} from 'src/app/services/snackbar/snackbar.service';
import { DatumService } from 'src/app/services/datum/datum.service';
import {LoginService} from 'src/app/services/login/login.service';
import {SilverComponent} from './silver/silver.component';
import * as AuthData from '../../../../shared/states/auth-data/auth-data.action';
import {hyb_udetail} from '../../../../shared/data/constant-data';

@Component({
    selector: 'batp-verification',
    templateUrl: './verification.component.html',
    styleUrls: ['./verification.component.css']
})
export class VerificationComponent implements OnInit {
    bronze: number;
    silver: number;
    gold: number;
    userDetails: any;
    sub: any;

    constructor(
        private store: Store<rootState.State>,
        private _dialog: MatDialog,
        private _route: Router,
        private _sb: SnackbarService,
        private _ulog: LoginService,
        private _datum: DatumService,
    ) {
        this.bronze = environment.bronceLevel;
        this.silver = environment.silverLevel;
        this.gold = environment.goldLevel;
    }

    // public checkIsBronze(type, level): void {

    //   const message = (type === 'bronze') ? `Scan QR Code For ${type} !` : `Scan QR Code For ${type}`;
    //   const dialogRef = this._dialog.open(BronzeComponent, {
    //     width: '335px',
    //     data: {
    //       message: message,
    //       buttonText: 'OK',
    //       level: level,
    //       type: type,
    //     }
    //   });
    //   dialogRef.afterClosed().subscribe(result => {
    //     if (result) {
    //     }
    //   });


    //   // this._route.navigate(['/profile/bronze']);
    // }

    checkIsSilver(type, level) {
        if (this.userDetails.userLevel === 'Bronze') {
            const message = `Scan QR Code For ${type}`;
            const dialogRef = this._dialog.open(SilverComponent, {
                width: '335px',
                data: {
                    message: message,
                    buttonText: 'OK',
                    level: level,
                    type: type,
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    console.log(result);

                }
            });
        } else {
            // this._route.navigate(['/profile/gold']);
            this._sb.openSnackBar('Kindly Update Your Bronze Level. ', 5000, 'hybse_success_snackbar', 'center', 'top');
        }
    }

    // public checkSilver(): void {
    //   const dialogRef = this._dialog.open(SilverPlusComponent, {
    //     width: '850px',
    //     data: {
    //       message: 'message',
    //       buttonText: 'OK'
    //     }
    //   });
    // }

    public checkIsGold(type, level): void {
        if (this.userDetails.userLevel === 'Silver') {
            const message = `Scan QR Code For ${type}`;
            const dialogRef = this._dialog.open(GoldComponent, {
                width: '335px',
                data: {
                    message: message,
                    buttonText: 'OK',
                    level: level,
                    type: type,
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                }
            });
        } else {
            this._sb.openSnackBar('Kindly Update Your Silver Level. ', 5000, 'hybse_success_snackbar', 'center', 'top');
        }
    }

    getUserDetails() {
        this.store.pipe(select('authData')).subscribe(
            (data: any) => {
                this.userDetails = data.userDetail;
            }
        );
    }

    updateBronzeLvl(type, level) {
        if (this.userDetails.userLevel === 'New') {
            const message = `Scan QR Code For ${type}`;
            const dialogRef = this._dialog.open(BronzeComponent, {
                width: '335px',
                data: {
                    message: message,
                    buttonText: 'OK',
                    level: level,
                    type: type,
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                }
            });
        }
    }

    // updateBronzeLvl() {
    //   const dialogRef = this._dialog.open(UpdateProfileModalComponent, {
    //     width: '650px',
    //     data: this.userDetails
    //   });
    //   dialogRef.afterClosed().subscribe(result => {
    //     if (result) {
    //     }
    //   });
    // }

    openDepositModal() {
        const dialogRef = this._dialog.open(InitialDepositModalComponent, {
            width: '700px',
            data: {
                showPayment: true,
                userDetail: this.userDetails
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                // this._route.navigate(['/']);
            }
        });
    }

    updateUserData() {
        this.sub = setInterval(() => {
            const userData = this._datum.getUserLoginData();
            this._ulog.getUserData(userData.idUser);
            this.getUserDetails();
        }, 3000);
    }


    ngOnInit() {
        this.getUserDetails();
        this.updateUserData();
    }

    ngOnDestroy() {
        clearInterval(this.sub);
    }
}
