import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'batp-confirm-order-login',
  templateUrl: './confirm-order-login.component.html',
  styleUrls: ['./confirm-order-login.component.css']
})
export class ConfirmOrderLoginComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
