declare var require: any;
import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import { ApiUrlService } from 'src/app/services/api-url/api-url.service';
import { ApirequestService } from 'src/app/services/apirequest/apirequest.service';

// const IndicatorsCore = require('highcharts/indicators/indicators');
// IndicatorsCore(Highcharts);
// const IndicatorZigZag = require('highcharts/indicators/zigzag');
// IndicatorZigZag(Highcharts);

@Component({
  selector: 'batp-high-charts',
  templateUrl: './high-charts.component.html',
  styleUrls: ['./high-charts.component.css']
})
export class HighChartsComponent implements OnInit, OnChanges {
  @ViewChild('container', { read: ElementRef }) container: ElementRef;
  @Input() idCompanyStock: any = '';
  @Input() viewMode: any = '';
  Highcharts = Highcharts;
  public chartOptions: any;
  arrayData: any;

  constructor(private _urls: ApiUrlService, private _req: ApirequestService) { }

  ngOnInit() {
    this.getHighCharts();
  }
  private getHighCharts(): void {

    this._req.fetchApiData(this._urls.highchartsUrl + `idCompanyStock=${this.idCompanyStock}`, {}, false, 'get').subscribe((res: any) => {
      this.arrayData = res;
      Highcharts.stockChart(this.container.nativeElement, {
        rangeSelector: {
          buttons: [{
            type: 'month' as 'month',
            count: 1,
            text: '1m',
            events: {
              click: function (e) {
                console.log('button clickd');
              }
            }
          }, {
            type: 'month',
            count: 3,
            text: '3m'
          }, {
            type: 'month',
            count: 6,
            text: '6m'
          }, {
            type: 'ytd',
            text: 'YTD'
          }, {
            type: 'year',
            count: 1,
            text: '1y'
          }, {
            type: 'all',
            text: 'All'
          }]
        },
        legend: {
          enabled: true,
        },

        credits: {
          enabled: false,
        },
        chart: {
          backgroundColor: {
            stops: [
              [0, 'rgb(22, 26, 35)'],
              [1, 'rgb(22, 26, 35)']
            ]
          },
        },
        plotOptions: {
          series: {
            showInLegend: false
          }
        },
        series: [{
          name: 'Stock Price',
          type: 'candlestick',
          data: res,
          tooltip: {
            valueDecimals: 2
          }
        }]
      });
    },
      (errror) => {

      });

  }
  ngOnChanges() {
    setTimeout(() => {
      this.getHighCharts();
    }, 400);
  }
}
