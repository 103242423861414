import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApirequestService } from '../../../services/apirequest/apirequest.service';
import { ApiUrlService } from '../../../services/api-url/api-url.service';
import { messageBoxIcon, iconSet } from '../../../shared/shared.data';
import { captchaKey, devEnv } from '../../../config/hybse.config';
import { ErrorMessages } from '../../../shared/error-messages';
import { validateEmail, checkPassword, validatePassword } from '../../../shared/form-validators';
import { ActivatedRoute } from '@angular/router';
import { trigger, transition, state, style, animate } from '@angular/animations';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import { CryptoService } from '../../../services/crypto/crypto.service';

@Component({
  selector: 'batp-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  animations: [
    trigger('FadeAnimation', [
      state('in', style({
        opacity: 1
      })),
      transition(':enter', [
        style({opacity: 0}),
        animate(600 )
      ]),
      transition(':leave',
        animate(600, style({opacity: 0})))
    ])
  ]
})
export class ResetPasswordComponent implements OnInit {

  constructor(
      private _fb: FormBuilder,
      private _req: ApirequestService,
      private _ar: ActivatedRoute,
      private _sb: SnackbarService,
      private _urls: ApiUrlService,
      private _cryp: CryptoService,
  ) { }

  loginMessage:any = '';
  resetPwdMsg:any = '';
  resetPwdStatus:boolean = false;
  showLoader:boolean = false;
  messIcons:iconSet = messageBoxIcon;
  captchaKey:string = captchaKey;
  resetPwdForm:FormGroup;
  errorMessages:any = new ErrorMessages();
  resetPwdFormInit() {
    this.resetPwdForm = this._fb.group({
      'password': ['', [ Validators.required, validatePassword]],
      'confirmPassword': ['',[Validators.required]],
      'recaptcha': ['', devEnv ? [] : [Validators.required] ]
    },
    { validator: [checkPassword] });
  }
  queryParams:any;
  resetPwd() {
    if(this.resetPwdForm.valid) {
      this.showLoader = true;
      const formVal = this.resetPwdForm.value;
      const data = {
        ...this.queryParams,
        password: formVal.password
      };

        const encData = {
            load: this._cryp.encrypt(JSON.stringify(data))
        };

      this._req.fetchApiData(this._urls.resetPasswordUrl, encData).subscribe(
        (data:any) => {
          let resSucc = data.data;
          let resErr = data.error;
          if(resSucc != '') {
            this.resetPwdMsg = 'Your Password Has Been Changed Successfully';
            this.resetPwdStatus = true;
            this.resetPwdForm.reset();
          }
          if(resErr != '') {
            //this.resetPwdMsg = resErr['Error Description'];
            this._sb.openSnackBar(resErr['Error Description'],4000,'hybse_error_snackbar','top','right');
            this.resetPwdStatus = false;
          }
        },
        error => {

        },
        () => {
          this.showLoader = false;
        }
      )
    }
  }
  handleSuccess(evt) {
  }
  ngOnInit() {
    this.resetPwdFormInit();
    this._ar.queryParams.subscribe(
      params => {
        if(params.idUser && params.activationKey) {
          this.queryParams = params;
        }
      }
    );
  }


}
