import { Component, OnInit } from "@angular/core";
import { LoginService } from "../../../services/login/login.service";
import { ApirequestService } from "../../../services/apirequest/apirequest.service";
import { validateEmail, validNumber } from "../../../shared/form-validators";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { ErrorMessages } from "../../../shared/error-messages";
import { Router } from "@angular/router";
import { messageBoxIcon, iconSet } from "../../../shared/shared.data";
import { devEnv, captchaKey } from "../../../config/hybse.config";
import { TwoFactorService } from "src/app/services/twoFactor/two-factor.service";
import { CryptoService } from '../../../services/crypto/crypto.service';

@Component({
  selector: "batp-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  constructor(
    private _fb: FormBuilder,
    private _req: ApirequestService,
    private _route: Router,
    private _tFa: TwoFactorService,
    private _auth: LoginService,
    private _cryp: CryptoService,
  ) {}

  userLoginForm: FormGroup;
  twoFactorLogin: FormGroup;
  userFormSubmitted: boolean = false;
  errorMessages: any = new ErrorMessages();
  showLoader: boolean = false;
  loginStatus: boolean = false;
  loginMessage: any = "";
  messIcons: iconSet = messageBoxIcon;
  captchaKey: string = captchaKey;
  userLoginFormInit() {
    this.userLoginForm = this._fb.group({
      email: ["", [Validators.required, validateEmail]],
      password: ["", [Validators.required]],
      g_recatcha: ["", devEnv ? [] : [Validators.required]]
    });
  }
  twoFactorFormInit() {
    this.twoFactorLogin = this._fb.group({
      two_factor_code: [
        "",
        [
          Validators.required,
          validNumber,
          Validators.minLength(6),
          Validators.maxLength(6)
        ]
      ]
    });
  }

  userLoginData: any;
  isTwoFactorEnabled: boolean = false;
  userLogin() {
    this.userFormSubmitted = true;
    if (this.userLoginForm.valid) {
      this._auth.loginStatus.next(null);
      this._tFa.twoFactorData.next(null);
      this.resetMessages();
      this.showLoader = true;
      let formVal: any = this.userLoginForm.value;
      this.userLoginData = {
        email: formVal.email,
        password: formVal.password
      };
      // this._tFa.checkTwoFactorEnabled(formVal.email);
      // this.checkTfaStatus();

        const encData = {
            load: this._cryp.encrypt(JSON.stringify(this.userLoginData))
        };

      this._auth.login(encData);
      this.getLoginStatus();
      this.getLoginMessages();
      this.loaderStatus();
    }
  }

  twoFactorSubmit() {
    if (this.twoFactorLogin.valid) {
      this._tFa.checkTfaCodeData.next(null);
      this.showLoader = true;
      let formVal = this.twoFactorLogin.value;
      let data = {
        code: formVal.two_factor_code,
        secret: this.loginResData.user2faSecretKey
      };
      this._tFa.checkCodeAtLogin(data);
      this._tFa.checkTfaCodeData.subscribe(data => {
        if (data) {
          let res = data.data;

          if (data.status === true) {
            this.showLoader = false;
            if (res != "") this._auth.saveLoginData(this.loginResData);
          } else if (data.status === false) {
            this.showLoader = false;
            if (res != "") {
              this.loginMessage = res["Error Description"];
              this.loginStatus = false;
            }
          }
        }
      });
    }
  }
  tFaData: any;
  checkTfaSub: any;
  checkTfaStatus() {
    this.checkTfaSub = this._tFa.twoFactorData.subscribe(
      data => {
        if (data) {
          this.showLoader = false;
          this.tFaData = data;
          let res = data.data;
          this.tFaData = res;
          if (data.status == true) {
            res.isActive == 1 ? this.enableTwoFactor() : this.loginUser();
          } else if (data.status == false) {
            this.loginMessage = res["Error Description"];
            this.loginStatus = data.status;
          }
        }
      },
      error => {},
      () => {}
    );
  }
  enableTwoFactor() {
    this.isTwoFactorEnabled = true;
    this.twoFactorFormInit();
  }

  loginUser() {
    this.showLoader = true;
    this._auth.login(this.userLoginData);
    this.getLoginStatus();
    this.getLoginMessages();
    this.loaderStatus();
  }

  resetMessages() {
    this.loginStatus = false;
    this._auth.loginErrMsg.next("");
    this.loginMessage = "";
  }

  loginStatSubs: any;
  loginResData: any = [];
  getLoginStatus() {
    this.loginStatSubs = this._auth.loginStatus.subscribe((data: Object) => {
      if (data) {
        if (data.hasOwnProperty("idUser")) {
          this.loginResData = data;
          this.enableTwoFactor();
        }
      }
    });
  }
  loginMsgSubs: any;
  getLoginMessages() {
    this.loginMsgSubs = this._auth.loginErrMsg.subscribe(data => {
      if (data != "") {
        this.loginMessage = data;
      }
    });
  }

  loaderStatSubs: any;
  loaderStatus() {
    this.loaderStatSubs = this._auth.showLoader.subscribe(data => {
      this.showLoader = data;
    });
  }

  getSlStatus($evt) {
    this.showLoader = true;
    if (Object.keys($evt.error).length != 0) {
      this.loginStatus = false;
      this.showLoader = false;
      this.loginMessage = $evt.error["Error Description"];
    }
  }

  handleSuccess(event) {}

  ngOnInit() {
    this.userLoginFormInit();
  }

  ngOnDestroy() {
    if (typeof this.loginStatSubs != "undefined")
      this.loginStatSubs.unsubscribe();
    if (typeof this.loginMsgSubs != "undefined")
      this.loginMsgSubs.unsubscribe();
    if (typeof this.loaderStatSubs != "undefined")
      this.loaderStatSubs.unsubscribe();
    if (typeof this.checkTfaSub != "undefined") this.checkTfaSub.unsubscribe();
  }
}
