import { Component, OnInit } from '@angular/core';
import { PageContentService } from 'src/app/services/pageContent/page-content.service';
import { pressCenterId, pressCenterAlias } from 'src/app/shared/data/content-page-data';

@Component({
  selector: 'batp-press-content',
  templateUrl: './press-content.component.html',
  styleUrls: ['./press-content.component.css']
})
export class PressContentComponent implements OnInit {

  constructor(private _ps:PageContentService) { }
  pcContent:any = [];
  showLoader:boolean = true;
  getPressCenterCont() {
    this._ps.getContentByCategory(pressCenterId,pressCenterAlias);
    this._ps.pressContent
      .subscribe(
        data => {
          if(data) {
            this.pcContent = data;
            this.showLoader = false;
          }
        }
      );
  }

  ngOnInit() {
    this.getPressCenterCont()
  }

}
