import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApirequestService } from '../../../services/apirequest/apirequest.service';
import { ApiUrlService } from '../../../services/api-url/api-url.service';
import { Store, select } from '@ngrx/store';
import * as rootStore from '../../../app.reducer';
import { SessionOutService } from '../../../services/sessionOut/session-out.service';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { VideoDialogComponent } from '../../widgets/video-dialog/video-dialog.component';
import { environment } from 'src/environments/environment';
import { DatumService } from 'src/app/services/datum/datum.service';
import { MarketsService } from 'src/app/services/markets/markets.service';
import { UtilityService } from 'src/app/services/utilities/utility.service';
import { LoginService } from 'src/app/services/login/login.service';
import { IbinService } from 'src/app/services/ibin/ibin.service';
import { StorageService } from 'src/app/services/localstorage/storage.service';
import { Router } from '@angular/router';
import { _ibin_api_url } from 'src/app/config/hybse.config';
declare var $: any;

@Component({
  selector: 'batp-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('favoriteStockSort') favoriteStockSort: MatSort;
  isMobile: boolean = this._utils.checkMobileDevice();
  stockListColumns: string[] = ['serial', 'companyName', 'ibin', 'stockName', 'priceCurrent', 'volume', 'Percentage', 'View'];

  stockList: any = [];
  stockListClone: any = [];
  stockListSubs: any;
  favoriteStock: any = [];
  userDetails:any = [];
  preferredTheme:boolean = true;
  constructor(private _req: ApirequestService,
    private store: Store<rootStore.State>,
    private _so: SessionOutService,
    private _datum: DatumService,
    private _dialog: MatDialog,
    private _url: ApiUrlService,
    private _market: MarketsService,
    private _route: Router,
    private _utils: UtilityService,
    private _http: HttpClient,
    private _lo: LoginService,
    private _ibin: IbinService,
    private _ls: StorageService) { }



  isAuthenticated: boolean = false;
  getAuthenticatedData() {
    this.store.pipe(select('auth')).subscribe(
      (data: any) => {
        this.isAuthenticated = data.isAuthenticated;
      }
    );
  }

  openVideo() {
    const dialogRef = this._dialog.open(VideoDialogComponent, {
      width: '800px',
      data: {
        embedUrl: ''
      }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }


  userData: any;
  getUserData() {
    this.userData = this._datum.getUserLoginData();
  }

  getDemoUrl(prod: boolean = true) {
    let url = environment.demoUrl + this.userData.demoToken;
    // let url = 'https://demostage.business-software.in?demoToken=' + this.userData.demoToken;
    return url;
  }
  getUserDetails() {
    this.store.pipe(select('authData')).subscribe(
      (data:any) => {
        this.userDetails = data.userDetail;

        // this.preferredTheme = this.userDetails.preferredTheme == true ? true : false;
        // this._ls.setLocalItem('preferredTheme',this.preferredTheme);
        // this._lo.preferredTheme.next(this.preferredTheme);
      }
    );
  }

  ngOnInit() {
    this.getAuthenticatedData();
    this.getLoginStatus();
     this.getUserData();
    this.getUserDetails();
    this.getStockList();
  }




  // add market page

  getStockLists() {
    this._market.getStockList();
    this.stockListSubs = this._market.stockList.subscribe(
      (data: any) => {
        if (data != null) {
          if (data !== 'error') {
            this.stockList = []; this.stockListClone = [];
            this.stockList = data;
            this.stockListClone = new MatTableDataSource(data);
            this.stockListClone.sort = this.sort;
            // this.stockListClone.sort.active = 'volume';
            this.getFavorites(data);
          } else{
            this.stockList = [];
          }
        }
      }
    );
  }

  getFavorites(data) {
    let favoriteStock = this._utils.filteredArray(data, 'isFavourite', true);
    this.favoriteStock = new MatTableDataSource(favoriteStock);
    this.favoriteStock.sort = this.favoriteStockSort;
  }

  searchMarket(filterValue) {
    this.stockListClone.filter = filterValue.trim().toLowerCase();
  }

  getChange(stock, type) {
    return this._market.getProfileLoss(stock, type);
  }

  getFlagUrl(ibin: string) {
    let flag = ibin.substr(0, 2).toLowerCase();
    let flagUrl = `assets/images/flags/${flag}.svg`;
    return flagUrl;
  }

  getProfileLoss(stock, type = 'sign') {
    return this._market.getProfileLoss(stock, type);
  }

  navToMarketInfo(stock) {
    // let marketInfo = this.isMobile ? 'market-info-mobile' : 'market-info';
    this._route.navigate(['market-details', stock.idCompany, stock.idCompanyStock]);
  }
  navToMarketTrade(stock) {
    let latestView = this._ls.getLocalItems('latestView');
    let marketInfo = '';
    if (latestView) {
      marketInfo = this.isMobile ? 'market-info-mobile' : latestView;
    } else {
      marketInfo = this.isMobile ? 'market-info-mobile' : 'market-info-pro';
    }
    this._route.navigate([marketInfo, stock.idCompanyStock]);
  }

  // userData: any = '';

  // getUserData() {
  //   this.userData = this._datum.getUserLoginData();
  //   if (Object.keys(this.userData).length === 0){ this.userData = ''};
  // }

  toggleFavorites(stock, ind) {
    const url = stock.isFavourite ? this._url.deleteCompanyFavouriteUrl : this._url.createCompanyFavouriteUrl;
    const data = {
      idCompanyStock: stock.idCompanyStock
    };
    const favToggle = !stock.isFavourite;
    stock.isFavourite = !stock.isFavourite;
    this._req.fetchApiData(url, data, false).subscribe(
      (data: any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if (resSucc != '') {
          stock.isFavourite = favToggle;
          // stock.isFavourite = !stock.isFavourite;
          this.getStockLists();
        }
        if (resErr) {
          this._so.handleSessionOutErr(resErr);
        }
      }
    );
  }

  getLoginStatus() {
    this.getStockLists();
  }

  getIbinLink(ibin) {
    // return `https://ibin.io/company_profile/${ibin}`;
    let url = `${_ibin_api_url}ibin/${ibin}`;
    this._http.get(url).subscribe(
      data => {
        console.log(data);
      });
    // this._req.fetchApiData(url,{},false,'get').subscribe(
    //   data => {
    //     console.log(data);
    //   }
    // )
  }
  @Input() paramIdHdr:any;
  getMarketRoute(route) {
    let url = [];
    if(this.stockList.length > 0) {
      setTimeout(()=>{
        if(typeof this.paramIdHdr == 'undefined')
        this.paramIdHdr = this.stockList[0].idCompanyStock;
        url = [route,this.paramIdHdr];
        this._ls.setLocalItem('latestView',url[0]);
        this._route.navigate(url);
        this.menuToggle(false);
      },400);
    }
  }
  getStockList() {
    let data = {};
    if(this.userData != '') data = { idUser: this.userData.idUser };
    this._req.fetchApiData(this._url.stockListUrl,data,true).subscribe(
      (data:any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if(resSucc != '') {
          this.stockList = resSucc;
        }
      }
    )
  }
  menuStatus:boolean = false;
  menuToggle(bool) {
    this.menuStatus = bool;
  }

  ngOnDestroy() {
    if (typeof this.stockListSubs !== 'undefined') { this.stockListSubs.unsubscribe(); }
  }

}
