import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'batp-page-banner',
  templateUrl: './page-banner.component.html',
  styleUrls: ['./page-banner.component.css']
})
export class PageBannerComponent implements OnInit {

  constructor() { }
  @Input() bannerImg:string = '';
  assetUrl:string = 'assets/images/';
  ngOnInit() {

  }

}
