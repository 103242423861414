import { Injectable } from '@angular/core';
import { ApirequestService } from '../apirequest/apirequest.service';
import { ApiUrlService } from '../api-url/api-url.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class RegisterService {

  constructor( private _req:ApirequestService,
    private _urls: ApiUrlService ) { }


  registerStatus:any = new BehaviorSubject({});
  registerData:any = new BehaviorSubject('');
  registerErrMsg:any = new BehaviorSubject('');
  showLoader:any = new BehaviorSubject(false);
  register(user,data) {
    let url = ( user == 'user' ) ? this._urls.userRegistrationUrl : this._urls.issuerRegistrationUrl;
    this._req.fetchApiData(url,data).subscribe(
      (data:any) => {
        let resSucc:any = data.data;
        let resError:any = data.error;
        let result;
        if(resSucc != '') {
          result = { status: true, result: resSucc }
          this.registerStatus.next(result);
        }
        if(resError != '') {
          result = { status: false, result: resError }
          this.registerStatus.next(result);
          this.registerErrMsg.next(resError['Error Description']);
        }
        this.showLoader.next(false);
      },
      error => {

      },
      () => {

      }
    )
  }


  resendEmailLoader:boolean = false;
  resendMailStatus = new BehaviorSubject(null);
  resendEmail(data) {
    let url = this._urls.resendEmailUrl;
    this.resendEmailLoader = true;
    this._req.fetchApiData(url,data).subscribe(
      (data:any) => {
        let resSucc = data.data;
        let resErr = data.error;
        let result = { status: null, data: null };
        if(resSucc != '') {
          result.status = true;
          result.data = resSucc;
          this.resendEmailLoader = false;
        }
        if(resErr != '') {
          result.status = false;
          result.data = resErr;
          this.resendEmailLoader = false;
        }
        this.resendMailStatus.next(result);
      },
      error => {

      },
      () => {

      }
    )
  }

}
