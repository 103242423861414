import { Injectable } from '@angular/core';
import { HybseConfig } from '../../config/hybse.config';

@Injectable()
export class ApiUrlService {

  constructor(public config: HybseConfig) {

  }

  listMenuItemUrl: string = this.config.apiURL + 'getMenuHierarchy';

  /* Login Urls */
  // loginUrl: string = this.config.apiURL + 'userLogin';
  loginUrl: string = this.config.authURL + 'userLogin';


  socialRegisterUrl: string = this.config.apiURL + 'socialRegister';
  socialLoginUrl: string = this.config.apiURL + 'socialLogin';

  /* Get Menu Item */
  menuItemsByNameUrl: string = this.config.apiURL + 'menuItemsByName';

  /* User Details */
  // userDetailsUrl: string = this.config.apiURL + 'userDetails';
  userDetailsUrl: string = this.config.apiURL + 'userInfo';
  companyDetailsUrl: string = this.config.apiURL + 'companyDetail';

  /* User Registration */
  // userRegistrationUrl: string = this.config.apiURL + 'investorRegister';
  // issuerRegistrationUrl: string = this.config.apiURL + 'issuerRegistration';
  companyRegistrationUrl: string = this.config.apiURL + 'companyRegistration';

  issuerRegistrationUrl: string = this.config.authURL + 'issuerRegistration';
  userRegistrationUrl: string = this.config.authURL + 'investorRegister';

  /* Issuer Backend */
  companyInformationUpdateUrl: string = this.config.apiURL + 'companyInformationUpdate';
  companyDescriptionUpdateUrl: string = this.config.apiURL + 'companyDescriptionUpdate';
  companyManagementUpdateUrl: string = this.config.apiURL + 'companyManagementUpdate';
  addCompanyManagementUrl: string = this.config.apiURL + 'addCompanyManagement';
  deleteCompanyManagementUrl: string = this.config.apiURL + 'deleteCompanyManagement';
  addCompanyDocumentsUrl: string = this.config.apiURL + 'addCompanyDocuments';
  deleteCompanyDocumentsUrl: string = this.config.apiURL + 'deleteCompanyDocuments';
  companyUpdateUrl: string = this.config.apiURL + 'companyUpdate';


  /* User Verification */
  resendEmailUrl: string = this.config.authURL + 'resendEmail';
  resendEmailIssuerUrl: string = this.config.authURL + 'resendIssuerActivationEmail'

  /* Issuer Detail */
  issuerDetailUrl: string = this.config.apiURL + 'issuerDetail';


  /* Password & Email Change */
  changePasswordUrl: string = this.config.apiURL + 'passwordChange';
  changeEmailUrl: string = this.config.apiURL + 'emailChange';


  /* User Update Profile and Wallet */
  updateUserDetailsUrl: string = this.config.apiURL + 'updateUserDetails';
  updateFinancialDetailsUrl: string = this.config.apiURL + 'updateFinancialDetails';


  /* User Account Details */
  userAccountDetailsUrl: string = this.config.apiURL + 'userAccountDetails';
  ledgerDetailsUrl: string = this.config.apiURL + 'ledgerDetails';

  userDetailmainUrl: string = this.config.pollingURL;


  /* Google Two Factor Authentication */
  verify2faUrl: string = this.config.apiURL + 'verify2fa';
  verifyQRCodeUrl: string = this.config.apiURL + 'verifyQRCode';
  twofaEnableorNotUrl: string = this.config.apiURL + 'twofaEnableorNot';
  verify2faAtLoginUrl: string = this.config.apiURL + 'verify2faAtLogin';

  /*Primary Account Details */
  getPrimaryActUrl: string = this.config.apiURL + 'primaryAccountDetails';

  /*Deposit, Withdraw & Transfer */
  withdrawalUrl: string = this.config.apiURL + 'withdraw';
  depositToTradingWalletUrl: string = this.config.apiURL + 'depositToTradingWallet';
  withdrawFromTradingWalletUrl: string = this.config.apiURL + 'withdrawFromTradingWallet';

  /* Get Public and Private Keys */
  getUserAPIKeysUrl: string = this.config.apiURL + 'getUserAPIKeys';

  /* Transaction History */
  nemHistoryUrl: string = this.config.apiURL + 'nemHistoryList';

  /* Currency List */
  primarySymbolUrl: string = this.config.apiURL + 'primarySymbol';

  /* Active and Matched Orders */
  tradeHistoryUrl: string = this.config.apiURL + 'tradingHistory';
  userOrderUrl: string = this.config.apiURL + 'userOrder';

  /* Activation and Temp Email Update */
  // activationUrl: string = this.config.apiURL + 'accountActivation';
  // tempEmailUpdateUrl: string = this.config.apiURL + 'tmpEmailUpdate';
  activationUrl: string = this.config.authURL + 'accountActivation';
  tempEmailUpdateUrl: string = this.config.authURL + 'tmpEmailUpdate';

  /* Forgot Password and Reset Password */
  // forgotPasswordUrl: string = this.config.apiURL + 'forgotPassword';
  forgotPasswordUrl: string = this.config.authURL + 'forgotPassword';
  resetPasswordUrl: string = this.config.authURL + 'resetPassword';


  /* Profile Image Update */
  updateProfileImageUrl: string = this.config.apiURL + 'updateProfileimage';

  /* Update Document & Silver, Gold Verify */
  verifySilverUrl: string = this.config.apiURL + 'verifySilver';
  goldVerifyUrl: string = this.config.apiURL + 'goldVerify';

  /* Markets */
  deleteOrderUrl: string = this.config.apiURL + 'deleteOrder';
  stockListUrl: string = this.config.apiURL + 'stockList';
  stockInfoUrl: string = this.config.apiURL + 'stockInfo';
  orderBookUrl: string = this.config.apiURL + 'orderBook';
  buyOrderUrl: string = this.config.apiURL + 'buyOrder';
  sellOrderUrl: string = this.config.apiURL + 'sellOrder';
  matchedordersUrl: string = this.config.apiURL + 'matchedorders';
  marketDepthUrl: string = this.config.apiURL + 'marketDepth';
  createCompanyFavouriteUrl: string = this.config.apiURL + 'createCompanyFavourite';
  deleteCompanyFavouriteUrl: string = this.config.apiURL + 'deleteCompanyFavourite';

  /* NEWS LIST */
  newsList = this.config.apiURL + 'listExchangeNews';

  /* VOUCHER */
  redeemCouponUrl = this.config.apiURL + 'redeemCoupon';

  /* IBO LIST */
  iboList = this.config.apiURL + 'iBOList';

  /* ID NOW Connect */
  idNowConnectUrl: string = this.config.apiURL + 'idNowPersonalInfo';

  /*Session Check */
  heartBeatUrl = this.config.apiURL + 'heartbeat';


  /* Page Content Api */
  getContentUrl: string = this.config.apiURL + 'getContent';
  getContentCategoryUrl: string = this.config.apiURL + 'getContentCategory';
  getContentByCategoryUrl: string = this.config.apiURL + 'getContentByCategory';

  /* IBO Detailed List */
  iBODetailedListUrl = this.config.apiURL + 'iBODetailedList';
  iBOBuyorder = this.config.apiURL + 'iBOBuyorder';

  /* CET Time */
  cetTimeUrl: string = this.config.apiURL + 'cetTime';

  /* Contact & Support */
  supportContactUrl = this.config.apiURL + 'supportContact';

    /* Get in touch */
    getInTouchUrl = this.config.apiURL + 'leadContact';

  /*KYC */
  kycInternalTokenUrl = `${this.config.kycURL}internal/token`;
  qrCodeUrl = `${this.config.kycURL}internal/qrcode`;
  silverPlusKycUrl = `${this.config.apiURL}silverPlusKyc`;
  webHooksAddUrl = `${this.config.kycURL}internal/webhook/add`;

  /**  KYC update */
  getQRcodeUrl = this.config.apiURL + 'getQRcode';
  getUserKycUrl = this.config.apiURL + 'getUserKyc';

  /**  Change Preferred Theme */
  changeThemeUrl: string = this.config.apiURL + 'changeTheme';

  /** high-charts */
  highchartsUrl = this.config.chartURL+'highchart?';
  areachartsUrl = this.config.chartURL+'areachart?';

  /*Log Out */
  logoutUrl: string = this.config.apiURL + 'Logout';
  logoutPreviousSessionUrl: string = this.config.apiURL + 'logoutPreviousSession';
  isUserLogin: string = this.config.authURL + 'isUserLogin';

  /* Transfer from BEX to BEX */
  transferUrl: string = this.config.apiURL + 'transferAssets';
}
