import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { validNumber, validPhoneNumber, ValidAge } from '../../../../../shared/form-validators';
import { countries, Countries } from '../../../../../shared/data/countries';
import { ErrorMessages } from '../../../../../shared/error-messages';
import { DatumService } from '../../../../../services/datum/datum.service';
import { ApiUrlService } from '../../../../../services/api-url/api-url.service';
import { ApirequestService } from '../../../../../services/apirequest/apirequest.service';
import { idNowUrl } from '../../../../../config/hybse.config';
import { UtilityService } from '../../../../../services/utilities/utility.service';
import { MatDialog } from '@angular/material';
import { ModalBoxComponent } from '../../../../widgets/modal-box/modal-box.component';
import { SessionOutService } from '../../../../../services/sessionOut/session-out.service';
import { FsUploadService } from 'src/app/services/fs-upload/fs-upload.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { Router } from '@angular/router';


@Component({
  selector: 'batp-silver',
  templateUrl: './silver.component.html',
  styleUrls: ['./silver.component.css']
})
export class SilverComponent implements OnInit {

  constructor(private _fb:FormBuilder,
              private _url:ApiUrlService,
              private _req:ApirequestService,
              private _utils: UtilityService,
              private _dialog: MatDialog,
              private _router:Router,
              private _sb:SnackbarService,
              private _fs:FsUploadService,
              private _so:SessionOutService,
              private _datum:DatumService) { }

  countryList:Countries[] = countries;
  silverForm:FormGroup;
  errorMessages:any = new ErrorMessages();
  userDetails:any = {};
  showLoader:boolean = false;
  idConnectStat:any = {
    message: '',
    icon: '',
    messageType: ''
  }
  silverFormInit() {
    this.silverForm = this._fb.group({
      idNowGrp: this._fb.group({
        gender: ['Male', [Validators.required] ],
        firstName:[ '', [Validators.required] ],
        lastName: [ '', [Validators.required] ],
        dob: [ '', [Validators.required,ValidAge] ],
        country: [ '', [Validators.required] ],
        address1: [ '', [Validators.required] ],
        address2: [ '', [] ],
        zipCode: [ '', [Validators.required, validNumber] ],
        countryCode: [ '', [Validators.required] ],
        mobile: [ '', [Validators.required, validPhoneNumber ] ],
        city: [ '', [Validators.required] ],
        docName: [ 'License', [Validators.required] ],
        docId: [ '', [Validators.required] ],
        docExpiry: [ '', [Validators.required] ],
        docFileName: [ '', [Validators.required] ],
        docFile: [ '', [Validators.required] ],
        photoFileName: ['', [Validators.required]],
        photoFile: [ '', [Validators.required] ],
      }),
      idNownumber: [''],

    })
  }



  getUserDetails() {
    this.userDetails = this._datum.getUserDetails();
  }
  setProfDetails() {
    this.silverForm.patchValue({
      idNowGrp : {
        gender: this.getValue(this.userDetails.gender,'Male'),
        firstName: this.getValue(this.userDetails.firstName,''),
        lastName: this.getValue(this.userDetails.lastName,''),
        dob: this.getValue(this.userDetails.dob,''),
        country: this.getValue(this.userDetails.country,''),
        address1: this.getValue(this.userDetails.address1,''),
        address2: this.getValue(this.userDetails.address2,''),
        zipCode: this.getValue(this.userDetails.zipCode,''),
        city: this.getValue(this.userDetails.city,''),
        countryCode: this.getValue(this.userDetails.telcode,''),
        mobile: this.getValue(this.userDetails.mobile,'')
      }
    });
  }

  getValue(val,retVal:string = 'N/A') {
    return (val == '' || val == 'N/A') ? retVal : val;
  }
  getDob(date) {
    let year = date.getFullYear(), month = date.getMonth() + 1, day = date.getDate();
    if(day < 10) day='0'+ day;
    if(month < 10)  month='0'+month;
    return year + '-' + month + '-' + day;
  }


  uploadFiles(type) {
    let fileAccepted = [".pdf",".doc",".docx",".docm","image/*"];
    let maxSize = 10485760;
    this._fs.uploadFiles(fileAccepted,maxSize).then((res)=>{
      if(res.filesUploaded.length > 0) {
        this.fileUploadHandler(type,res)
      }
    });
  }
  fileUploadHandler(type,res) {
    let filesFailed = res.filesFailed;
    let fileUploaded = res.filesUploaded;
    if(fileUploaded.length > 0 ) {
      let data;
      if(type == 'doc') {
        data = {
          'docFileName': fileUploaded[0].filename,
          'docFile': fileUploaded[0].url
        }
      } else {
        data = {
          'photoFileName': fileUploaded[0].filename,
          'photoFile': fileUploaded[0].url
        }
      }
      this.silverForm.patchValue({ idNowGrp: data });
    } else {

    }
  }

  /*getIdNowNumber() {
    if(this.silverForm.get('idNowGrp').valid) {
      this.showLoader = true;
      let data = this.silverForm.get('idNowGrp').value;
      data.dob = this.getDob(new Date( data.dob ));
      this._req.fetchApiData(this._url.idNowConnectUrl,data,false).subscribe(
        (data:any) => {
          let resSucc = data.data;
          let resErr = data.error;
          if(resSucc != '') {
            this._utils.scrollToY(0,400,'easeInOutSine');
            // this.openModalBox();
            // let idNowBtn:any = document.getElementById('idNowBtn');
            // idNowBtn.target = '_blank';
            // idNowBtn.href = idNowUrl + resSucc;
            // idNowBtn.click();
          }
          if(resErr != '') {
            this.setIdConnectStat('','fal fa-exclamation-triangle','success');
            this._so.handleSessionOutErr(resErr);
          }

        },
        error => {

        },
        () => {
          this.showLoader = false;
        }
      )
    }
  }

  openModalBox() {
    const dialogRef = this._dialog.open( ModalBoxComponent, {
      width: '250px',
      data: {
        message: ''
      }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  } */

  setIdConnectStat(message,icon,messageType) {
    this.idConnectStat.message = message;
    this.idConnectStat.icon = icon;
    this.idConnectStat.messageType = messageType;
  }

  silverFormSubmit() {
    if(this.silverForm.valid) {
      this.showLoader = true;
      let formdata = this.silverForm.get('idNowGrp').value;
      let formVal = this.silverForm.value;
      let data = formdata;
      this._req.fetchApiData(this._url.verifySilverUrl,data,false).subscribe(
        (data:any) => {
          let resSucc = data.data;
          let resErr = data.error;
          if(resSucc != '') {
            this._sb.openSnackBar(
              "Your Data Has Been Submitted Successfully",
              3000,
              "hybse_success_snackbar",
              "center",
              "top"
            );
            this._router.navigate(['/profile/verification-level']);
          }
          if(resErr != '') {
            this._sb.openSnackBar(
              resErr["Error Description"],
              3000,
              "hybse_error_snackbar",
              "center",
              "top"
            );
            this._so.handleSessionOutErr(resErr);
          }
        },
        error => {

        },
        () => {
          this.showLoader = false;
        }
      )
    }
  }




  ngOnInit() {
    this.silverFormInit();
    this.getUserDetails();
    this.setProfDetails();
  }

}
