import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { InitialDepositModalComponent } from 'src/app/components/widgets/initial-deposit-modal/initial-deposit-modal.component';

@Component({
  selector: 'batp-register-section',
  templateUrl: './register-section.component.html',
  styleUrls: ['./register-section.component.css']
})
export class RegisterSectionComponent implements OnInit {

  constructor( private _route: Router, private _dialog:MatDialog) { }
  @Input() fillBg:boolean = false;
  @Input() homeRegister:boolean = false;

  navToRegistration(type) {
    let route = (type == 'user') ? '/user-registration' : '/issuer-registration';
    this._route.navigate([route]);
  }


  openDepositModal() {
    const dialogRef = this._dialog.open(InitialDepositModalComponent, {
      width: '700px',
      data: {
        showPayment: false,
        userDetail: {}
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        //this._route.navigate(['/']);
      }
    });
  }
  ngOnInit() {

  }

}
