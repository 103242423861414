import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApirequestService } from '../../../../services/apirequest/apirequest.service';
import { ApiUrlService } from '../../../../services/api-url/api-url.service';
import { LoginService } from '../../../../services/login/login.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ErrorMessages } from '../../../../shared/error-messages';
import { validNumber } from '../../../../shared/form-validators';
import { DatumService } from '../../../../services/datum/datum.service';
import { SnackbarService } from '../../../../services/snackbar/snackbar.service';
import { SessionOutService } from '../../../../services/sessionOut/session-out.service';
import { StorageService } from 'src/app/services/localstorage/storage.service';
import { ConfirmLoginComponent } from 'src/app/components/widgets/confirm-login/confirm-login.component';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { BuyOrderComponent } from 'src/app/components/widgets/buy-order/buy-order.component';
import { AccountDetailsService } from 'src/app/services/accountDetails/account-details.service';
import { UtilityService } from 'src/app/services/utilities/utility.service';
import { MarketsService } from 'src/app/services/markets/markets.service';

@Component({
  selector: 'batp-ibo-company',
  templateUrl: './ibo-company.component.html',
  styleUrls: ['./ibo-company.component.css']
})
export class IboCompanyComponent implements OnInit {

  constructor(private _ac:ActivatedRoute,
              private _req:ApirequestService,
              private _ls:LoginService,
              private _ss:StorageService,
              private _route:Router,
              private _datum:DatumService,
              private _so:SessionOutService,
              private _fb:FormBuilder,
              private _sb: SnackbarService,
              private _urls:ApiUrlService,
              private _dialog:MatDialog,
    private _accDetail: AccountDetailsService,
    private _utils: UtilityService,
    private _market: MarketsService) { }

  iboPurchaseForm:FormGroup;
  iboDetails:any  = [];
  isAuthenticated:boolean = false;
  formSubmitted:boolean = false;
  toggle1:boolean = false;
  toggle2:boolean = false;
  toggle3:boolean = false;
  toggle4:boolean = false;
  toggle5:boolean = false;
  toggle6:boolean = false;
  toggle7:boolean = false;
  allowPurchaseShares:boolean = false;
  errorMessages:any = new ErrorMessages();
  userDetails:any = [];
  btnText:string = 'Complete Purchase';
  purchaseMessage:string = '';
  purchaseStatus:boolean = false;

  buyOrderForm: FormGroup;
  buyOrderLoader = false;
  sellOrderLoader = false;
  buyOrderSubmitted = false;
  stockInfo: any = { lockupStock: [] };
  actDetailSubs: any;
  stockListSubs: any;
  stockList: any = [];
  stockListClone: any = [];

  accountDetails: any = {
    primary: [],
    secondary: []
  };
  primaryData: any = [];
  secondaryData: any = [];
  idCompanyStock:any;

  isMobile:boolean = false;
  wltBalUSD: any = 0;
  wltBalBTC: any = 0;
  @ViewChild('PrimarySort')
  PrimarySort: MatSort;
  @ViewChild('SecondarySort')
  SecondarySort: MatSort;
  iboPurchaseFormInit() {
    this.iboPurchaseForm = this._fb.group({
      shareSize: ['',[Validators.required,validNumber]],
      balancePercent: [''],
      walletAddress: [''],
      availableAmt: [''],
    })
  }
  getUserDetails() {
    this.userDetails = this._datum.getUserDetails();
  }
  // getIBOCompDetails(id) {
  //   if(typeof id != 'undefined') {
  //     let data = {
  //       idCompanyStock: id
  //     }
  //     this._req.fetchApiData(this._urls.iBODetailedListUrl,data).subscribe(
  //       (data:any) => {
  //         let resSucc = data.data;
  //         let resErr = data.error;
  //         if(resSucc != '') {
  //           this.iboDetails = resSucc[0];
  //           this.iboPurchaseForm.controls['walletAddress'].setValue(this.userDetails.internalAddress);
  //           this.iboDiscount = this.iboDetails.lockupStock[0].discount;
  //           this.askPrice = this.iboDetails.askPrice;
  //         }
  //         if(resErr != '') {

  //         }
  //       }
  //     )
  //   }
  // }

  getMemberImg(img) {
    return (img == '') ? '/assets/images/no_member_image.png' : img;
  }
  buyIBOShares() {
    if(this.isAuthenticated) {
      this.allowPurchaseShares = true;
    } else {
      this._route.navigate(['login']);
    }
  }
  showLoader:boolean = false;
  iboPurchaseFormSubmit() {
    this.formSubmitted = true;
    if(this.iboPurchaseForm.valid) {
      this.showLoader = true;
      let formvalue = this.iboPurchaseForm.value;
      let data = {
          idCompanyStock:this.iboDetails.idCompanyStock,
          size:formvalue.shareSize
      }
      this._req.fetchApiData(this._urls.iBOBuyorder,data,false).subscribe(
          (data:any) => {
              let resSucc = data.data;
              let resError = data.error;
              if(resSucc != "") {
                this.purchaseMessage = 'Share Purchase Completed Successfully';
                this.purchaseStatus = true;
                this.btnText = 'Purchase Completed';
                setTimeout(()=>{
                  this._route.navigate(['ibo']);
                },2000);
              }
              if(resError!="") {
                this.purchaseMessage = resError['Error Description'];
                this.purchaseStatus = false;
                this._so.handleSessionOutErr(resError);
              }
          },
          error => {

          },
          () => {
            this.showLoader = false;
          }
      );
    }
  }

  sharePercent() {
    let percent = this.iboPurchaseForm.controls['balancePercent'].value;
    let remainingShares:any = this.iboDetails.remainingSize;
    let sharePrice:any = this.iboDetails.askPrice;
    let comissionFee:any = this.commissionFee;
    let availableBalance:any = 2018.645000 //this.primAcDetails.availableAmount;
    let shareNum:any = '';
    let perCentPrice:any = (percent/100) * availableBalance;
    if(perCentPrice > 0) {
      //perCentPrice = ( this.commissionFee/100 ) + perCentPrice;
      perCentPrice = (perCentPrice + ((this.iboDiscount/100) * perCentPrice));
      perCentPrice =  perCentPrice - ( this.commissionFee/100 );
      shareNum = Math.floor((perCentPrice/sharePrice));
      this.iboPurchaseForm.controls['shareSize'].setValue(shareNum);
      this.getSummary('percent');
    } else {
      this.purchaseMessage = "You have Zero Balance in your wallet";
    }

  }
  iboDiscount:any = '';
  askPrice:any = '';
  totalAmount:any = '';
  commissionFee:any = 1;
  getSummary(type = '') {
    if(type == '') this.iboPurchaseForm.controls['balancePercent'].setValue('');
    let shareSize = this.iboPurchaseForm.controls['shareSize'].value
    let price;
    let feeComission;
    let discountedAmount;
    if(shareSize != '') {
        price = shareSize * this.askPrice;
        discountedAmount = ((100-this.iboDiscount)/100)*price;
        feeComission = (this.commissionFee/100)*discountedAmount;
        this.totalAmount = discountedAmount + feeComission;
        this.totalAmount = Math.round(this.totalAmount * 100) / 100;
    } else {
        this.totalAmount = '-';
    }
  }

  primAcDetails:any = [];
  getPrimDetails() {
    let data = {};
    this._req.fetchApiData(this._urls.getPrimaryActUrl,data,false).subscribe(
      (data:any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if(resSucc != '') {
          this.iboPurchaseForm.controls['availableAmt'].setValue(resSucc[0].availableAmount);
          this.primAcDetails = resSucc[0];
        }
      }
    )
  }
  @Input() paramIdHdr:any;
  getMarketRoute(route) {
    let url = [];
    if(this.stockList.length > 0) {
      setTimeout(()=>{
        if(typeof this.paramIdHdr == 'undefined')
        this.paramIdHdr = this.stockList[0].idCompanyStock;
        url = [route,this.paramIdHdr];
        this._ss.setLocalItem('latestView',url[0]);
        this._route.navigate(url);
        this.menuToggle(false);
      },400);
    }
  }
  menuStatus:boolean = false;
  menuToggle(bool) {
    this.menuStatus = bool;
  }
  openBuyOrder() {
    this.showLoader = true;
    this.getUserAccountDetails();
  }
  openBuyPopup()
  {
    this.showLoader=false;
    const dialogRef = this._dialog.open(BuyOrderComponent, {
      width: '450px',
      data: {
        stockInfo: this.stockInfo,
        wltBalUSD: this.availableAmt
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {

      }
    });
  }
  copyAddress(ele) {
    ele.select();
    document.execCommand("copy");
    this._sb.openSnackBar('Wallet Address Copied to Clipboard',2000,'hybse_success_snackbar','right','top');
  }
  iboLogoData:any = [];
  iboFinanceData:any = [];
  getIBOCompDetails(id) {
    this.showLoader = true;
    if(typeof id != 'undefined') {
      let data = {
        idCompanyStock: id
      }
      this._req.fetchApiData(this._urls.iBODetailedListUrl,data).subscribe(
        (data:any) => {
          let resSucc = data.data;
          let resErr = data.error;
          if(resSucc != '') {
          this.stockInfo = resSucc[0];
          this.stockInfo.stockName = this.stockInfo.currency;
          // for (prop in arr){
          //   if (arr[prop] === "banana"){
          //      //add to new array
          //   }
          // }
          // this._utils.filteredObj(this.stockInfo.documents)
          this.iboLogoData = this.splitObjOnKey(this.stockInfo.documents, 'logo')
          this.iboFinanceData = this.splitObjOnKey(this.stockInfo.documents, 'Finance')
          this.showLoader = false;
          }
          if(resErr != '') {
            this.showLoader = false;
          }
        }
      )
    }
  }
  splitObjOnKey(arr:any[], key) {
    return arr.filter(el => {
      let objKey = Object.keys(el)[0];
      return objKey == key;
    })
  }


 availableAmt:any;
  getUserAccountDetails() {
    this._accDetail.getAccountDetailsSch().subscribe(
      (data:any) => {
        let resSuss = data.data;
        let resErr = data.error;
        if(resSuss != '') {
          this.availableAmt = resSuss[0].availableAmount;
          this.openBuyPopup();
          //this.getWltBal(resSuss);
        }
        if(resErr != '') {

        }
      }
    )
  }
  getWltBal(data) {
    this.wltBalUSD = 0; this.wltBalBTC = 0;
    data.forEach((ele) => {
      this.wltBalUSD = this.wltBalUSD + ele.totalinUSD;
      this.wltBalBTC = this.wltBalBTC + ele.totalinBTC;
    });
    this.openBuyPopup();
  }
  confirmBuyOrderLogin() {
    const dialogRef = this._dialog.open(ConfirmLoginComponent, {
      width: '460px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {

      }
    });
  }

  ngOnInit() {
    this.isAuthenticated = this._ls.getAuthenticated();
    this.getUserDetails()
    this.iboPurchaseFormInit();
    this.isMobile = this._utils.checkMobileDevice();
    this._ac.params.subscribe(
      data => {
        if(data.id) {
          this.idCompanyStock = data.id;
          this.getIBOCompDetails(data.id);
         // this.getStockInfo();
        }
      }
    )
  }

}
