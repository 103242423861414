import {Injectable} from '@angular/core';
import {ApirequestService} from '../apirequest/apirequest.service';
import {Subject, BehaviorSubject, forkJoin} from 'rxjs';
import {SessionOutService} from '../sessionOut/session-out.service';
import {ApiUrlService} from '../api-url/api-url.service';
import {DatumService} from '../datum/datum.service';
import {LoginService} from '../login/login.service';

@Injectable({
    providedIn: 'root'
})
export class MarketsService {

    userData: any = '';
    isAuthenticated: boolean = false;
    stockList: Subject<any> = new BehaviorSubject(null);
    stockInfo: Subject<any> = new BehaviorSubject(null);
    stockInformation: Subject<any> = new BehaviorSubject(null);
    stockForkData: Subject<any> = new BehaviorSubject(null);
    newsListsRes: Subject<any> = new BehaviorSubject(null);

    constructor(private _req: ApirequestService,
                private _so: SessionOutService,
                private _datum: DatumService,
                private _url: ApiUrlService) {
        this.getUserDetails();
    }

    getUserDetails() {
        this.userData = this._datum.getUserLoginData();
        if (Object.keys(this.userData).length === 0) {
            this.userData = '';
        }
    }

    getStockList() {
        let data = {};
        this.userData = this._datum.getUserLoginData();
        if (this.userData !== '') {
            data = {idUser: this.userData.idUser};
        }
        this._req.fetchApiData(this._url.stockListUrl, data, true).subscribe(
            (data: any) => {
                const resSucc = data.data;
                const resErr = data.error;
                if (resSucc !== '') {
                    this.stockList.next(resSucc);
                } else {
                    this.stockList.next([]);
                }

                if (resErr !== '') {
                    this.stockList.next('error');
                    this._so.handleSessionOutErr(resErr);
                }
            }
        );
    }

    getNewsList() {
        let datum = {data: [], error: false};
        let dataP = {};
        this._req.fetchApiData(this._url.newsList, {dataP}, true, 'get').subscribe(
            (data: any) => {
                const resSucc = data.data;
                const resErr = data.error;
                if (resSucc !== '') {
                    datum.data = resSucc;
                }
                if (resErr !== '') {
                    datum.error = resErr;
                }
                this.newsListsRes.next(datum);
            },
            (error) => {
            },
            () => {
            }
        );
    }

    getRoiCalculated(stock, type = 'sign') {
        let currentPrice = stock.priceCurrent,
            initialPrice = stock.initialValue,
            percentage, percentSign;
        if (currentPrice !== 0 && initialPrice !== 0) {
            percentage = ((currentPrice - initialPrice) / initialPrice) * 100;
            percentage = Math.round(percentage);
            percentSign = Math.sign(percentage);
        }

        if (percentage > 0) {
            return percentage > 0 ? '+' + percentage : percentage;
        }
        return (type === 'sign') ? percentSign : percentage;
    }

    getStockInfomation(id) {
        const data = {
            idCompanyStock: id
        };
        this._req.fetchApiData(this._url.stockInfoUrl, data, true).subscribe(
            (data: any) => {
                const resSucc = data.data;
                const resErr = data.error;
                const nxtData: any = {};
                if (resSucc !== '') {
                    nxtData.status = true;
                    nxtData.result = resSucc[0];
                    this.stockInformation.next(nxtData);
                }
            }
        );
    }

    getStockInfo(id) {
        const data = {
            idCompanyStock: id
        };

        this._req.fetchApiData(this._url.stockInfoUrl, data, true).subscribe(
            (data: any) => {
                const resSucc = data.data;
                const resErr = data.error;
                const nxtData: any = {};
                if (resSucc !== '') {
                    nxtData.status = true;
                    nxtData.result = resSucc[0];
                    this.stockInfo.next(nxtData);
                    this.stockForkJoin(resSucc[0]);
                }
                if (resErr !== '') {
                    nxtData.status = false;
                    nxtData.result = resErr;
                    this.stockInfo.next(nxtData);
                    this._so.handleSessionOutErr(resErr);
                }
            }
        );
    }

    stockForkJoin(res) {
        const data = {
            idCompanyStock: res.idCompanyStock,
            idCompany: res.idCompany
        };
        if (this.userData !== '') {
            const orderBook = this._req.fetchApiData(this._url.orderBookUrl, data, true);
            const userAcDetails = this._req.fetchApiData(this._url.userAccountDetailsUrl, {}, false);
            const thData = {...data, type: 'all'};
            const tradingHistory = this._req.fetchApiData(this._url.tradeHistoryUrl, thData, false);
            const thMData = {...thData};
            thMData.type = 'market';
            const marketTradingHistory = this._req.fetchApiData(this._url.tradeHistoryUrl, thMData, false);
            const matchedOrders = this._req.fetchApiData(this._url.matchedordersUrl, {
                idCompanyStock: res.idCompanyStock
            }, false);
            this._req.forkJoinReq(userAcDetails, orderBook, tradingHistory, marketTradingHistory, matchedOrders).subscribe(
                data => {
                    const nxtData: any = {};
                    if (data.length !== 0) {
                        nxtData.status = true;
                        nxtData.result = data;
                    } else {
                        nxtData.status = false;
                        nxtData.result = data;
                    }
                    this.stockForkData.next(nxtData);
                },
                error => {

                },
                () => {

                }
            );
        } else {
            const orderBook = this._req.fetchApiData(this._url.orderBookUrl, data, true);
            this._req.forkJoinReq(orderBook).subscribe(
                data => {
                    const nxtData: any = {};
                    if (data.length !== 0) {
                        nxtData.status = true;
                        nxtData.result = data;
                    } else {
                        nxtData.status = false;
                        nxtData.result = data;
                    }
                    this.stockForkData.next(nxtData);
                },
                error => {

                },
                () => {

                }
            );
        }

    }

    getProfileLoss(stock, type = 'sign') {
        let currentPrice = stock.priceCurrent,
            highPrice = stock.priceThirty != 0 ? stock.priceThirty : stock.priceCurrent,
            percentage, percentSign;
        if (currentPrice !== 0 && highPrice !== 0) {
            percentage = ((currentPrice - highPrice) / highPrice) * 100;
            if (percentage !== 0) {
                percentage = percentage.toFixed(2);
            }
            percentSign = Math.sign(percentage);
        }
        if (percentage > 0) {
            return percentage > 0 ? '+' + percentage : percentage;
        }
        return (type === 'sign') ? percentSign : percentage;
    }


    // getProfileLoss(stock, type = 'sign') {
    //   let currentPrice = stock.priceCurrent,
    //     highPrice = stock.priceHigh != 0 ? stock.priceHigh : stock.priceClose;
    //     percentage, percentSign;
    //   if (currentPrice !== 0 && highPrice !== 0) {
    //     percentage = ((highPrice - currentPrice ) / currentPrice) * 100;
    //     percentage = Math.round(percentage);
    //     percentSign = Math.sign(percentage);
    //   }
    //   if (percentage > 0) {
    //     return percentage > 0 ? "+" + percentage : percentage;
    //   }
    //   return (type === 'sign') ? percentSign : percentage;
    // }

}
