import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ApiUtilsService } from '../../../services/apiUtils/api-utils.service';
import { UtilityService } from '../../../services/utilities/utility.service';
import { MatTableDataSource, MatSort, MatDialog } from '@angular/material';
import { DatumService } from 'src/app/services/datum/datum.service';
import { MarketsService } from 'src/app/services/markets/markets.service';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/localstorage/storage.service';
import { ConfirmLoginComponent } from '../../widgets/confirm-login/confirm-login.component';
import { LoginService } from 'src/app/services/login/login.service';
import { ApiUrlService } from 'src/app/services/api-url/api-url.service';
import { ApirequestService } from 'src/app/services/apirequest/apirequest.service';
import { BuyOrderComponent } from '../../widgets/buy-order/buy-order.component';
import { AccountDetailsService } from 'src/app/services/accountDetails/account-details.service';
import { SessionOutService } from 'src/app/services/sessionOut/session-out.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'batp-ibo',
  templateUrl: './ibo.component.html',
  styleUrls: ['./ibo.component.css'],
  animations: [
    trigger('detailExpand', [
      state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('*', style({ height: '*', visibility: 'visible' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class IboComponent implements OnInit {

  constructor(private _req:ApirequestService,
    private _urls:ApiUrlService,
    private _apiUtils:ApiUtilsService,
              private _utils:UtilityService,
              private _datum: DatumService,
              private _market: MarketsService,
              private _route: Router,
              private _ls: StorageService,
              private _dialog:MatDialog,
              private _lo:LoginService,
              private _accDetail:AccountDetailsService,
              private _so: SessionOutService) { }

  isMobile: boolean = this._utils.checkMobileDevice();
  stockListLoginColumns: string[] = ['rank','favourite','name','marketcap' ,'ibin','price','volume','cutrate','ibo'];
  // stockListColumns: string[] = ['rank','favourite','name','marketcap' ,'ibin','price','listingdate','status','ibo'];
  stockListColumns: string[] = ['serial','rank','companyName','view'];
  stockList:any = [];
  idCompanyStock:number;
  stockInfo: any = [];
  actDetailSubs: any;
  stockListSubs: any;
  stockListClone: any = [];
  toggle = {};
  accountDetails: any = {
    primary: [],
    secondary: []
  };
  primaryData: any = [];
  secondaryData: any = [];
  wltBalUSD: any = 0;
  wltBalBTC: any = 0;
  @ViewChild('PrimarySort')
  PrimarySort: MatSort;
  @ViewChild('SecondarySort')
  SecondarySort: MatSort;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("favoriteStockSort") favoriteStockSort: MatSort;
  isAuthenticated:boolean = false;
  iboDiscRange:any = [{
    start: 0,
    end:25,
    checked: false
  },
  {
    start: 25,
    end:50,
    checked: false
  },
  {
    start: 50,
    end:75,
    checked: false
  },
  {
    start: 75,
    end:100,
    checked: false
  }];
  scrollToList() {
    let listSec = document.getElementById('table_list_sec').offsetTop;
    this._utils.scrollToY(listSec,400, 'easeOutSine')
  }

  currencyLists:any;
  getCurrencyList(data) {
    let currency = [];
    this.currencyLists = [];
    data.forEach((ele)=>{
      if( currency.indexOf(ele.currencyPrim) == -1 ) currency.push(ele.currencyPrim);
    });
    currency.forEach((ele)=>{
      this.currencyLists.push({
        'currency':ele,
        'checked': false
      });
    });
  }
  @Input() paramIdHdr:any;
  getIBORoute(route,id) {
    let url = [];
    if(this.stockList.length > 0) {
      setTimeout(()=>{
        if(typeof this.paramIdHdr == 'undefined')
        this.paramIdHdr = this.stockList[0].idCompanyStock;
        url = [route,id];
        this._ls.setLocalItem('latestView',url[0]);
        this._route.navigate(url);
        this.menuToggle(false);
      },400);
    }
  }
  getStockList() {
    let data = {};
    if(this.userData != '') data = { idUser: this.userData.idUser };
    this._req.fetchApiData(this._urls.stockListUrl,data,true).subscribe(
      (data:any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if(resSucc != '') {
          this.stockList = resSucc;
        }
      }
    )
  }
  menuStatus:boolean = false;
  menuToggle(bool) {
    this.menuStatus = bool;
  }
  iboLists:any = '';
  iboListsClone:any = [];
  favoriteStock:any = [];
  iboListSubs: any;
  showLoader:boolean = false;
  getIBOList() {
    this._apiUtils.getIBOList();
    this._apiUtils.iboListsPageRes.subscribe(
      (data:any) => {
        if(data.data.hasOwnProperty('iboList')) {
          this.iboLists = []; this.iboListsClone = [];
            this.iboLists = data.data.iboList;
            this.iboListsClone = new MatTableDataSource<PeriodicElement>(this.iboLists);
            this.iboListsClone.sort = this.sort;
            this.getFavorites(this.iboLists);
        }
        if(data.error) {
        }
      }
    )
  }
  getFavorites(data) {
    let favoriteStock = this._utils.filteredArray(data, 'isFavourite', true);
    this.favoriteStock = new MatTableDataSource<PeriodicElement>(favoriteStock);
    this.favoriteStock.sort = this.favoriteStockSort;
  }
  addPropForIBO(data) {
    data.forEach(ele => {
      ele.checked = false;
      ele.discount = ele.lockupStock[0].discount;
    });
  }
  filterByCompany(i,evt) {
    this.iboLists[i].checked = !this.iboLists[i].checked;
  }

  filterDiscount(i,e) {
    this.iboDiscRange[i].checked = !this.iboDiscRange[i].checked;
  }

  filterByCurrency(i,e) {
    this.currencyLists[i].checked = !this.currencyLists[i].checked;
  }

  companyFilteration() {
    this.iboListsClone = this._utils.filteredArray(this.iboLists,'checked',true);
    if(this.iboListsClone.length == 0) this.iboListsClone = this.iboLists;
    this.discountFilteration();
    this.currencyFilteration();
  }
  discountFilteration() {
    this.iboDiscRange.forEach((ele)=>{
      if(ele.checked) this.findDiscount(ele.start, ele.end);
    });
  }
  findDiscount( start, end ) {
    this.iboListsClone = this.iboListsClone.filter( item => {
      return ( item.discount >= start && item.discount <= end );
    });
  }

  currencyFilteration() {
    this.currencyLists.forEach((ele)=>{
      if(ele.checked) this.findCurrency(ele.currency);
    });
  }
  findCurrency(currency) {
    this.iboListsClone = this.iboListsClone.filter( item => {
      return ( item.currencyPrim == currency );
    });
  }

  clearFilteration() {
    this.resetCompanyFilter();
    this.resetDiscountFilter();
    this.resetCurrencyFilter();
    this.showAllCompany()
  }
  resetCompanyFilter() {
    this.iboLists = this._utils.changeArrProp(this.iboLists,'checked',false);
  }
  resetDiscountFilter() {
    this.iboDiscRange = this._utils.changeArrProp(this.iboDiscRange,'checked',false);
  }
  resetCurrencyFilter() {
    this.currencyLists = this._utils.changeArrProp(this.currencyLists,'checked',false);
  }
  showAllCompany() {
    this.iboListsClone = this.iboLists;
  }

  searchMarket(filterValue) {
    this.iboListsClone.filter = filterValue.trim().toLowerCase();
  }

  getChange(stock, type) {
    return this._market.getProfileLoss(stock, type);
  }

  getFlagUrl(ibin: string) {
    let flag = ibin.substr(0, 2).toLowerCase();
    let flagUrl = `assets/images/flags/${flag}.svg`;
    return flagUrl;
  }

  getProfileLoss(stock, type = 'sign') {
    return this._market.getProfileLoss(stock, type);
  }

  userData: any = '';
  getUserData() {
    this.userData = this._datum.getUserLoginData();
    if (Object.keys(this.userData).length == 0) this.userData = '';
  }
  toggleFavorites(stock, ind) {
    let url = stock.isFavourite ? this._urls.deleteCompanyFavouriteUrl : this._urls.createCompanyFavouriteUrl;
    let data = {
      idCompanyStock: stock.idCompanyStock
    }
    let favToggle = !stock.isFavourite;
    stock.isFavourite = !stock.isFavourite;
    this._req.fetchApiData(url, data , false ).subscribe(
      (data: any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if (resSucc != '') {
          stock.isFavourite = favToggle;
          //stock.isFavourite = !stock.isFavourite;
          this.getIBOList();
        }
        if (resErr) {
          this._so.handleSessionOutErr(resErr);
        }
      }
    );
  }
  confirmBuyOrderLogin() {
    const dialogRef = this._dialog.open(ConfirmLoginComponent, {
      width: '460px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {

      }
    });
  }
  openBuyOrder(id) {
    this.showLoader = true;
    this.idCompanyStock = parseInt(id);
    this.getIBOCompDetails(this.idCompanyStock)
  }

  openBuyOrderModal() {
    this.showLoader = false;
    const dialogRef = this._dialog.open(BuyOrderComponent, {
      width: '450px',
      data: {
        stockInfo: this.stockInfo,
        wltBalUSD: this.availableAmt,
        idCompanyStock: this.idCompanyStock
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {

      }
    });
  }

  getIBOCompDetails(id) {
    if(typeof id != 'undefined') {
      let data = {
        idCompanyStock: id
      }
      this._req.fetchApiData(this._urls.iBODetailedListUrl,data).subscribe(
        (data:any) => {
          let resSucc = data.data;
          let resErr = data.error;
          if(resSucc != '') {
          this.stockInfo = resSucc[0];
          this.stockInfo.stockName = this.stockInfo.currency;
          this.getUserAccountDetails();
          }
          if(resErr != '') {
          }
        }
      )
    }
  }
  availableAmt:any ;
  getUserAccountDetails() {
    this._accDetail.getAccountDetailsSch().subscribe(
      (data:any) => {
        let resSuss = data.data;
        let resErr = data.error;
        if(resSuss != '') {
          this.availableAmt = resSuss[0].availableAmount;
          this.openBuyOrderModal();
        }
        if(resErr != '') {

        }
      }
    )
  }
  // getWltBal(data) {
  //   this.wltBalUSD = 0; this.wltBalBTC = 0;
  //   data.forEach((ele) => {
  //     this.wltBalUSD = this.wltBalUSD + ele.totalinUSD;
  //     this.wltBalBTC = this.wltBalBTC + ele.totalinBTC;
  //   });
  // }
  ngOnInit() {
    this.getUserData();
    this.getIBOList();
    this.getStockList();
    this.isAuthenticated = this._lo.getAuthenticated();
  }
  ngOnDestroy() {
    if (typeof this.iboListSubs != 'undefined') this.iboListSubs.unsubscribe();
  }
}

export interface PeriodicElement {
  // companyName: string;
  // ibin: string;
  // idCompany: number;
  // idCompanyStock: number;
  // idCompanyStockPrime: number;
  // isFavourite: boolean;
  // issuedStock: number;
  // priceClose: string;
  // priceCurrent: string;
  // priceHigh: number;
  // priceLow: number;
  // priceOpen: string;
  // primaryCurrency: string;
  // primarySourceCurr: string;
  // primaryTargetCurr: string;
  // size: string;
  // sourceCurr: string;
  // stockName: string;
  // targetCurr: string;
  // type: string;
  // volume: string;
  // show: boolean;

askPrice: string;
askSize: string;
companyName: string;
createdAt: string;
currencyPrim: string;
ibin: string;
idCompany: string;
idCompanyStock: string;
idIBOSellorder: string;
idUser: number;
isFavourite: boolean;
isIBO: boolean;
issuedStock: number;
lockupDate: string;

discount: string;
expiry: string;
idLockupStock: string;
pairSource: string;
pairTarget: string;
primarySourceCurr: string;
primaryTargetCurr: string;
remainingSize: string;
sourceCurr: string;
targetCurr: string;
type: string;
show: boolean;
}
