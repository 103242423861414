import { Component, OnInit } from '@angular/core';
import {ApiUrlService} from '../../../../../services/api-url/api-url.service';
import {ApirequestService} from '../../../../../services/apirequest/apirequest.service';
import {SnackbarService} from '../../../../../services/snackbar/snackbar.service';
import {DatumService} from '../../../../../services/datum/datum.service';
import {Router} from '@angular/router';

@Component({
  selector: 'batp-gold',
  templateUrl: './gold.component.html',
  styleUrls: ['./gold.component.css']
})
export class GoldComponent implements OnInit {

    private userDetails;
    public qgenToken: string;
    public qgenSession: string;
    public kycRefId: string;

  constructor(
      private _url: ApiUrlService,
      private _req: ApirequestService,
      private _sb: SnackbarService,
      private _router:Router,
      private _datum: DatumService
  ) { }

  ngOnInit() {
      this.userDetails = this._datum.getUserDetails();
      this.processQgenSteps();
  }

    processQgenSteps() {
        const data = {
            clientID: '6766536335b48e1e90dea2ac',
            clientSecret: 'vTRU<Xp736~n8@95NrsMC%df-1[/c$0g'
        };

        this._req.fetchApiData(this._url.qgenLoginUrl, data, true).subscribe((response: any) => {
            console.log(response);
            if (response) {
                this.qgenToken = response.data.accessToken;

                this._req.fetchApiData(this._url.checkKycSessionUrl, data, false).subscribe((res2: any) => {
                    console.log(this.userDetails);
                    if (!res2.data) {
                        this.kycRefId = this.userDetails.username + '_' + this.getUnixTimestamp();
                        const initSessionData = {
                            caseTypeId: '666bfca9a711ea181255fcc8',
                            referenceId: this.kycRefId,
                            email: this.userDetails.email,
                            firstName: this.userDetails.firstName,
                            lastName: this.userDetails.lastName,
                        };
                        this._req.getQRCode(this._url.qgenInitCaseUrl, initSessionData, this.qgenToken).subscribe((caseRes: any) => {
                            console.log(caseRes);
                            this.qgenSession = caseRes.data.sessionId;

                            const kycSessionData = {
                                caseId: caseRes.data.caseId,
                                referenceId: this.kycRefId,
                                sessionId: caseRes.data.sessionId
                            };

                            this._req.fetchApiData(this._url.initKycSessionUrl, kycSessionData, false).subscribe((hbkycres) => {
                                console.log(hbkycres);
                                this.initQgenSession(this.qgenSession);
                            });
                        });
                    } else {
                        const contSessionPost = {
                            sessionId: res2.data,
                            withMessage: true
                        };
                        this.qgenSession = res2.data;
                        this._req.getQRCode(this._url.qgenContinueSessionUrl, contSessionPost, this.qgenToken).subscribe((sessRes: any) => {
                            console.log(sessRes);
                            this.qgenSession = sessRes.data.sessionId;
                            const updateData = {
                                sessionId: sessRes.data.sessionId
                            };

                            this._req.fetchApiData(this._url.updateKycSessionUrl, updateData, false).subscribe((sessUpdRes: any) => {
                                console.log(sessUpdRes);
                                this.initQgenSession(this.qgenSession);
                            });
                        }, (error) => {
                            this.initQgenSession(this.qgenSession);
                        });
                    }
                });
            } else {
                this._sb.openSnackBar(
                    'QGen Login failed!',
                    3000,
                    'batp_error_snackbar',
                    'center',
                    'top'
                );
            }
        });
    }

    initQgenSession(sessId: string) {
        window.open('https://qgenonlinestaging.com/cfm/?session_id=' + sessId, '_blank');
        this._router.navigate(['profile/verification-level']);
    }

    getUnixTimestamp() {
        return Math.floor(Date.now() / 1000);
    }

}
