export const documentsList = [
  {
    docTitle: 'Custody statements of investments',
    docType: 'POFS1'
  },
  {
    docTitle: 'Certificate of real estate ownership',
    docType: 'POFS2'
  },
  {
    docTitle: 'Bank account statement',
    docType: 'POFS3'
  },
];
