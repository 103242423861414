import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material';
import { ApirequestService } from '../../../services/apirequest/apirequest.service';
import { MarketsService } from '../../../services/markets/markets.service';
import { Router } from '@angular/router';
import { DatumService } from 'src/app/services/datum/datum.service';
import { ApiUrlService } from 'src/app/services/api-url/api-url.service';
import { UtilityService } from 'src/app/services/utilities/utility.service';
import { LoginService } from 'src/app/services/login/login.service';
import { StorageService } from 'src/app/services/localstorage/storage.service';
import { SessionOutService } from 'src/app/services/sessionOut/session-out.service';
import { IbinService } from 'src/app/services/ibin/ibin.service';
import { _ibin_api_url } from 'src/app/config/hybse.config';
import { HttpClient } from '@angular/common/http';
import { DataSource } from '@angular/cdk/collections';
import { Observable, of } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'batp-markets',
  templateUrl: './markets.component.html',
  styleUrls: ['./markets.component.css'],
  animations: [
    trigger('detailExpand', [
      state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('*', style({ height: '*', visibility: 'visible' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MarketsComponent implements OnInit {



  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild(MatSort) sort: MatSort;

  isExpansionDetailRow = (index, row) => row.hasOwnProperty('detailRow');
  constructor(private _req: ApirequestService,
    private _market: MarketsService,
    private _route: Router,
    private _datum: DatumService,
    private _utils: UtilityService,
    private _http: HttpClient,
    private _lo: LoginService,
    private _ibin: IbinService,
    private _ls: StorageService,
    private _so: SessionOutService,
    private _url: ApiUrlService) { }

  isMobile: boolean = this._utils.checkMobileDevice();
  stockListColumns: string[] = ['serial', 'rank', 'companyName', 'view'];
  // stockListColumns: string[] = ['serial', 'companyName', 'ibin', 'stockName', 'priceCurrent', 'volume', 'Percentage', 'View'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("favoriteStockSort") favoriteStockSort: MatSort;
  @ViewChild("blockshareStockSort") blockshareStockSort: MatSort;
  @ViewChild("commodityStockSort") commodityStockSort: MatSort;
  @ViewChild("currencyStockSort") currencyStockSort: MatSort;

  stockList: any = [];
  stockListClone: any = [];
  stockListSubs: any;
  isAuthenticated: boolean = false;
  toggle = {};
  getStockLists() {
    this._market.getStockList();
    this.stockListSubs = this._market.stockList.subscribe(
      (data: any) => {
        if (data != null) {
          if (data != 'error') {
            this.stockList = []; this.stockListClone = [];
            this.stockList = data;
            this.stockListClone = new MatTableDataSource<PeriodicElement>(data);
            this.stockListClone.sort = this.sort;

            //this.stockListClone.sort.active = 'volume';
            this.getFavorites(data);
            this.getBlockShares(data);
            this.getCommodities(data);
            this.getCurrencies(data);
          } else{
            this.stockList=[];
          }
        }
      }
    );
    // this._req.fetchApiData(this._url.stockListUrl,{},false).subscribe(
    //   (data:any) => {
    //     let resSucc = data.data;
    //     let resErr = data.error;
    //     if(resSucc != '') {
    //       this.stockList = []; this.stockListClone = [];
    //       this.stockList = resSucc;
    //       this.stockListClone = new MatTableDataSource(resSucc);
    //       this.stockListClone.sort = this.sort;
    //       //this.stockListClone.sort.active = 'volume';
    //       this.getFavorites(resSucc);
    //     }

    //     if(resErr != '') {
    //       this.stockList.next('error');
    //     }
    //   }
    // )
  }
  favoriteStock: any = [];
  getFavorites(data) {
    let favoriteStock = this._utils.filteredArray(data, 'isFavourite', true);
    this.favoriteStock = new MatTableDataSource<PeriodicElement>(favoriteStock);
    this.favoriteStock.sort = this.favoriteStockSort;
  }
  blockshareStock: any = [];
  getBlockShares(data) {
    let blockshareStock = this._utils.filteredArray(data, 'type', 'Stock');
    this.blockshareStock = new MatTableDataSource(blockshareStock);
    this.blockshareStock.sort = this.blockshareStockSort;
  }
  commodityStock: any = [];
  getCommodities(data) {
    let commodityStock = this._utils.filteredArray(data, 'type', 'Commodity');
    this.commodityStock = new MatTableDataSource(commodityStock);
    this.commodityStock.sort = this.commodityStockSort;
  }
  currencyStock: any = [];
  getCurrencies(data) {
    let currencyStock = this._utils.filteredArray(data, 'type', 'Currencies');
    this.currencyStock = new MatTableDataSource(currencyStock);
    this.currencyStock.sort = this.currencyStockSort;
  }
  searchMarket(filterValue) {
    this.stockListClone.filter = filterValue.trim().toLowerCase();
  }

  getChange(stock, type) {
    return this._market.getProfileLoss(stock, type);
  }

  getFlagUrl(ibin: string) {
    let flag = ibin.substr(0, 2).toLowerCase();
    let flagUrl = `assets/images/flags/${flag}.svg`;
    return flagUrl;
  }

    formatURL(stockData: any) {
        let url = '';
        if (stockData.website.length < 3) {
            url = 'https://ibin.global/detail/' + stockData.ibin;
        } else {
            if (!/^(?:f|ht)tps?\:\/\//.test(stockData.website)) {
                url = 'https://' + stockData.website;
            } else {
                url = stockData.website;
            }
        }

        return url;
    }


    getRoiChange(stock, type) {
    return this._market.getRoiCalculated(stock, type);
  }

  getCalculatedRoi(stock, type = 'sign') {
    return this._market.getRoiCalculated(stock, type);
  }

  getProfileLoss(stock, type = 'sign') {
    return this._market.getProfileLoss(stock, type);
  }

  navToMarketInfo(stock) {
    // let marketInfo = this.isMobile ? 'market-info-mobile' : 'market-info';
    this._route.navigate(['market-details', stock.idCompany, stock.idCompanyStock]);
  }
  navToMarketTrade(stock, idCompanyStock) {
    //  let latestView = this._ls.getLocalItems('latestView');
    let marketInfo = '';
    // if (latestView) {
    //  marketInfo = this.isMobile ? 'market-info-mobile' : latestView;
    // } else {
    marketInfo = this.isMobile ? 'market-info-mobile' : 'market-info-pro';
    // }
    this._route.navigate([marketInfo, idCompanyStock]);
  }
  userData: any = '';
  getUserData() {
    this.userData = this._datum.getUserLoginData();
    if (Object.keys(this.userData).length == 0) this.userData = '';
  }

  toggleFavorites(stock, ind) {

    let url = stock.isFavourite ? this._url.deleteCompanyFavouriteUrl : this._url.createCompanyFavouriteUrl;
    let data = {
      idCompanyStock: stock.idCompanyStock
    }
    let favToggle = !stock.isFavourite;
    stock.isFavourite = !stock.isFavourite;
    this._req.fetchApiData(url, data, false).subscribe(
      (data: any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if (resSucc != '') {
          stock.isFavourite = favToggle;
          //stock.isFavourite = !stock.isFavourite;
          this.getStockLists();
        }
        if (resErr) {
          this._so.handleSessionOutErr(resErr);
        }
      }
    );
  }

  getLoginStatus() {
    this.getStockLists();
  }

  preferredTheme: boolean = true;

  getPreferredTheme() {
    if (this.isAuthenticated) {
      const preferredTheme: any = this._ls.getLocalItems('preferredTheme');
      this._lo.preferredTheme.subscribe(
        data => {
          if (data != null) {
            this.preferredTheme = data;
          } else {
            this.preferredTheme = preferredTheme == 'true' ? true : false;
          }
        }
      );
    }
  }
  getIbinLink(ibin) {
    // return `https://ibin.io/company_profile/${ibin}`;
    let url = `${_ibin_api_url}ibin/${ibin}`;
    this._http.get(url).subscribe(
      data => {
        console.log(data);
      }
    )

  }

  applyFilter(filterValue: string) {
    this.stockListClone.filter = filterValue.trim().toLowerCase();

    if (this.stockListClone.paginator) {
      this.stockListClone.paginator.firstPage();
    }
  }

  ngOnInit() {
    this.isAuthenticated = this._lo.getAuthenticated();
    this.getLoginStatus();
    this.getUserData();
    this.getPreferredTheme();
    // this.getIbinLink('dfsdfsfdsf');
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }
  ngOnDestroy() {
    if (typeof this.stockListSubs != 'undefined') this.stockListSubs.unsubscribe();
  }

  navMarketTrade(stock) {

    let marketInfo = 'market-info-pro';

    this._route.navigate([marketInfo, stock.idCompanyStock]);
  }

  navMarketBesicTrade(stock) {
    let marketInfo = 'market-info-novice';
    this._route.navigate([marketInfo, stock.idCompanyStock]);
  }
}

export interface PeriodicElement {
  companyName: string;
  ibin: string;
  idCompany: number;
  idCompanyStock: number;
  idCompanyStockPrime: number;
  isFavourite: boolean;
  issuedStock: number;
  priceClose: string;
  priceCurrent: string;
  priceHigh: number;
  priceLow: number;
  priceOpen: string;
  primaryCurrency: string;
  primarySourceCurr: string;
  primaryTargetCurr: string;
  size: string;
  sourceCurr: string;
  stockName: string;
  targetCurr: string;
  type: string;
  volume: string;
  show: boolean;
}
