import { Component, OnInit } from '@angular/core';
import { trigger, transition, state, style, animate } from '@angular/animations';
import { AccountDetailsService } from '../../../../services/accountDetails/account-details.service';
import { UtilityService } from '../../../../services/utilities/utility.service';
import { Sort } from '@angular/material';

@Component({
  selector: 'batp-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css'],
  animations: [
    trigger('FadeAnimation', [
      state('in', style({
        opacity: 1
      })),
      transition(':enter', [
        style({opacity: 0}),
        animate(600 )
      ])
    ])
  ]
})
export class HistoryComponent implements OnInit {

  constructor(private _accDetail:AccountDetailsService,
    private _utils:UtilityService) { }
  transxHistorySec:any = {
    depositTranx: true,
    withdrawTranx: false
  }

  toggleSection(prop) {
    switch (prop) {
      case 'depositTranx':
        this.transxHistorySec = { depositTranx: true, withdrawTranx: false }
        break;
      case 'withdrawTranx':
        this.transxHistorySec = { depositTranx: false, withdrawTranx: true }
        break;
      default:
        break;
    }
  }
  tranxHistory:any = {};
  depositTranx:any = [];
  withdrawTranx:any = [];
  dataLoaded = false;
  showLoader = true;
  getUserTranxHistory() {
    this._accDetail.getTranxHistory();
    this._accDetail.tranxHistory
    .subscribe( data => {
      if(data != 'error') {
        if(data.length != 0 ) {
          this.tranxHistory = data;
          this.showLoader = false;
          this.dataLoaded = true;
          if(this.tranxHistory.hasOwnProperty('Deposit')) this.depositTranx = this.tranxHistory.Deposit;
          if(this.tranxHistory.hasOwnProperty('Withdraw')) this.withdrawTranx = this.tranxHistory.Withdraw;
        }
      } else if( data == 'error' ) {
        this.dataLoaded = true;
        this.showLoader = false;
      }
    });
  }


  /* sorting Table */
  sortDepHistory(sort: Sort) {
    const data = this.depositTranx.slice();
    if (!sort.active || sort.direction === '') {
      this.depositTranx = data;
      return;
    }
    this.depositTranx = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'pair': return this._utils.compareSort(a.pair, b.pair, isAsc);
        case 'txnId': return this._utils.compareSort(a.txnId, b.txnId, isAsc);
        case 'createdAt': return this._utils.compareSort(a.createdAt, b.createdAt, isAsc);
        case 'quantity': return this._utils.compareSort(a.quantity, b.quantity, isAsc);
        case 'status': return this._utils.compareSort(a.status, b.status, isAsc);
        default: return 0;
      }
    });
  }

  sortWDHistory(sort: Sort) {
    const data = this.withdrawTranx.slice();
    if (!sort.active || sort.direction === '') {
      this.withdrawTranx = data;
      return;
    }
    this.withdrawTranx = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'pair': return this._utils.compareSort(a.pair, b.pair, isAsc);
        case 'txnId': return this._utils.compareSort(a.txnId, b.txnId, isAsc);
        case 'createdAt': return this._utils.compareSort(a.createdAt, b.createdAt, isAsc);
        case 'quantity': return this._utils.compareSort(a.quantity, b.quantity, isAsc);
        case 'status': return this._utils.compareSort(a.status, b.status, isAsc);
        default: return 0;
      }
    });
  }


  ngOnInit() {
    this.getUserTranxHistory();
  }

  ngOnDestroy() {

  }

}
