import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorMessages } from '../../../../shared/error-messages';
import { wizardErrorMessage, wizardMessages } from '../../../../shared/wizard-error-messages';
import { validateEmail, validPhoneNumber, validWebsite, validNumber } from '../../../../shared/form-validators';
import { countries, Countries } from '../../../../shared/data/countries';
import { FsUploadService } from '../../../../services/fs-upload/fs-upload.service';


@Component({
  selector: 'batp-wiz-company-information',
  templateUrl: './wiz-company-information.component.html',
  styleUrls: ['./wiz-company-information.component.css']
})
export class WizCompanyInformationComponent implements OnInit {

  constructor(private _fb:FormBuilder,private _fs:FsUploadService) { }
  @Input() goTo:any = '';
  @Input() companyInfo:any = '';
  @Output() companyInfoDatas:any = new EventEmitter();
  @Output() companyLogo:any = new EventEmitter();
  @Input() companyLogoDetails:any = null;
  companyInfoForm:FormGroup;
  companyIfoFormSubmitted:boolean = false;
  companyInfoDetail:any = '';
  errorMessage:wizardMessages = wizardErrorMessage;
  countriesList:Countries[] = countries;
  companyInfoFormInit() {
    this.companyInfoForm = this._fb.group({
      companyName: ['',[Validators.required]],
      website: ['',[Validators.required,validWebsite]],
      companyEmail: ['',[Validators.required,validateEmail]],
      country: ['',[Validators.required]],
      address1: ['',[Validators.required]],
      address2: ['',[]],
      zipcode: ['',[Validators.required,validNumber]],
      linkedin: ['',[validWebsite]],
      twitter: ['',[validWebsite]],
      facebook: ['',[validWebsite]],
      legalSeat:['',[Validators.required]]
    });
  }

  companyInfoSubmit() {
    this.companyIfoFormSubmitted = true;
    if(this.companyInfoForm.valid) {
      this.companyInfoDetail = this.companyInfoForm.value;
      let exportData:any = {
        formData: this.companyInfoDetail,
        goTo: 1
      }
      this.companyInfoDatas.emit(exportData);
    }
  }

  setFormValues() {
    setTimeout(()=>{
      if(typeof this.companyInfoForm != undefined) {
        this.companyInfoForm.patchValue(this.companyInfo);
      }
    },200);
  }

  uploadFile() {
      let fileAccepted = ["image/*"];
      let maxSize = 2097152;

      this._fs.uploadFiles(fileAccepted,maxSize).then((res)=>{
        if(res.filesUploaded.length > 0) this.fileUploadHandler(res)
      });

  }

  fileUploadHandler(res) {
    let filesFailed = res.filesFailed;
    let fileUploaded = res.filesUploaded;
    this.companyLogoDetails = [];
    if(fileUploaded.length > 0 ) {
      this.companyLogoDetails = [{
        docTitle: 'logo',
        docName: fileUploaded[0].url,
        docType: 1
      }];
      this.companyLogo.emit(this.companyLogoDetails);
    }
    if(filesFailed.length > 0 ) {

    }
  }

  removeLogo() {
    this.companyLogoDetails = null;
  }
  ngOnInit() {
    this.companyInfoFormInit();
  }

  ngOnChanges() {
    if(this.companyInfo != '') {
      this.setFormValues();
    }
  }

}
