import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RegisterService } from '../../../services/register/register.service';
import  { CryptoService } from '../../../services/crypto/crypto.service';
import { ErrorMessages } from '../../../shared/error-messages';
import { validatePassword, validateEmail, checkPassword } from '../../../shared/form-validators';
import { devEnv, captchaKey } from '../../../config/hybse.config';
import { messageBoxIcon, iconSet } from '../../../shared/shared.data';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, transition, state, style, animate } from '@angular/animations';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';


declare var $:any;
@Component({
  selector: 'batp-issuer-register',
  templateUrl: './issuer-register.component.html',
  styleUrls: ['./issuer-register.component.css'],
  animations: [
    trigger('FadeAnimation', [
      state('in', style({
        opacity: 1
      })),
      transition(':enter', [
        style({opacity: 0}),
        animate(600 )
      ]),
      transition(':leave',
        animate(600, style({opacity: 0})))
    ])
  ]
})
export class IssuerRegisterComponent implements OnInit {

  constructor(
      private _fb: FormBuilder,
      private _ar: ActivatedRoute,
      private _route: Router,
      private _register: RegisterService,
      private _sb: SnackbarService,
      private _cryp: CryptoService,
  ) { }
    wizardStepper:any = [
      {
        step: 1,
        active: true,
        completed: false,
        stepSection: true
      },
      {
        step: 2,
        active: false,
        completed: false,
        stepSection: false
      },
      {
        step: 3,
        active: false,
        completed: false,
        stepSection: false
      },
      {
        step: 4,
        active: false,
        completed: false,
        stepSection: false
      },
      {
        step: 5,
        active: false,
        completed: false,
        stepSection: false
      },
      {
        step: 6,
        active: false,
        completed: false,
        stepSection: false
      },
      {
        step: 7,
        active: false,
        completed: false,
        stepSection: false
      },
      {
        step: 8,
        active: false,
        completed: false,
        stepSection: false
      }
    ];
  issuerRegisterForm: FormGroup;
  issuerRegisterSubmit:boolean = false;
  registerSubmitted:boolean = false;
  showLoader:boolean = false;
  errorMessages:any = new ErrorMessages();
  captchaKey:string = captchaKey;
  registerMessage:string = '';
  registerStatus:string = '';
  messIcons:iconSet = messageBoxIcon;
  termsChecked:boolean = true;
    showPass = false;
    showCPass = false;
    passType = 'password';
    passCType = 'password';

  issuerLoginFormInit() {
    this.issuerRegisterForm = this._fb.group({
      // 'firstName': ['',[ Validators.required ]],
      // 'lastName': ['',[ Validators.required ]],
      'email': ['',[ Validators.required, validateEmail ]],
      'password': ['', [ Validators.required, validatePassword]],
      'googleRecaptcha': ['', devEnv ? [] : [Validators.required]],
      'confirmPassword': ['', [ Validators.required ]],
      'letterCondition':[' '],
      'termsCondition': ['', [ Validators.requiredTrue ]],
      'g_recatcha': ['', devEnv ? [] : [Validators.required] ]
    },
    { validator: [checkPassword] }
    );
  }

  issuerRegister() {
    this.issuerRegisterSubmit = true;
    if(this.issuerRegisterForm.valid) {
      this.showLoader = true;
      this._register.registerStatus.next({});
      const formVal:any = this.issuerRegisterForm.value;
      const data:any = {
        // firstName: formVal.firstName,
        // lastName: formVal.lastName,
        email: formVal.email,
        password: formVal.password,
        googleRecaptcha: formVal.googleRecaptcha,
        register: 'LS'
      };
        const encData = {
            load: this._cryp.encrypt(JSON.stringify(data))
        };

      this._register.register('issuer', encData);
      this.getStatus();
      //this.getMessages();
    }
  }
  statSubs:any;
  registeredUserID:any;
  registerUserData:any;
  initiateRegFlow:boolean = false;
  getStatus() {
    this.statSubs = this._register.registerStatus.subscribe(
      data => {
        this.registerStatus = data.status;
        if(data.status == true) {
          this.showLoader = false;
          this.registerMessage = this.errorMessages.userFormSubmission;
          this.loaderStatus();
          this.registerSubmitted = true;
          // this.initiateRegFlow = true;
          this.registerUserData = data.result;
          this.registeredUserID = data.result.idUser;
        } else if( data.status == false ) {
          this.handleRegisterErr(data.result);
          this.showLoader = false;
        }
      }
    );
  }
  regSubmitted($event){
    this.registerSubmitted = $event == true ? true : false;
  }
  messageSubs:any;
  getMessages() {
    this.messageSubs =this._register.registerErrMsg.subscribe(
      data => {
        if( data != '' ) {
          this.showLoader = false;
          this.registerMessage = data;
        }
      }
    );
  }
  loaderSubs:any;
  loaderStatus() {
    this.loaderSubs = this._register.showLoader.subscribe(
      data => {
        this.showLoader = false;
      }
    );
  }

  emailError:boolean = false;
  handleRegisterErr(data) {
    if( data['Error Msg'] == 'emailNotSent' ) {
      this.registerSubmitted = true;
      this.emailError = true;
    }
    this.registerMessage = data['Error Description'];
  }


  showTip(event) {
    $('.password_tip')[ event=='focus' ? 'fadeIn' : 'fadeOut' ]();
  }

  handleSuccess(event) {
    this.issuerRegisterForm.controls['googleRecaptcha'].setValue(event);
  }


  resendEmailLoader:boolean = false;
  resendMailSubs:any;
  resendEmail() {
    let data = {
      id: this.registeredUserID
    }
    this.resendEmailLoader = true;
    this._register.resendEmail(data);
    this.resendMailSubs = this._register.resendMailStatus.subscribe(
      data => {
        if(data) {
          if(data.status == true) {
            this.registerMessage = this.errorMessages.userFormSubmission;
            this.resendEmailLoader = false;
            this._sb.openSnackBar( "Resend email sent successfully",5000,'hybse_success_snackbar','center','top');
          //  this._route.navigate(['/']);
          } else if( !data.status ) {
            this.resendEmailLoader = false;
          }
        }

      },
      error => {

      },
      () => {

      }
    );
  }

  ngOnInit() {
    this.issuerLoginFormInit();
    this._ar.data.subscribe(
      data=> {}
    );
  }

    togglePassword() {
        if (!this.showPass) {
            this.passType = 'text';
            this.showPass = true;
        } else {
            this.passType = 'password';
            this.showPass = false;
        }
    }

    toggleCPassword() {
        if (!this.showCPass) {
            this.passCType = 'text';
            this.showCPass = true;
        } else {
            this.passCType = 'password';
            this.showCPass = false;
        }
    }

  ngOnDestroy() {
    if(typeof this.statSubs != 'undefined' ) this.statSubs.unsubscribe();
    if(typeof this.loaderSubs != 'undefined' ) this.loaderSubs.unsubscribe();
    if(typeof this.messageSubs != 'undefined' ) this.messageSubs.unsubscribe();
  }
}
