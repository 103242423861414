import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'batp-user-register-steps',
  templateUrl: './user-register-steps.component.html',
  styleUrls: ['./user-register-steps.component.css']
})
export class UserRegisterStepsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
