import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApirequestService } from '../../../../services/apirequest/apirequest.service';
import { ApiUrlService } from '../../../../services/api-url/api-url.service';
import { SessionOutService } from '../../../../services/sessionOut/session-out.service';
import { LoginService } from '../../../../services/login/login.service';

@Component({
  selector: 'batp-wiz-registratin-summary',
  templateUrl: './wiz-registratin-summary.component.html',
  styleUrls: ['./wiz-registratin-summary.component.css']
})
export class WizRegistratinSummaryComponent implements OnInit {

  constructor(private _req:ApirequestService,
              private _so:SessionOutService,
              private _lo:LoginService,
              private _url:ApiUrlService) { }
  @Input() goTo:any = '';
  @Input() summaryData:any = '';
  @Input() issuerDetail:any = '';
  @Output() finStepper:any = new EventEmitter();
  @Output() regComplete:any = new EventEmitter();

  goToPrevious() {
    this.finStepper.emit(3);
  }
  //companyRegistrationUrl
  showLoader:boolean = false;
  registerStat:boolean;
  companyRegistrtaion() {
    let data = this.summaryData;
    this.showLoader = true;
    this._req.fetchApiData(this._url.companyRegistrationUrl,data,false).subscribe(
      (data:any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if(resSucc != '') {
          this._lo.getUserDetails();
          this._lo.userDetails.subscribe(
            data => {
              if(data != null) {
                this.registerStat = true;
                this.registerationSuccess(resSucc);
              }
            }
          )
        }
        if(resErr != '') {
          this.registerStat = false;
          this.registerationSuccess(resErr);
          this._so.handleSessionOutErr(resErr);
        }
      },
      error => {

      },
      () => {
        this.showLoader = false;
      }
    );
  }





  getAddress() {
    let address = '';
    if(this.summaryData != '') {
      address = this.summaryData.address1 + ', ' + this.summaryData.address2 + ', ' + this.summaryData.city + ', ' + this.summaryData.zipcode;
    }
    return address;
  }

  registerationSuccess(data) {
    let exportData:any = {
      status: this.registerStat,
      data: data,
      goTo: 4
    };
    this.regComplete.emit(exportData);
  }

  // let data = {
  //   documentInfo: this.documentInfo
  // }
  // let exportData:any = {
  //   formData: data,
  //   goTo: 4
  // };
  // this.companyFinDocs.emit(exportData);


  ngOnInit() {

  }

}
