import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { validateEmail } from '../../../shared/form-validators';
import { ErrorMessages } from '../../../shared/error-messages';
import { captchaKey, devEnv } from '../../../config/hybse.config';
import { ApirequestService } from '../../../services/apirequest/apirequest.service';
import { ApiUrlService } from '../../../services/api-url/api-url.service';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';

@Component({
  selector: 'batp-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {

  constructor( private _fb: FormBuilder,
               private _sb:SnackbarService,
               private _req:ApirequestService,
               private _url:ApiUrlService ) { }
  @Input() form:string = '';
  @Input() formTitle:string = '';

  errorMessages:any = new ErrorMessages();
  contactForm:FormGroup;
  showLoader:boolean = false;
  formSubmitted:boolean = false;
  captchaKey:string = captchaKey;
  contactFormInit() {
    this.contactForm = this._fb.group({
      'email': ['', [Validators.required,validateEmail]],
      'username': ['', [Validators.required] ],
      'body': ['', [Validators.required]],
      'recaptcha': ['', devEnv ? [] : [Validators.required] ]
    })
  }


  handleSuccess(event) {

  }

  contactFormSubmit() {
    this.formSubmitted = true;
    if(this.contactForm.valid) {
      this.showLoader = true;
      let data = this.contactForm.value;
      this._req.fetchApiData(this._url.supportContactUrl,data).subscribe(
        (data:any) => {
          let resSucc = data.data;
          let resErr = data.error;
          if(resSucc) {
            this._sb.openSnackBar( resSucc['Success Msg'],5000,'hybse_success_snackbar','center','top');
          }
          if(resErr) {
            this._sb.openSnackBar( resErr['Error Description'],5000,'hybse_error_snackbar','center','top');
          }
          this.contactForm.reset();
          this.formSubmitted = false;
        },
        error => {

        },
        () => {
          this.showLoader = false;
        }
      )
    }
  }

  ngOnInit() {
    this.contactFormInit();
  }
  ngOnChanges() {

  }

}
